import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { Box} from "@mui/material";
import {
  CustomButton,
  CustomHeading,
  CustomSubHeading,
} from "../../../../../ui";
import CustomBuildingAccordionConfiguration from "../../../../../ui/custom-accordion-configuration/custom-building-accordion-configuration";

interface BuildingConfigurationProps {
  buildingId?: number;
  setIsConfigurationTabsView?: Dispatch<SetStateAction<{ show: boolean; active: number | undefined }>>;
  setIsTabsView?: Dispatch<SetStateAction<{ show: boolean; active: number | undefined }>>;
}

const BuildingConfiguration: FC<BuildingConfigurationProps> = ({
  buildingId,
  setIsConfigurationTabsView,
  setIsTabsView,
}) => {
  const [savePreferences, setSavePreferences] = useState<boolean>(false);


  const rows = [
    {
      facilityName: "Electricity",
      amount: "0.00",
      emissions: "0000",
      actions: "",
    },
    {
      facilityName: "Purchased Steam, heat and cooling",
      amount: "0.00",
      emissions: "0000",
      actions: "",
    },
  ];

  const handleSavePreferences = async () => {
    await setSavePreferences(true);
    await setIsTabsView && setIsTabsView?.({ show: true, active: undefined });
    await setIsConfigurationTabsView && setIsConfigurationTabsView?.({ show: false, active: undefined });
  };

  const handleCancel = () => {
    setSavePreferences(false);
    setIsTabsView && setIsTabsView?.({ show: true, active: undefined });
    setIsConfigurationTabsView && setIsConfigurationTabsView?.({ show: false, active: undefined });
  };
  return (
    <div>
      <Box sx={{ p: "24px 12px 16px", m: "6px" }}>
        <CustomHeading text="Configuration" fontSize="20px" />
        <CustomSubHeading
          text="Please select the building you want to apply the configuration on!"
          fontSize="14px"
          sx={{ pt: "8px", display: "flex-start" }}
        />
      </Box>

      <Box sx={{ margin: "14px 18px" }}>
        <CustomBuildingAccordionConfiguration
          buildingId={buildingId}
          savePreferences={savePreferences}
          navigateUrl={"/measure/scope2-emissions?emission=building"}
          // setSavePreferences ={setSavePreferences}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <CustomButton
          text="Cancel"
          bgColor="transparent"
          rounded="8px"
          variant="outlined"
          onClick={handleCancel}
          sx={{
            borderColor: "#D0D5DD",
            outlineColor: "#EAECF0",
            margin: "8px 16px",
            marginRight: "2px",
            padding: "10px",
            color: "#344054",
            "&:hover": {
              borderColor: "transparent",
              backgroundColor: "#FFF",
            },
          }}
        >
          Cancel
        </CustomButton>

        <CustomButton
          text="Save "
          textColor="#000"
          iconPosition="end"
          rounded="8px"
          onClick={() => handleSavePreferences()}
          sx={{
            margin: "8px 16px ",
            padding: "10px",
            backgroundColor: "#FFDE59",
            color: "#000",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          Save
        </CustomButton>
      </Box>
    </div>
  );
};

export default BuildingConfiguration;
