import {
  BarchartIcon,
  BarlineIcon,
  FileIcon,
  HomeIcon,
  PresentationChartIcon,
  TeamIcon,
} from "../../icons";

export interface NavigationItem {
  id: string;
  text: string;
  title: string;
  icon: React.ElementType;
  href: string;
  hasChild?: boolean;
  children?: NavigationSubItem[];
}

export interface NavigationSubItem {
  id: string;
  text: string;
  href: string;
}

export const navigation: NavigationItem[] = [
  {
    id: "dashboard",
    text: "Dashboard",
    title: "Dashboard",
    icon: HomeIcon,
    href: "/dashboard",
  },
  {
    id: "measure",
    text: "Measure",
    title: "Measure",
    icon: BarchartIcon,
    href: "/measure",
    hasChild: true,
    children: [
      {
        id: "emission-sources",
        text: "Emission Sources",
        href: "/measure/emission-sources",
      },
      {
        id: "scope1",
        text: "Scope 1 Emission",
        href: "/measure/scope1-emissions",
      },
      { id: "scope2", text: "Scope 2 Emission", href: "/measure/scope2-emissions" },
    ],
  },
  {
    id: "manage",
    text: "Manage",
    title: "Assets & Location Management",
    icon: BarlineIcon,
    href: "/manage",
  },
  {
    id: "reporting",
    text: "Reporting",
    title: "Reporting",
    icon: FileIcon,
    href: "/reporting",
  },
  {
    id: "workspace",
    text: "Workspace",
    title: "Workspace",
    icon: PresentationChartIcon,
    href: "/workspace",
  },
  {
    id: "team-management",
    text: "Team Management",
    title: "Team Management",
    icon: TeamIcon,
    href: "/team-management",
  },
];
