import * as React from "react";

function EllipseLargeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="83"
      height="86"
      viewBox="0 0 83 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="60.5" cy="60.5" r="60.5" fill="#0A5D36" />
    </svg>
  );
}

export default EllipseLargeIcon;
