import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  styled,
} from "@mui/material";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  useFormContext,
} from "react-hook-form";
type CustomSelectFieldProps<TFieldValues extends FieldValues = FieldValues> =
  Omit<SelectProps, "name"> & {
    name: FieldPath<TFieldValues>;
    label?: string;
    options: { label: string; value: string | number }[];
  };
const CustomSelectField = <TFieldValues extends FieldValues = FieldValues>({
  name,
  label,
  options,
  ...rest
}: CustomSelectFieldProps<TFieldValues>): JSX.Element => {
  const { control } = useFormContext<TFieldValues>();
  return (
    <Controller<TFieldValues>
      name={name}
      control={control}
      render={({
        field,
        fieldState: { error },
      }: {
        field: ControllerRenderProps<TFieldValues, FieldPath<TFieldValues>>;
        fieldState: ControllerFieldState;
      }) => (
        <FormControl fullWidth variant="outlined" error={!!error}>
          <InputLabel>{label}</InputLabel>
          <StyledSelect variant="outlined" {...field} label={label} {...rest}>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </StyledSelect>
          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
export default CustomSelectField;
const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));
