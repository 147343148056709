import { useState, ReactNode } from "react";
import { Tabs, Tab, Box } from "@mui/material";

interface TabItem {
  label: string;
  content: ReactNode;
}

// Custom hook for managing tab state and rendering components
const useCustomTabs = (
  tabs: TabItem[],
  initialTab: number = 0,
  orientation: "horizontal" | "vertical" = "horizontal",
  value?: number,
  onChange?: (event: React.SyntheticEvent, newValue: number) => void
) => {
  const [internalValue, setInternalValue] = useState(initialTab);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (onChange) {
      onChange(event, newValue);
    } else {
      setInternalValue(newValue);
    }
  };

  const currentValue = value !== undefined ? value : internalValue;

  // TabsHeader component
  const TabsHeader = () => (
    <Box
      sx={{
        ...(orientation === "vertical" && {
          display: "flex",
          alignItems: "flex-start",
        }),
      }}
    >
      <Tabs
        value={currentValue}
        onChange={handleChange}
        aria-label="custom tabs example"
        orientation={orientation}
        sx={{
          ...(orientation === "vertical"
            ? {
              width: "100%",
              minWidth: "200px",
            }
            : { borderBottom: "0px" }),
        }}
        TabIndicatorProps={{
          sx: {
            backgroundColor: "#B59E3F",
            ...(orientation === "vertical" && { left: 0, width: "0px" }),
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            id={`tab-${index}`}
            aria-controls={`tabpanel-${index}`}
            sx={{
              fontFamily: "PoppinsMedium",
              color: "#000",
              textTransform: "capitalize",
              ...(orientation === "vertical"
                ? {
                  fontSize: "14px",
                  textAlign: "left",
                  alignItems: "flex-start",
                  padding: "14px 12px",
                  minHeight: "auto",
                  "&.Mui-selected": {
                    color: "#090909",
                    backgroundColor: "#FFF5CC",
                  },
                  "&:hover": {
                    backgroundColor: "#FFF5CC",
                  },
                }
                : {
                  padding: "14px 12px",
                  minHeight: "64px",
                  fontSize: "16px",
                  fontFamily: "PoppinsMedium",
                  color: "#000",
                  textTransform: "capitalize",
                  "&.Mui-selected": {
                    color: "#B59E3F",
                  },
                }),
            }}
          />
        ))}
      </Tabs>
    </Box>
  );

  // TabPanel component
  const TabPanel = ({
    children,
    index,
  }: {
    children?: ReactNode;
    index: number;
  }) => (
    <Box
      role="tabpanel"
      hidden={currentValue !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      sx={{ m: 0 }}
    >
      {currentValue === index && <Box>{children}</Box>}
    </Box>
  );

  // TabContent component
  const TabContent = () => (
    <>
      {tabs.map((tab, index) => (
        <TabPanel key={index} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </>
  );

  return { TabsHeader, TabContent };
};

export default useCustomTabs;
