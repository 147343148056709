import { Box, Stack } from "@mui/material";
import {
  CustomHeading,
  CustomSubHeading,
  TabsCard,
  VerticalTabsCard,
} from "../../../../../ui";
import useCustomTabs from "../../../../../hooks/useCustomTabs";
import React, { useEffect, useRef, useState } from "react";
import BuildingConfiguration from "./scope1-building-configuration";
import { getAllBuilding } from "../../../../../api/scope2-building-api";
import { showToast } from "../../../../../utils/showToast.util";
import LocationConfiguration from "../location/scope1-location-configuration";


const tabItems = [
  { label: "Building", content: <BuildingConfiguration /> },
  { label: "Location", content: <LocationConfiguration/>},
];


 const Scope1ConfigurationBuildingTabs: React.FC<{
  activeTab: number | undefined;
  activeListTab: number | undefined;
  setSelectedTab: (value: any) => void;
  selectedTab: string;
  setIsTabsView: (value: any) => void;
  setIsConfigurationTabsView: (value: any) => void;
}> = ({ activeTab, activeListTab, setSelectedTab, selectedTab, setIsTabsView, setIsConfigurationTabsView }) => {

const[buildings, setBuildings] = useState<any[]>([]);

  const isFetchCalled = useRef(false); 

useEffect(() => {
  const fetchBuildings = async () => {
    try {
      const response = await getAllBuilding();
      setBuildings(response.data);
    } catch (error: any) {
      console.error("Error fetching buildings:", error);
      showToast(error, "error");
    }
  };

  if (!isFetchCalled.current) {
    fetchBuildings();
    isFetchCalled.current = true;
  }
}, []);
const tabItemslist = buildings.map((building) => ({
  label: building.buildingName,
  content: <BuildingConfiguration  buildingId = {building.buildingId} setIsTabsView={setIsTabsView} setIsConfigurationTabsView={setIsConfigurationTabsView}/>,
}));

  const { TabsHeader} = useCustomTabs(tabItems, activeTab);
  const { TabsHeader: ListHeader, TabContent: ListContent } = useCustomTabs(
    tabItemslist,
    activeListTab,
    "vertical"
  );
  
  return (
    <Stack spacing={3}>
      <TabsCard>
        <TabsHeader />
      </TabsCard>

      <VerticalTabsCard content={<ListContent />}>
        <Box sx={{ p: "24px 12px 16px" }}>
          <CustomHeading text="Building Configuration" fontSize="16px" />
          <CustomSubHeading
            text="Please select the building you want to apply the configuration on!"
            fontSize="14px"
            sx={{ pt: "8px" }}
          />
        </Box>
        <ListHeader />
      </VerticalTabsCard>
    </Stack>
  );
};


export default Scope1ConfigurationBuildingTabs
