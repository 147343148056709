import React from "react";
import Logout from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import CustomHeading from "../custom-heading/custom-heading";
import { useNavigate } from "react-router-dom";
import { ListItemIcon } from "@mui/material";
import { useUserContext } from "../../context/user-context";
import { capitalizeFirstLetter } from "../../utils/getStatusStyle";
import { useQuery } from "@tanstack/react-query";
import { viewProfile } from "../../api/profile-api";

interface ProfileDropdownProps {
  handleLogout: () => void;
}

export default function ProfileDropdown({
  handleLogout,
}: ProfileDropdownProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { user } = useUserContext();

  const { refetch: refetchProfile } = useQuery({
    queryKey: ["viewProfile"],
    queryFn: viewProfile,
    enabled: false
  });

  const firstName = user?.firstName
    ? capitalizeFirstLetter(user.firstName)
    : "";
  const lastName = user?.lastName ? capitalizeFirstLetter(user.lastName) : "";
  const imageUrl =
    typeof user?.imageUrl === "string" ? user?.imageUrl : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = async () => {
    await refetchProfile();
    navigate("/profile", {replace: true});
    handleClose();
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            sx={{ display: "flex", alignItems: "center", textAlign: "left" }}
          >
            <Avatar src={imageUrl || undefined} />
            <Box sx={{ display: "flex", flexDirection: "column", ml: 2 }}>
              <CustomHeading
                text={
                  firstName && lastName
                    ? `${firstName} ${lastName}`
                    : "Planet Pulse"
                }
                variant="body1"
                fontSize="16px"
                gutterBottom={false}
                sx={{ lineHeight: "24px" }}
              />
            </Box>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleProfileClick}>
          <Avatar src={imageUrl || undefined} /> Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
