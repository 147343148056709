export const styles = {
  tableHeader: {
    background: "#F3F4F6",
  },
  tableTextHead: {
    fontFamily: "PoppinsRegular",
    fontSize: "0.875rem",
    fontStyle: "normal",
    lineHeight: "20px",
    color: "#2A2A2A",
  },
  tableBody: {
    color: "#6E7793",
    fontFamily: "PoppinsRegular",
    fontSize: "0.875rem",
    fontStyle: "normal",
 
  },

  container: {
    display: "flex",
    justifyContent: "space-between", 
    alignItems: "center",
    width: "100%",
    padding: "8px 10px",
    borderRadius: "1px",
  },
    text: {
      fontWeight: 400,
      fontFamily: "PoppinsRegular",
      textAlign: "left",
      color: "#2A2A2A",
      fontSize: 14,
    },
    evenRow: {
      background: "#FFFFFF",
    },
    oddRow: {
      background: "#FCFCFD",
    },
    
  };