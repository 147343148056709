import * as React from "react";

function HomeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
    >
      <path
        d="M8 17.0007H16M11.0177 2.76473L4.23539 8.03986C3.78202 8.39248 3.55534 8.56879 3.39203 8.78959C3.24737 8.98518 3.1396 9.20552 3.07403 9.43978C3 9.70425 3 9.99143 3 10.5658V17.8007C3 18.9208 3 19.4809 3.21799 19.9087C3.40973 20.285 3.71569 20.591 4.09202 20.7827C4.51984 21.0007 5.07989 21.0007 6.2 21.0007H17.8C18.9201 21.0007 19.4802 21.0007 19.908 20.7827C20.2843 20.591 20.5903 20.285 20.782 19.9087C21 19.4809 21 18.9208 21 17.8007V10.5658C21 9.99143 21 9.70425 20.926 9.43978C20.8604 9.20552 20.7526 8.98518 20.608 8.78959C20.4447 8.56879 20.218 8.39248 19.7646 8.03986L12.9823 2.76473C12.631 2.49148 12.4553 2.35485 12.2613 2.30233C12.0902 2.25599 11.9098 2.25599 11.7387 2.30233C11.5447 2.35485 11.369 2.49148 11.0177 2.76473Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HomeIcon;
