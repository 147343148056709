import * as React from "react";

function WindowsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6346_203)">
        <path
          d="M9.20383 1.99101C12.6311 1.46802 16.0623 0.971013 19.4973 0.5C19.4999 3.50191 19.5007 6.50285 19.4997 9.50284C16.0682 9.51583 12.6368 9.56829 9.20335 9.57888C9.2011 7.04831 9.2011 4.51902 9.20335 1.99101H9.20383Z"
          fill="#2F76BC"
        />
        <path
          d="M0.499994 3.20182C3.08035 2.80476 5.67181 2.46209 8.26327 2.13867C8.26584 4.62786 8.26729 7.11656 8.26761 9.60478C5.67857 9.60478 3.08904 9.64184 0.499512 9.6351V3.20182H0.499994Z"
          fill="#2F76BC"
        />
        <path
          d="M0.499977 10.4187C3.08709 10.41 5.67421 10.4514 8.26132 10.4471C8.26132 12.943 8.26759 15.4389 8.26132 17.9353C5.67662 17.5532 3.08709 17.2187 0.498047 16.8693L0.499977 10.4187Z"
          fill="#2F76BC"
        />
        <path
          d="M9.18799 10.519H19.4969C19.5014 13.512 19.5014 16.5057 19.4969 19.5002C16.0712 18.9881 12.6363 18.5126 9.20295 18.0482C9.19876 15.5394 9.19378 13.0297 9.18799 10.519Z"
          fill="#2F76BC"
        />
      </g>
      <defs>
        <clipPath id="clip0_6346_203">
          <rect
            width="19"
            height="19"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default WindowsIcon;
