import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { createFuelCombustion, CreateFuelData } from "../../../../../api/fuel-api";
import { MutationConfig } from "../../../../../utils/queryClient";


export const useFuel = (
  config?: MutationConfig<typeof createFuelCombustion>
): UseMutationResult<void, AxiosError, CreateFuelData> => {
  return useMutation<void, AxiosError, CreateFuelData>({
    mutationFn: createFuelCombustion,
    ...config,
  });
};



