import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { ChevronDown } from "../../../../icons";

import {
  CreateAssetData,
  getAssetType,
  updateAsset,
  createAsset,
} from "../../../../api/assets-api";

import {
  CustomHeading,
  CustomModal,
  CustomSubHeading,
  CustomTextField,
} from "../../../../ui";
import { showToast } from "../../../../utils/showToast.util";
import {
  getLocationsCity,
  getLocationsCountries,
  getLocationsStates,
} from "../../../../api/locations-api";

const createSchema = (isAddingNewLocation: boolean) =>
  z.object({
    newLocation: z.boolean().optional(),
    newLocationName: z.string().optional(),
    assetId: z.number().optional(),
    assetName: z
      .string()
      .min(1, "Asset Name is required")
      .max(255, "Asset Name must be less than 255 characters"),
    assetTypeId: z.string().min(1, "Asset Type is required"),
    locationId: z.number().optional(),
    locationName: z.string().optional(),
    ...(isAddingNewLocation
      ? {
        
          address: z
            .string()
            .min(1, "Address is required")
            .max(255, "Address must be less than 255 characters"),
          countryId: z.string().min(1, "Country is required"),
          stateId: z.string().min(1, "State is required"),
          cityId: z.string().min(1, "City is required"),
          zipCode: z
            .string()
            .min(5, "ZIP Code must be at least 5 characters")
            .max(10, "ZIP Code must be no more than 10 characters"),
        }
      : {
        
          address: z.string().optional(),
          // countryId: z.string().optional(),
          // stateId: z.string().optional(),
          // cityId: z.string().optional(),
          zipCode: z.string().optional(),
        }),
  });

interface CreateAssetsProps {
  handleClose: () => void;
  selectedAssets: any;
  handleUpdateRow: any;
  fetchAssets: (page: number) => void;
  currentPage: number;
  isModalOpen: boolean;
  locationData: any[];
}

interface Country {
  countryCode: string;
  countryId: number;
  countryName: string;
}

const CreateAssets: React.FC<CreateAssetsProps> = ({
  handleClose,
  selectedAssets,
  handleUpdateRow,
  fetchAssets,
  currentPage,
  isModalOpen,
  locationData = [],
}) => {
  const location = useLocation();
  const isEditing =
    new URLSearchParams(location.search).get("action") === "edit";

  const [isAddingNewLocation, setIsAddingNewLocation] =
    useState<boolean>(false);
  const [assetTypes, setAssetTypes] = useState<any[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [states, setStates] = useState<
    Array<{ stateId: string; stateName: string }>
  >([]);
  const [cities, setCities] = useState<
    Array<{ cityId: string; cityName: string }>
  >([]);

  const defaultLocation =
    locationData.find((loc: any) => loc?.isDefault) || null;

  const methods = useForm<any>({
    resolver: zodResolver(createSchema(isAddingNewLocation)),
    mode: "onChange",
    defaultValues: {
      assetName: "",
      assetTypeId: "",
      locationName: defaultLocation?.locationName || "",
      address: "",
      countryId: "",
      stateId: "",
      cityId: "",
      zipCode: "",
    },
  });

  const { watch, setValue, trigger, reset, handleSubmit } = methods;

  const countryId = watch("countryId");
  const stateId = watch("stateId");
  const newLocation = watch("newLocation"); 

  useEffect(() => {
    if (isEditing && selectedAssets) {
      const matchedAssetType = assetTypes.find(
        (type: any) => type.name === selectedAssets.assetType
      );

      reset({
        assetName: selectedAssets.assetName || "",
        assetTypeId: matchedAssetType ? matchedAssetType.id.toString() : "",
        locationName: !selectedAssets.newLocation
          ? selectedAssets.locationName || defaultLocation?.locationName
          : "Add New Location", 
        newLocationName: selectedAssets.newLocation ? selectedAssets.newLocationName : "", 
        address: selectedAssets.newLocation ? selectedAssets.address : "",
        countryId: selectedAssets.newLocation ? selectedAssets.countryId : undefined,
        stateId: selectedAssets.newLocation ? selectedAssets.stateId : undefined,
        cityId: selectedAssets.newLocation ? selectedAssets.cityId : undefined,
        zipCode: selectedAssets.newLocation ? selectedAssets.zipCode : "",
      });
    }
  }, [isEditing, selectedAssets, assetTypes, reset, defaultLocation]);

  useEffect(() => {
    if (isModalOpen && !isEditing) {
      reset({
        assetName: "",
        assetTypeId: "",
        locationName: defaultLocation?.locationName || "",
        newLocationName: "",
        address: "",
        countryId: "",
        stateId: "",
        cityId: "",
        zipCode: "",
      });
      setIsAddingNewLocation(false);
    }
  }, [isModalOpen, isEditing, reset, defaultLocation]);

  const onSubmit = async (data: any) => {
    const locationId =
      locationData.find((loc: any) => loc?.locationName === data?.locationName)
        ?.locationId || null;
    if (!locationId && !isAddingNewLocation) {
      showToast("Location ID is required for existing locations", "error");
      return;
    }

    if (isEditing) {
      try {
        const updatePayload = isAddingNewLocation
          ? {
              newLocation: true,
              assetId: selectedAssets.assetId,
              assetName: data.assetName,
              assetTypeId: Number(data.assetTypeId),
              locationDetails: {
                locationName: data.newLocationName, 
                address: data.address,
                zipCode: data.zipCode,
                countryId: parseInt(data.countryId),
                cityId: parseInt(data.cityId),
                stateId: parseInt(data.stateId),
              },
            }
          : {
              newLocation: false,
              assetId: selectedAssets.assetId,
              assetName: data.assetName,
              assetTypeId: Number(data.assetTypeId),
              locationId: locationId, 
            };
        const response = await updateAsset(updatePayload);
        showToast(response.message);
        handleUpdateRow(updatePayload);
        await fetchAssets(currentPage);
        handleClose();
      } catch (error: any) {
        console.error("Failed to update asset:", error);
        showToast(error, "error");
      }
    } else {
      try {
        const createPayload: CreateAssetData = {
          newLocation: isAddingNewLocation,
          assetName: data.assetName,
          assetTypeId: parseInt(data.assetTypeId),
          ...(isAddingNewLocation
            ? {
                locationDetails: {
                  locationName: data.newLocationName,
                  address: data.address,
                  zipCode: data.zipCode,
                  countryId: parseInt(data.countryId),
                  cityId: parseInt(data.cityId),
                  stateId: parseInt(data.stateId),
                },
              }
            : { locationId: locationId }),
        };
        const response = await createAsset(createPayload);
        showToast(response.message);
        await fetchAssets(currentPage);
        handleClose();
      } catch (error: any) {
        showToast(error, "error");
      }
    }
  };

  useEffect(() => {
    const fetchAssetTypes = async () => {
      try {
        const assetTypeData = await getAssetType();
        setAssetTypes(assetTypeData?.data || []);
      } catch (error: any) {
        console.error("Failed to fetch asset types", error);
      }
    };
    fetchAssetTypes();
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getLocationsCountries();
        if (response.success) {
          setCountries(response.data);
        } else {
          console.error("Failed to fetch countries:", response.message);
        }
      } catch (error: any) {
        console.error(error, "error");
      }
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchStates = async () => {
      if (countryId) {
        try {
          const response = await getLocationsStates(Number(countryId));
          setStates(response.data);
        } catch (error) {
          console.error( error, "error");
        }
      } else {
        setStates([]);
        setCities([]);
        setValue("stateId", null);
        setValue("cityId", null);
      }
    };
    fetchStates();
  }, [countryId]);

  useEffect(() => {
    const fetchCities = async () => {
      if (stateId) {
        try {
          const response = await getLocationsCity(Number(stateId));
          setCities(response.data);
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      } else {
        setCities([]);
        setValue("cityId", null);
      }
    };
    fetchCities();
  }, [stateId]);

  const handleLocationChange = (e: any) => {
    if (e.target.value === "add_new") {
      setIsAddingNewLocation(true);
      setValue("newLocationName", "");
    } else {
      setIsAddingNewLocation(false);
      const selectedLocation = locationData.find(
        (location: any) => location.locationId.toString() === e.target.value
      );
      setValue("locationName", selectedLocation?.locationName || "");
      setValue("newLocationName", "");
    }
    trigger("locationName");
    trigger("newLocationName");
  };


  const handleCountryChange = (e: any) => {
    setValue("countryId", e.target.value);
    setValue("stateId", null);
    setValue("cityId", null);
  };

  const handleStateChange = (e: any) => {
    setValue("stateId", e.target.value);
    setValue("cityId", null);
  };

  const fields = [
    {
      name: "assetName",
      label: "Asset Name",
      placeholder: "Enter Asset Name",
      col: 6,
    },
    {
      name: "assetTypeId",
      label: "Asset Type",
      placeholder: "Select Asset Type",
      icon: <ChevronDown />,
      select: true,
      options: assetTypes.map((asset: any) => ({
        value: asset.id.toString(),
        label: asset.name,
      })),
      col: 6,
    },
    {
      name: "locationName",
      label: "Location Name",
      icon: <ChevronDown />,
      select: true,
      options: [
        ...locationData.map((location: any) => ({
          value: location.locationId.toString(),
          label: location?.locationName,
        })),
        { value: "add_new", label: "Add New Location" },
      ],
      placeholder: defaultLocation
        ? defaultLocation?.locationName
        : "Select Location",
      col: 6,
      onChange: handleLocationChange,
    },
  ];

  const additionalLocationFields = [
    {
      name: "newLocationName",
      label: "New Location Name",
      placeholder: "Enter New Location Name",
      col: 6,
    },
    {
      name: "zipCode",
      label: "Zip Code",
      placeholder: "Enter Zip Code",
      col: 6,
    },
    {
      name: "address",
      label: "Address",
      placeholder: "Enter Your Address",
      col: 6,
    },
    {
      name: "countryId",
      label: "Country",
      placeholder: "Select Country",
      col: 6,
      icon: <ChevronDown />,
      select: true,
      options: countries.map((country: any) => ({
        value: country.countryId.toString() || "",
        label: country.countryName,
      })),
      onChange: handleCountryChange,
    },
    {
      name: "stateId",
      label: "State",
      placeholder: "Select State",
      col: 6,
      icon: <ChevronDown />,
      select: true,
      options: states.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      })),
      disabled: !countryId,
      onChange: handleStateChange,
    },
    {
      name: "cityId",
      label: "City",
      placeholder: "Select City",
      col: 6,
      icon: <ChevronDown />,
      select: true,
      options: cities.map((city: any) => ({
        value: city.cityId.toString() || "",
        label: city.cityName,
      })),
      disabled: !stateId,
    },
  ];

  return (
    <CustomModal
      title={isEditing ? "Edit Asset" : "Create Asset"}
      open={isModalOpen}
      onClose={handleClose}
      actions={[
        { text: "Discard", onClick: handleClose, bgColor: "transparent" },
        {
          text: isEditing ? "Update Asset" : "Save Asset",
          type: "submit",
          onClick: () => {
            methods.handleSubmit(onSubmit)();
          },
        },
      ]}
    >
      <Box mt={3}>
        <CustomHeading
          text={isEditing ? "Edit Asset" : "Create Asset"}
          variant="h5"
        />
        <CustomSubHeading text="Please Provide the Following Details!" />
      </Box>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} mt={0}>
            {fields.map((field, index) => (
              <Grid item xs={12} sm={field.col} key={index}>
                <CustomTextField {...field} />
              </Grid>
            ))}
            {isAddingNewLocation &&
              additionalLocationFields.map((field, index) => (
                <Grid item xs={12} sm={field.col} key={index}>
                  <CustomTextField {...field} />
                </Grid>
              ))}
          </Grid>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default CreateAssets;
