import React, { useState, FC, ReactNode } from "react";
import { Box, MenuItem, Menu, Stack, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../custom-textfield/custom-textfield";
import CustomButton from "../custom-button/custom-button";
import PlusIcon from "../../icons/PlusIcon";
import UploadCsvModal from "../upload-csv-modal";

interface CardProps {
  children?: ReactNode;
  headerText?: string;
  spacing?: number;
  headerBtnText?: string;
  searchPlaceholder?: string;
  btnActions?: BtnAction[];
  tableDataLength?: number;
  setSearchQuery?: (query: string) => void;
  searchQuery?: string;
  fetchUploadedFiles?: (data: any) => void;
  currentPage?: number;
  uploadUrl?: any;
  fileType?: string;
}

const Card: FC<CardProps> = ({
  children,
  headerText,
  spacing = 3,
  headerBtnText,
  btnActions,
  searchPlaceholder = "Search",
  tableDataLength = 0,
  setSearchQuery,
  searchQuery,
  fetchUploadedFiles,
  currentPage,
  uploadUrl,
  fileType = ""
}) => {
  const [csvModalOpen, setCsvModalOpen] = useState(false);

  // Handle when file upload is complete
  const handleUploadComplete = (data: any[]) => {
    setCsvModalOpen(false);
  };

  const handleCloseModal = () => {
    setCsvModalOpen(false);
  };

  return (
    <Stack sx={styles["card-container"]} spacing={spacing}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {headerText && (
          <Typography
            sx={{
              fontSize: "1.875rem",
              lineHeight: "1",
              fontFamily: "PoppinsSemibold",
              color: "#000",
            }}
          >
            {headerText}
          </Typography>
        )}
        {tableDataLength > 0 && (
          <HeaderFilter
            headerBtnText={headerBtnText}
            btnActions={btnActions}
            searchPlaceholder={searchPlaceholder}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            onOpenModal={() => setCsvModalOpen(true)}
            fetchUploadedFiles={fetchUploadedFiles}
            currentPage={currentPage}
            uploadUrl={uploadUrl}
           
          />
        )}
      </Box>
      {children && <Box>{children}</Box>}

      <UploadCsvModal
        open={csvModalOpen}
        onClose={handleCloseModal}
        onUploadComplete={handleUploadComplete}
        fetchUploadedFiles={fetchUploadedFiles}
        currentPage={currentPage}
        uploadUrl={uploadUrl}
        fileType= {fileType}
      />
    </Stack>
  );
};

export default Card;

const styles = {
  "card-container": {
    borderRadius: "12px",
    border: "0px solid #D0D5DD",
    padding: "24px 16px",
    background: "#FFF",
    boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.13)",
    marginTop: "0px",
  },
};

interface BtnAction {
  text: string;
  onClick: () => void;
}

interface HeaderFilterProps {
  headerBtnText?: string;
  btnActions?: BtnAction[];
  searchPlaceholder?: string;
  setSearchQuery?: (query: string) => void;
  searchQuery?: string;
  onOpenModal: () => void; // Prop for opening modal
  fetchUploadedFiles?: (data: any) => void;
  currentPage?: number;
  uploadUrl?: string;
}

const HeaderFilter: FC<HeaderFilterProps> = ({
  headerBtnText,
  btnActions,
  searchPlaceholder,
  setSearchQuery,
  searchQuery,
  onOpenModal,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const methods = useForm<FormData>({});

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputSearchChange = (query: string) => {
    if (setSearchQuery) {
      setSearchQuery(query);
    }
  };

  const handleMenuItemClick = (action: string) => {
    setAnchorEl(null);
    const firstWord = headerBtnText?.split(" ")[0];
    const formattedText = firstWord?.toLowerCase().replace(/ /g, "-");

    if (action === "add-manually") {
      navigate(`?action=${formattedText}`, { replace: true });
    } else if (action === "import-csv") {
      onOpenModal();
    } else if (action === "schedule-gathering") {
      navigate(`?action=schedule-data-gathering`, { replace: true });
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <FormProvider {...methods}>
        <form>
          <CustomTextField
            name="search"
            margin="normal"
            type="text"
            placeholder={searchPlaceholder}
            icon="search"
            sx={{ margin: 0 }}
            value={searchQuery}
            onChange={(e) => handleInputSearchChange(e.target.value)}
          />
        </form>
      </FormProvider>

      <CustomButton
        text={headerBtnText}
        onClick={handleOpen}
        icon={PlusIcon}
        iconPosition="end"
        sx={{ pl: 3 }}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            border: "1px solid #ECECEC",
            width: "240px",
          },
        }}
      >
        <MenuItem
          onClick={() => handleMenuItemClick("add-manually")}
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "16px",
            color: "#5A5A5A",
            padding: "12px 16px",
            "&:hover": {
              backgroundColor: "#F7F7F7",
            },
          }}
        >
          Add Data Manually
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick("import-csv")}
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "16px",
            color: "#5A5A5A",
            padding: "12px 16px",
            "&:hover": {
              backgroundColor: "#F7F7F7",
            },
          }}
        >
          Import Data Via CSV
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick("schedule-gathering")}
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "16px",
            color: "#5A5A5A",
            padding: "12px 16px",
            "&:hover": {
              backgroundColor: "#F7F7F7",
            },
          }}
        >
          Schedule Data Gathering
        </MenuItem>
      </Menu>
    </Box>
  );
};
