import React, { useState } from "react";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import {
  Box,
  InputAdornment,
  MenuItem,
  Select,
  styled,
  Typography,
  InputLabel,
} from "@mui/material";
import { Clear as ClearIcon, Search as SearchIcon } from "@mui/icons-material";
import "react-phone-number-input/style.css";

type IconType = "search" | "clear" | React.ReactNode;

interface CustomPhoneInputProps<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  label: string;
  placeholder?: string;
  required?: boolean;
  phoneCountryCode?: string | undefined;
  icon?: IconType;
  onIconClick?: () => void;
  select?: boolean;
  disabled?:boolean;
  options?: { value: string; label: string }[];
}

const CustomPhoneInput = <TFieldValues extends FieldValues>({
  name,
  label,
  placeholder = "Enter phone number",
  required = false,
  phoneCountryCode,
  icon,
  onIconClick,
  select = false,
  disabled= false,
  options = [],
  ...rest
}: CustomPhoneInputProps<TFieldValues>) => {
  const { control } = useFormContext<TFieldValues>();
  const [focused, setFocused] = useState(false);

  const renderIcon = () => {
    if (typeof icon === "string") {
      switch (icon) {
        case "search":
          return <SearchIcon />;
        case "clear":
          return <ClearIcon />;
        default:
          return null;
      }
    }
    return icon as React.ReactNode;
  };

  const endAdornment = icon ? (
    <InputAdornment
      position="end"
      sx={{ cursor: "pointer" }}
      onClick={onIconClick}
    >
      {renderIcon()}
    </InputAdornment>
  ) : null;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box position="relative" width="100%">
          {/* Custom Input Label */}
          <InputLabel
            shrink={focused || Boolean(field.value)}

            sx={{
              position: "absolute",
           
              left: "16px",
              transform:  "translateY(-50%)",
              fontSize: "0.75rem",
              backgroundColor: "#fff",
              padding: "0 4px",
              color: focused ? "primary.main" : "#475467",
              pointerEvents: "none",
              transition: "all 0.2s ease",
             
              fontFamily: "PoppinsRegular",
             
            }}
          >
            {label}
          </InputLabel>

          {select ? (
            <Select
              {...field}
              {...rest}
              displayEmpty
              placeholder={placeholder}
              fullWidth
              onChange={(e) => field.onChange(e.target.value)}
              value={field.value || ""}
              error={!!error}
              inputProps={{
                endAdornment,
               
              }}
              disabled={disabled}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <StyledPhoneInput
              {...field}
              placeholder={focused ? placeholder : ""}
              international
              withCountryCallingCode
              defaultCountry={phoneCountryCode as undefined}
              onFocus={() => setFocused(true)}
              onBlur={(e) => {
                setFocused(false);
                field.onBlur();
              }}
              onChange={(value) => field.onChange(value)}
              value={field.value}
              className={`phone-input ${error ? "error" : ""}`}
              disabled={disabled}
            />
          )}
          {endAdornment && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                right: 8,
                transform: "translateY(-50%)",
                pointerEvents: "auto",
              }}
            >
              {endAdornment}
            </Box>
          )}

          {error && (
            <Typography variant="caption" color="error">
              {error.message}
            </Typography>
          )}
        </Box>
      )}
    />
  );
};

export default CustomPhoneInput;

const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
  width: "100%",
  padding: "10px",
  backgroundColor: theme.palette.background.paper,
  marginTop: "4px",
  borderRadius: "8px",
  border: "1px solid #c1c6c8",
  transition: "border-color 0.3s",
  "& input": {
    border: "none",
    outline: "none",
  },
  "&:hover": {
    borderColor: theme.palette.primary.main,
  },
  "&:focus-within": {
    borderColor: theme.palette.primary.main,
    outline: "2px solid transparent",
  },
  "&.error": {
    borderColor: theme.palette.error.main,
  },
  "& .PhoneInputInput": {
    border: "none",
    outline: "none",
    paddingTop: "7px",
    paddingBottom: "7px",
  },
  "& .PhoneInputCountry": {
    border: "none",
    outline: "none",
  },
}));
