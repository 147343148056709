/** @jsxImportSource @emotion/react */
import { Global, css } from "@emotion/react";
import { Theme } from "@mui/material";

interface GlobalStylesProps {
  theme: Theme;
}

const GlobalStyles: React.FC<GlobalStylesProps> = ({ theme }) => (
  <Global
    styles={css`
      *,
      *::before,
      *::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }

      html,
      body {
        height: 100%;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        background-color: ${theme.palette.background.default};
        color: ${theme.palette.text.primary};
      }

      body {
        margin: 0;
        padding: 0;
        line-height: 1.6;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      a {
        text-decoration: none;
        color: inherit;
      }

      button {
        cursor: pointer;
      }
    `}
  />
);

export default GlobalStyles;
