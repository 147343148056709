import * as React from "react";

function ListIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33333 11.9987V9.33203H28V11.9987H9.33333ZM9.33333 17.332V14.6654H28V17.332H9.33333ZM9.33333 22.6654V19.9987H28V22.6654H9.33333ZM5.33333 11.9987C4.95556 11.9987 4.63889 11.8709 4.38333 11.6154C4.12778 11.3598 4 11.0431 4 10.6654C4 10.2876 4.12778 9.97092 4.38333 9.71536C4.63889 9.45981 4.95556 9.33203 5.33333 9.33203C5.71111 9.33203 6.02778 9.45981 6.28333 9.71536C6.53889 9.97092 6.66667 10.2876 6.66667 10.6654C6.66667 11.0431 6.53889 11.3598 6.28333 11.6154C6.02778 11.8709 5.71111 11.9987 5.33333 11.9987ZM5.33333 17.332C4.95556 17.332 4.63889 17.2043 4.38333 16.9487C4.12778 16.6931 4 16.3765 4 15.9987C4 15.6209 4.12778 15.3043 4.38333 15.0487C4.63889 14.7931 4.95556 14.6654 5.33333 14.6654C5.71111 14.6654 6.02778 14.7931 6.28333 15.0487C6.53889 15.3043 6.66667 15.6209 6.66667 15.9987C6.66667 16.3765 6.53889 16.6931 6.28333 16.9487C6.02778 17.2043 5.71111 17.332 5.33333 17.332ZM5.33333 22.6654C4.95556 22.6654 4.63889 22.5376 4.38333 22.282C4.12778 22.0265 4 21.7098 4 21.332C4 20.9543 4.12778 20.6376 4.38333 20.382C4.63889 20.1265 4.95556 19.9987 5.33333 19.9987C5.71111 19.9987 6.02778 20.1265 6.28333 20.382C6.53889 20.6376 6.66667 20.9543 6.66667 21.332C6.66667 21.7098 6.53889 22.0265 6.28333 22.282C6.02778 22.5376 5.71111 22.6654 5.33333 22.6654Z"
        fill="#1D1B20"
      />
    </svg>
  );
}

export default ListIcon;
