import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import React, { ReactNode } from "react";
import CustomButton from "../custom-button/custom-button";
import { CrossIcon } from "../../icons";

interface DialogAction {
  text: string;
  onClick?: () => void | undefined;
  bgColor?: string;
  textColor?: string;
  type?: string;
}

interface CustomModalProps extends Omit<DialogProps, "title" | "content"> {
  open: boolean;
  onClose?: () => void;
  title?: ReactNode;
  chilren?: ReactNode;
  actions?: DialogAction[];
  position?: "center" | "right";
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  onClose,
  title,
  children,
  actions,
  maxWidth = "md",
  fullWidth = true,
  position = "right",
  ...rest
}) => {
  const dialogStyle: SxProps<Theme> =
    position === "right"
      ? {
          "& .MuiDialog-container": {
            justifyContent: "flex-end",
          },
          "& .MuiDialog-paper": {
            margin: "0px",
            maxHeight: "100%",
            height: "100%",
            borderRadius: "20px 0px 0px 20px",
            boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.13)",
            padding: "20px",
            minWidth: "600px",
          },
        }
      : {};
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      sx={dialogStyle}
      {...rest}
    >
      {title && (
        <DialogTitle
          sx={{
            p: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {typeof title === "string" ? (
            <Typography
              variant="h6"
              sx={{
                fontFamily: "PoppinsSemibold",
                fontSize: "18px",
                color: "#090909",
              }}
            >
              {title}
            </Typography>
          ) : (
            title
          )}
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </DialogTitle>
      )}
      {children && <DialogContent sx={{ p: 0 }}>{children}</DialogContent>}
      {actions && actions.length > 0 && (
        <DialogActions sx={{ px:0,pt:1,pb:0, gap: 1 }}>
          {actions.map((action, index) => (
            <CustomButton
              key={index}
              onClick={action.onClick}
              bgColor={action.bgColor}
              fullWidth={true}
              type={action.type as "button" | "reset" | "submit"}
            >
              {action.text}
            </CustomButton>
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CustomModal;
