import * as React from "react";

function UserIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 20C5.83579 17.5226 9.00702 16 12.5 16C15.993 16 19.1642 17.5226 21.5 20M17 7.5C17 9.98528 14.9853 12 12.5 12C10.0147 12 8 9.98528 8 7.5C8 5.01472 10.0147 3 12.5 3C14.9853 3 17 5.01472 17 7.5Z"
        stroke="#8E98A8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UserIcon;
