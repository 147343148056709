import { axiosInstanceWithAuth } from "../utils/axios";

export interface CalculateBuildingEmissionData{
  buildingEmissionType: string;
  buildingId: number,
  measuringValue: number;
}

export interface SaveBuildingEmissionParams {
  buildingId: number;
  data: {
    buildingEmissionType: string;
    measuringValue: number;
    protocol?: string;
  }[];
}

export const getAllBuilding = async () => {
    try {
      const response = await axiosInstanceWithAuth.get(`/asset/building`);
      return response.data;
    } catch (error: any) {
      console.error("Get All Building failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Get All Building failed.";
    }
  }

  export const createOrUpdateScope1BuildingPreferences = async (buildingId: number, preferences: any) => {
    try {
      const response = await axiosInstanceWithAuth.put(`/scope-one/building-preferences/${buildingId}`, preferences);
      return response.data;
    } catch (error: any) {
      console.error("Error updating building preferences", error.response?.data || error.message);
      throw error.response?.data?.message || "Error updating building preferences.";
    }
  };

  export const getScope1BuildingPreferences = async (id: number) => {
    try {
      const response = await axiosInstanceWithAuth.get(`/scope-one/building-preferences/${id}`);
      return response.data;
    } catch (error: any) {
      console.error("Get Building Preferences By Id failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Get Building Preferences By Id failed.";
    }
  }

  export const calculateScope1BuildingEmission = async(data: CalculateBuildingEmissionData)=>{
   
      try {
        const response = await axiosInstanceWithAuth.post("/scope-one/calculate-building-emission", data); 

        return response.data;
      } catch (error) {
        console.error("Error calculating building emission:", error);
        throw error;
      }
  }


  export const saveScope1BuildingEmission = async (data: SaveBuildingEmissionParams) => {
    try {
      const response = await axiosInstanceWithAuth.post(`/scope-one/building-emission`,data);
      return response.data;
    } catch (error) {
      console.error("Error fetching building emission data:", error);
      throw error;
    }
  };


  export const getScope1BuildingEmission = async (buildingId: number, page?: number) => {
    try {
      const response = await axiosInstanceWithAuth.get(`/scope-one/building-emission?buildingId=${buildingId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching building emission data:", error);
      throw error;
    }
  };  


  export const deleteScope1BuildingEmission = async (emisssionId: number) => {
    try {
      const response = await axiosInstanceWithAuth.delete(`/scope-one/building-emission?emissionId=${emisssionId}`);
      
      return response.data;
    } catch (error) {
      console.error("Error deleting building emission data:", error);
      throw error;
    }
  };  
