export const styles = {
    tableHeader: {
      background: "#F3F4F6",
    },
    tableTextHead: {
      fontFamily: "PoppinsRegular",
      fontSize: "0.875rem",
      fontStyle: "normal",
      lineHeight: "20px",
      color: "#2A2A2A",
    },
    text: {
      fontWeight: 400,
      fontFamily: "Poppins",
      textAlign: "center",
    },
    tableBody: {
      color: "#6E7793",
      fontFamily: "PoppinsRegular",
      fontSize: "0.875rem",
      fontStyle: "normal",
    },

    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      padding: "8px 10px",
      gap: 6,
      borderRadius: "1px",
    },
    evenRow: {
      background: "#FFFFFF",
    },
    oddRow: {
      background: "#FCFCFD",
    },
    statusCompleted: {
      color: "#12B76A",
      display: "flex",
      alignItems: "center",
    },
    statusProcessing: {
      color: "#04A2FB",
      display: "flex",
      alignItems: "center",
    },
    statusError: {
      color: "#DA3E33",
      display: "flex",
      alignItems: "center",
    },
    statusInactive: {
      color: "#DA3E33",
      display: "flex",
      alignItems: "center",
    },
    statusActive: {
      color: "#12B76A",
      display: "flex",
      alignItems: "center",
    },
    dot: {
      height: "8px",
      width: "8px",
      borderRadius: "50%",
      marginRight: "8px",
    },
  };