import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Box, Typography } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { CustomButton } from "../../../../../ui";
import PlusIcon from "../../../../../icons/PlusIcon";
import { useBuildingContext } from "../../../../../context/building-context";
import EmissionsAddedTable from "../../scope2-emission/emission-table/emissions-added-table";
import {
  deleteScope1BuildingEmission,
  getScope1BuildingEmission,
  getScope1BuildingPreferences,
  saveScope1BuildingEmission,
} from "../../../../../api/scope1-building-api";
import Scope1BuildingAccordion from "../../../../../ui/scop1-building-accordion/scope1-building-accordion";
import { showToast } from "../../../../../utils/showToast.util";

interface Scope1BuildingEmissionsProps {
  setIsConfigurationTabsView?: Dispatch<
    SetStateAction<{ show: boolean; active: number | undefined }>
  >;
  buildingId: number;
}

const Scope1BuildingEmissions: FC<Scope1BuildingEmissionsProps> = ({
  setIsConfigurationTabsView,
  buildingId,
}) => {
  const { scope1BuildingPreferences, setScope1BuildingPreferences } =
    useBuildingContext();
  const [buildingEmissionType, setBuildingEmissionType] = useState<any>("");
  const [emissionData, setEmissionData] = useState<any[]>([]);
  const [scope1BuildingEmission, setScope1BuildingEmission] = useState<any[]>(
    []
  );
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true); 



  const methods = useForm({
    defaultValues: {
      gridElectricityAmount: "",
      onSiteRenewablesAmount: "",
    },
  });
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await getScope1BuildingPreferences(buildingId);
        setScope1BuildingPreferences(response.data);
        
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchInitialData();
    fetchBuildingData();
  }, [buildingId, buildingEmissionType]);

  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);

  const columns = [
    { key: "facilityName", label: "Facility Name", flexBasis: "40%" },
    { key: "amount", label: "Amount", flexBasis: "10%" },
    { key: "emissions", label: "Emissions", flexBasis: "12%" },
    { key: "actions", label: "Actions", flexBasis: "15%" },
  ];

  const columns1 = [
    { key: "buildingEmissionType", label: "Facility Name" },
    { key: "measuringValue", label: "Amount" },
    { key: "createdDate", label: "Created At" },
    { key: "calculatedEmission", label: "Emissions" },
  ];

  const [rows, setRows] = useState([
    {
      facilityName: "Stationary Combustion",
      amount: "0.00",
      emissions: "0000",
      actions: "",
      detailsType: "stationaryCombustion",
    },
    {
      facilityName: "Fire Suppressants",
      amount: "0.00",
      emissions: "0000",
      actions: "",
      detailsType: "fireSuppressants",
    },
    {
      facilityName: "Refrigerators",
      amount: "0.00",
      emissions: "0000",
      actions: "",
      detailsType: "refrigerants",
    },
  ]);

  const handleConfiguration = () => {
    setIsConfigurationTabsView &&
      setIsConfigurationTabsView({ show: true, active: undefined });
  };

  const fetchBuildingData = useCallback(async () => {
    if (!hasMore) return;

    try {
      const response = await getScope1BuildingEmission(buildingId, page);
      const newEmissions = response.data || [];

      if (newEmissions.length === 0) {
        setHasMore(false);
      } else {
        setScope1BuildingEmission((prevData) => [...prevData, ...newEmissions]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error: any) {
      console.error("Error fetching building emission data:", error);
      showToast(error, "error");
    }
  }, [buildingId, page, hasMore]);

  const onSubmit = async () => {
    try {
      const emissionBuildingData = emissionData.map((value: any) => ({
        buildingEmissionType: value.buildingEmissionType,
        measuringValue: Number(value.measuringValue),
        protocol: "CSRD",
      }));

      const response = await saveScope1BuildingEmission({
        buildingId: Number(buildingId),
        data: emissionBuildingData,
      });
      if (response.data) {
        setScope1BuildingEmission((prevData) => [
          ...response.data,
          ...prevData,
        ]);
      } else {
        console.error("Response data is invalid or empty.");
      }

      showToast(response.message);
      setPage(1);
      setHasMore(true);
    } catch (error: any) {
      console.error("Error saving building emission:", error);
      showToast(error, "error");
    }
  };
  const handleAddNew = () => {
    setExpandedRowIndex((prevIndex) => (prevIndex === 0 ? null : 0));
  };

  const handleEmissionBuildingTypeChange = (emissionBuildingType: string) => {
    setBuildingEmissionType(emissionBuildingType);
  };

  const handleEmissionTypeChange = (type: string) => {
    setBuildingEmissionType(type);
  };

  const handleEmissionsUpdate = (updatedEmissions: any) => {
    setEmissionData(updatedEmissions);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      e.currentTarget.clientHeight;

    if (bottom) {
      fetchBuildingData();
    }
  };


  const handleDeleteEmission = async (emissionId: number) => {
    try {
      const response = await deleteScope1BuildingEmission(emissionId); 
      setScope1BuildingEmission((prevData) =>
        prevData.filter((item) => item.buildingEmissionId !== emissionId)
      );
      showToast(response.message);
    } catch (error) {
      showToast("Error deleting emission", "error");
    }
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box display="flex" justifyContent="flex-end" gap={2} padding={2}>
          <CustomButton
            text="Configuration"
            bgColor="transparent"
            textColor="#344054"
            rounded="8px"
            variant="outlined"
            onClick={handleConfiguration}
            sx={{
              borderColor: "#D0D5DD",
              outlineColor: "#EAECF0",
              color: "#344054",
              "&:hover": {
                borderColor: "transparent",
                backgroundColor: "#FFF",
              },
            }}
          />
          <CustomButton
            text="Add New"
            bgColor="transparent"
            textColor="#000"
            icon={PlusIcon}
            iconPosition="end"
            rounded="8px"
            onClick={() => handleAddNew()}
            sx={{
              backgroundColor: "#FFDE59",
              color: "#000",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          />
        </Box>

       
          <>
            <Box sx={{ margin: "14px 18px" }}>
              <Scope1BuildingAccordion
                columns={columns}
                rows={rows}
                setRows={setRows}
                expandedRowIndex={expandedRowIndex}
                onExpandRow={setExpandedRowIndex}
                onEmissionTypeChange={handleEmissionTypeChange}
                onEmissionsUpdate={handleEmissionsUpdate}
                buildingId={buildingId}
                onEmissionBuildingTypeChange={handleEmissionBuildingTypeChange}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <CustomButton
                text="Cancel"
                bgColor="transparent"
                rounded="8px"
                variant="outlined"
                onClick={() => {}}
                sx={{
                  borderColor: "#D0D5DD",
                  outlineColor: "#EAECF0",
                  margin: "8px 16px",
                  marginRight: "2px",
                  padding: "10px",
                  color: "#344054",
                  "&:hover": {
                    borderColor: "transparent",
                    backgroundColor: "#FFF",
                  },
                }}
              >
                Cancel
              </CustomButton>

              {/* Save Button */}
              <CustomButton
                type="submit"
                text="Save"
                textColor="#000"
                iconPosition="end"
                rounded="8px"
                sx={{
                  margin: "8px 16px",
                  padding: "10px",
                  backgroundColor: "#FFDE59",
                  color: "#000",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                Save
              </CustomButton>
            </Box>
          </>

        {/* Emissions Table */}
        <Box>
          <EmissionsAddedTable
            columns={columns1}
            rows={scope1BuildingEmission}
            emissionType={buildingEmissionType}
            tableType={"Building"}
            onScroll={handleScroll}
            rowActions={[
              {
                text: "Delete Emission",
                onClick: (row:any) => handleDeleteEmission(row.buildingEmissionId),
              },
            ]}
          />
        </Box>
      </form>
    </FormProvider>
  );
};

export default Scope1BuildingEmissions;
