import React, { FC, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Checkbox,
  FormControlLabel,
  Switch,
  styled,
  SwitchProps,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { showToast } from "../../utils/showToast.util";
import { styles } from "../custom-accordion-configuration/custom-accordion-configuration.style";
import { createOrUpdateScope1BuildingPreferences } from "../../api/scope1-building-api";
import { useNavigate } from "react-router-dom";
import { IOSSwitch } from "../custom-switch/custom-switch";

interface Scope1BuildingAccordionConfigurationProps {
  buildingId?: number | undefined | any;
  savePreferences: boolean;
  navigateUrl: string;
}

const Scope1BuildingAccordionConfiguration: FC<Scope1BuildingAccordionConfigurationProps> = ({
  buildingId,
  savePreferences,
  navigateUrl,
}) => {

  const navigate = useNavigate();
  const [expandedCombustion, setExpandedCombustion] = useState<boolean>(false);
  const [expandedFireSuppressants, setExpandedFireSuppressants] = useState<boolean>(false);
  const [expandedRefrigerators, setExpandedRefrigerators] = useState<boolean>(false);

  const [stationaryCombustionChecked, setStationaryCombustionChecked] = useState<boolean>(false);
  const [fireSuppressantsChecked, setFireSuppressantsChecked] = useState<boolean>(false);
  const [refrigerantsChecked, setRefrigerantsChecked] = useState<boolean>(false);

  const [liquidFuelChecked, setLiquidFuelChecked] = useState<boolean>(false);
  const [solidFuelChecked, setSolidFuelChecked] = useState<boolean>(false);

  // Additional checkboxes for Liquid Fuel options
  const [naturalGasChecked, setNaturalGasChecked] = useState<boolean>(false);
  const [landfillGasChecked, setLandfillGasChecked] = useState<boolean>(false);
  const [liquefiedPetroleumGasChecked, setLiquefiedPetroleumGasChecked] = useState<boolean>(false);
  const [numberTwoOilOrRedDieselChecked, setNumberTwoOilOrRedDieselChecked] = useState<boolean>(false);
  const [numberSixFuelOilChecked, setNumberSixFuelOilChecked] = useState<boolean>(false);
  const [keroseneOrBurningOilChecked, setKeroseneOrBurningOilChecked] = useState<boolean>(false);


  const handleSavePreferences = async () => {
    const preferences = {
      scopeOneStationaryCombustion: {
        scopeOneLiquidFuelOrGas: {
          naturalGas: naturalGasChecked,
          landfillGas: landfillGasChecked,
          liquefiedPetroleumGas: liquefiedPetroleumGasChecked,
          numberTwoOilOrRedDieselGasFuel: numberTwoOilOrRedDieselChecked,
          numberSixFuelOil: numberSixFuelOilChecked,
          keroseneOrBurningOil: keroseneOrBurningOilChecked,
        },
        solidFuelWoodOrCoal: solidFuelChecked,
      },
      fireSuppressants: fireSuppressantsChecked,
      refrigerants: refrigerantsChecked,
    };
    try {
      const response = await createOrUpdateScope1BuildingPreferences(buildingId, preferences); 
      showToast(response.message);
      navigate(navigateUrl);
    } catch (error: any) {
      console.error("Error saving preferences", error);
      showToast(error, "error");
    }
  };


  useEffect(() => {
    if (savePreferences) {
      handleSavePreferences();
    }
  }, [savePreferences]);

  const handleCombustionSwitchChange = () => {
    const newValue = !stationaryCombustionChecked;
    setStationaryCombustionChecked(newValue);
    setExpandedCombustion(newValue);
  };

  const handleFireSuppressantsSwitchChange = () => {
    const newValue = !fireSuppressantsChecked;
    setFireSuppressantsChecked(newValue);
    setExpandedFireSuppressants(newValue);
  };

  const handleRefrigerantsSwitchChange = () => {
    const newValue = !refrigerantsChecked;
    setRefrigerantsChecked(newValue);
    setExpandedRefrigerators(newValue);
  };

  return (
    <>
      {/* First Accordion - Stationary Combustion */}
      <Accordion
        expanded={expandedCombustion}
        onChange={() => setExpandedCombustion(!expandedCombustion)}
        disableGutters
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={styles.container} display="flex" alignItems="center" flex="1">
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={stationaryCombustionChecked}
                  onChange={handleCombustionSwitchChange}
                  inputProps={{ "aria-label": "Stationary Combustion switch" }}
                />
              }
              label="Stationary Combustion"
              sx={{ gap: 2 }}
            />
          </Box>
        </AccordionSummary>

        {stationaryCombustionChecked && (
          <AccordionDetails>
            <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
              {/* Liquid Fuel or Gas */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={liquidFuelChecked}
                    onChange={() => setLiquidFuelChecked(!liquidFuelChecked)}
                    sx={{
                      color: "#8E98A8",
                      "&.Mui-checked": {
                        color: "#E8CA51",
                      },
                    }}
                    inputProps={{ "aria-label": "Liquid Fuel or Gas checkbox" }}
                  />
                }
                label="Liquid Fuel or gas"
              />

              {/* Additional Liquid Fuel options */}
              {liquidFuelChecked && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", marginLeft: "40px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={naturalGasChecked}
                        onChange={() => setNaturalGasChecked(!naturalGasChecked)}
                        sx={{ color: "#8E98A8", "&.Mui-checked": { color: "#E8CA51" } }}
                        inputProps={{ "aria-label": "Natural Gas checkbox" }}
                      />
                    }
                    label="Natural Gas"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={landfillGasChecked}
                        onChange={() => setLandfillGasChecked(!landfillGasChecked)}
                        sx={{ color: "#8E98A8", "&.Mui-checked": { color: "#E8CA51" } }}
                        inputProps={{ "aria-label": "Landfill Gas checkbox" }}
                      />
                    }
                    label="Landfill Gas"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={liquefiedPetroleumGasChecked}
                        onChange={() => setLiquefiedPetroleumGasChecked(!liquefiedPetroleumGasChecked)}
                        sx={{ color: "#8E98A8", "&.Mui-checked": { color: "#E8CA51" } }}
                        inputProps={{ "aria-label": "Liquefied Petroleum Gas checkbox" }}
                      />
                    }
                    label="Liquefied Petroleum Gas"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={numberTwoOilOrRedDieselChecked}
                        onChange={() => setNumberTwoOilOrRedDieselChecked(!numberTwoOilOrRedDieselChecked)}
                        sx={{ color: "#8E98A8", "&.Mui-checked": { color: "#E8CA51" } }}
                        inputProps={{ "aria-label": "#2 Fuel Oil/Red Diesel/Gas Fuel checkbox" }}
                      />
                    }
                    label="#2 Fuel Oil/Red Diesel/Gas Fuel"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={numberSixFuelOilChecked}
                        onChange={() => setNumberSixFuelOilChecked(!numberSixFuelOilChecked)}
                        sx={{ color: "#8E98A8", "&.Mui-checked": { color: "#E8CA51" } }}
                        inputProps={{ "aria-label": "#6 Fuel Oil checkbox" }}
                      />
                    }
                    label="#6 Fuel Oil"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={keroseneOrBurningOilChecked}
                        onChange={() => setKeroseneOrBurningOilChecked(!keroseneOrBurningOilChecked)}
                        sx={{ color: "#8E98A8", "&.Mui-checked": { color: "#E8CA51" } }}
                        inputProps={{ "aria-label": "Kerosene/Burning Oil checkbox" }}
                      />
                    }
                    label="Kerosene/Burning Oil"
                  />
                </Box>
              )}

              {/* Solid Fuel */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={solidFuelChecked}
                    onChange={() => setSolidFuelChecked(!solidFuelChecked)}
                    sx={{ color: "#8E98A8", "&.Mui-checked": { color: "#E8CA51" } }}
                    inputProps={{ "aria-label": "Solid Fuel (Wood or Coal) checkbox" }}
                  />
                }
                label="Solid Fuel (Wood or Coal)"
              />
            </Box>
          </AccordionDetails>
        )}
      </Accordion>

      {/* Second Accordion - Fire Suppressants */}
      <Accordion
        expanded={expandedFireSuppressants}
        onChange={() => setExpandedFireSuppressants(!expandedFireSuppressants)}
        disableGutters
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={styles.container} display="flex" alignItems="center" flex="1">
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={fireSuppressantsChecked}
                  onChange={handleFireSuppressantsSwitchChange}
                  inputProps={{ "aria-label": "Fire Suppressants switch" }}
                />
              }
              label="Fire Suppressants"
              sx={{ gap: 2 }}
            />
          </Box>
        </AccordionSummary>
      </Accordion>

      {/* Third Accordion - Refrigerants */}
      <Accordion
        expanded={expandedRefrigerators}
        onChange={() => setExpandedRefrigerators(!expandedRefrigerators)}
        disableGutters
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={styles.container} display="flex" alignItems="center" flex="1">
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={refrigerantsChecked}
                  onChange={handleRefrigerantsSwitchChange}
                  inputProps={{ "aria-label": "Refrigerants switch" }}
                />
              }
              label="Refrigerants"
              sx={{ gap: 2 }}
            />
          </Box>
        </AccordionSummary>
      </Accordion>
    </>
  );
};

export default Scope1BuildingAccordionConfiguration;
