
import {  axiosInstanceNoAuth  } from "../utils/axios";

export interface SignupData {
  email: string;
  password: string;
}

export interface ForgotPasswordData {
  email: string;
}

export interface ResetPasswordData {
  key: string;
  newPassword: string;
}

export interface VerifyEmail {
  key: string
  email?: string
}

export interface ErrorResponse {
  response?: {
    data?: {
      message?: string;
    };
    status?: number;
    statusText?: string;
  };
  message?: string;
}
export const signupUser = async (data: SignupData) => {
  try {
   
    const response = await axiosInstanceNoAuth.post(`/register`, data);
    return response.data
  } catch (error:any) {
    console.error("Signup failed", error.response?.data || error.message);
    throw error.response?.data?.message || "Signup failed.";
  }
}; 

export const verifyEmail = async (data: VerifyEmail) => {
  try {
    const response = await axiosInstanceNoAuth.get(`/verify-email?key=${data.key}`);
    return response.data;
  }catch (error: any ){
    console.error("Verify Email key failed", error.response?.data || error.message);
    throw error.response?.data?.message || "Verify Email key  failed.";
  }
};


export const loginUser = async (data: SignupData) => {
  try {
    console.log("Login Payload:", data);
    const response = await axiosInstanceNoAuth.post(`/login`, data); 
    console.log("response of login", response)
    console.log("Login Response:", response.data);
    return response.data;
  } catch (error: any) {
    console.error("Login failed", error.response?.data || error.message);
    throw error.response?.data?.message || "Login failed.";
  }
};
export const forgotPassword = async (data: ForgotPasswordData) => {
  try {
    console.log("ForgotPassword Payload:", data)
    const response = await axiosInstanceNoAuth.post("/reset-password-request", null, {
      params: data,
    });
    return response.data;
  }catch (error: any ) {
    console.error("Forgot Password failed", error.response?.data || error.message);
    throw error.response?.data?.message || "Forgot Password failed.";
  }

 
};

export const resetPassword = async (data: ResetPasswordData) => {
  try {
    console.log("Reset password Payload:", data)
    const response = await axiosInstanceNoAuth.post("/reset-password", {
      key: data.key,
      newPassword: data.newPassword
    });
    return response.data;
  }catch (error: any ){
    console.error("Reset Password failed", error.response?.data || error.message); 
    throw error.response?.data?.message || "Reset Password failed.";
  }
};



