import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import {
  CustomHeading,
  CustomSubHeading,
  TabsCard,
  VerticalTabsCard,
} from "../../../../../ui";
import useCustomTabs from "../../../../../hooks/useCustomTabs";
import { showToast } from "../../../../../utils/showToast.util";
import BuildingEmissions from "./scope1-location-emissions";
import { getAllLocation } from "../../../../../api/scope2-location-api";
import LocationEmissions from "./scope1-location-emissions";

export const Scope1LocationEmissionTabs: React.FC<{
  activeTab: number | undefined;
  activeListTab: number | undefined;
  setIsConfigurationTabsView?: Dispatch<SetStateAction<{ show: boolean; active: number | undefined; }>>;
  setSelectedTab?: Dispatch<SetStateAction<string>>;
  selectedTab: string;
}> = ({
  activeTab,
  activeListTab,
  setIsConfigurationTabsView,
  selectedTab,
  setSelectedTab,
}) => {
  const [locations, setLocation] = useState<any[]>([]);

 

  const isFetchCalled = useRef(false);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await getAllLocation();
        setLocation(response.data);
       
      } catch (error: any) {
        console.error("Error fetching buildings:", error);
        showToast(error, "error");  
      }
    };

    if (!isFetchCalled.current) {
      fetchLocation();
      isFetchCalled.current = true;
    }
  }, []);

  const tabItems = [
    {
      label: "Building",

      content: (
        <BuildingEmissions
          setIsConfigurationTabsView={setIsConfigurationTabsView}
         
        />
      ),
    },
    {
      label: "Location",

      content: (
        <LocationEmissions
          setIsConfigurationTabsView={setIsConfigurationTabsView}
          
        />
      ),
    },
  ];
  const tabItemslist = locations.map((location) => ({
    label: location.locationName,
    content: (
      <LocationEmissions
        setIsConfigurationTabsView={setIsConfigurationTabsView}
        locationId={location.locationId}
      />
    ),
  }));

  const { TabsHeader } = useCustomTabs(
    tabItems,
    activeTab,
    "horizontal",
    selectedTab === "building" ? 0 : 1,
    (e, val) =>
      setSelectedTab && setSelectedTab(val === 0 ? "building" : "location")
  );
  const { TabsHeader: ListHeader, TabContent: ListContent } = useCustomTabs(
    tabItemslist,
    activeListTab,
    "vertical"
  );

  return (
    <Stack spacing={3}>
      <TabsCard>
        <TabsHeader />
      </TabsCard>
      <VerticalTabsCard content={<ListContent />}>
        <Box sx={{ p: "24px 12px 16px" }}>
          <CustomHeading text="Location Emissions" fontSize="16px" />
          <CustomSubHeading
            text="Please select the Location you want to apply the configuration on!"
            fontSize="14px"
            sx={{ pt: "8px" }}
          />
        </Box>
        <ListHeader />
      </VerticalTabsCard>
    </Stack>
  );
};
