import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";
import { Box, Grid, Checkbox, FormControlLabel } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { CustomHeading, CustomModal, CustomTextField } from "../../../../ui";
import { useCreateLocation } from "./useCreateLocation";
import { useUpdateLocation } from "./useUpdateLocation";
import { showToast } from "../../../../utils/showToast.util";
import {
  createLocation,
  getLocationsCity,
  getLocationsCountries,
  getLocationsStates,
} from "../../../../api/locations-api";
import { ChevronDown } from "../../../../icons";
import { useLocation } from "react-router-dom";

const schema = z.object({
  updateName: z.boolean().optional(),
  locationName: z.string().min(1, "Location is required").max(255),
  address: z.string().min(1, "Address is required").max(255),
  countryId: z.string().min(1, "Country is required"),
  cityId: z.string().min(1, "City is required"),
  stateId: z.string().min(1, "State is required"),
  zipCode: z
    .string()
    .optional()
    .refine((val) => !val || (val.length >= 5 && val.length <= 10), {
      message: "ZIP Code must be between 5 and 10 characters",
    }),
  markAsDefault: z.boolean().optional(),
});
type FormData = z.infer<typeof schema>;

interface AddLocationProps {
  isModalOpen: boolean;
  handleClose: () => void;
  selectedLocation: any;
  handleUpdateRow: any;
  fetchData: (page: number) => void;
  currentPage: number;
}

interface Country {
  countryCode: string;
  countryId: number;
  countryName: string;
}

const AddLocation: React.FC<AddLocationProps> = ({
  isModalOpen,
  handleClose,
  selectedLocation,
  handleUpdateRow,
  fetchData,
  currentPage,
}) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [states, setStates] = useState<
    Array<{ stateId: number; stateName: string }>
  >([]);
  const [cities, setCities] = useState<
    Array<{ cityId: number; cityName: string }>
  >([]);

  const location = useLocation();
  const isEditing =
  new URLSearchParams(location.search).get("action") === "edit";

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      locationName: "",
      address: "",
      countryId: "",
      cityId: "",
      stateId: "",
      zipCode: "",
      markAsDefault: false,
      updateName: false,
    },
  });

  const countryId = methods.watch("countryId");
  const stateId = methods.watch("stateId");

  useEffect(() => { 
    if (isModalOpen && !isEditing) {
      methods.reset({
        locationName: "",
        address: "",
        countryId: "",
        cityId: "",
        stateId: "",
        zipCode: "",
        markAsDefault: false,
        updateName: false,
      });
    }
  }, [isModalOpen, isEditing, selectedLocation, methods]);

  useEffect(() => {
    if (selectedLocation && isEditing) {
      const fetchLocationIds = async () => {
        const countriesResponse = await getLocationsCountries();
        const country = countriesResponse.data.find(
          (country: any) => country.countryName === selectedLocation.country
        );

        const statesResponse = await getLocationsStates(country.countryId);
        const state = statesResponse.data.find(
          (state: any) => state.stateName === selectedLocation.state
        );

        const citiesResponse = await getLocationsCity(state.stateId);
        const city = citiesResponse.data.find(
          (city: any) => city.cityName === selectedLocation.city
        );

        methods.reset({
          locationName: selectedLocation.locationName || "",
          address: selectedLocation.address || "",
          countryId: country?.countryId.toString() || "",
          stateId: state?.stateId.toString() || "",
          cityId: city?.cityId.toString() || "",
          zipCode: selectedLocation.zipCode || "",
          markAsDefault: selectedLocation.isDefault || false,
          updateName: false,
        });
      };

      fetchLocationIds();
    }
  }, [selectedLocation, methods]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getLocationsCountries();
        if (response.success) {
          setCountries(response.data);
        } else {
          console.error("Failed to fetch countries:", response.message);
        }
      } catch (error: any) {
        console.error("Error fetching countries:", error);
        showToast(error, "error");
      }
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchStates = async () => {
      if (countryId) {
        try {
          const response = await getLocationsStates(Number(countryId));
          setStates(response.data);
        } catch (error: any) {
          console.error("Error fetching states:", error);
          showToast(error, "error");
        }
      }
    };
    fetchStates();
  }, [countryId]);

  useEffect(() => {
    const fetchCities = async () => {
      if (stateId) {
        try {
          const response = await getLocationsCity(Number(stateId));
          setCities(response.data);
        } catch (error: any) {
          console.error("Error fetching cities:", error);
          showToast(error, "error");
        }
      }
    };
    fetchCities();
  }, [stateId]);

  const { mutate: createLocation } = useCreateLocation({
    onSuccess: (data) => {
      showToast(data.message);
      handleClose();
      fetchData(currentPage); 
      
    },
    onError: (error: any) => {
      showToast(error, "error");
    },
  });

  const { mutate: updateLocation } = useUpdateLocation({
    onSuccess: (data) => {
      showToast(data.message);
      handleClose();
      fetchData(currentPage);
    },
    onError: (error: any) => {
      showToast(error, "error");
    },
  });

  const onSubmit = async (data: FormData) => {
    const payload = {
      updateName: true,
      markAsDefault: data.markAsDefault ?? false,
      locationName: data.locationName,
      address: data.address,
      countryId: Number(data.countryId),
      cityId: Number(data.cityId),
      stateId: Number(data.stateId),
      zipCode: data.zipCode,
    };
    try {
      if (selectedLocation) {
        updateLocation({
          ...payload,
          locationId: selectedLocation.locationId,
          updateName: true,
        });
        handleUpdateRow({
          ...payload,
          locationId: selectedLocation.locationId,
        });
      } else {
        
        createLocation({
          ...payload,
        });
      }
    } catch (error: any) {
      showToast(error, "error");
    }
  };
  const fields = [
    {
      name: "locationName",
      label: "Location",
      placeholder: "Enter Location",
      col: 6,
    },
    {
      name: "address",
      label: "Address",
      placeholder: "Enter Your Address",
      col: 6,
    },
    {
      name: "countryId",
      label: "Country",
      icon: <ChevronDown />,
      select: true,
      placeholder: "Select Country",
      options: countries.map((country: any) => ({
        value: country.countryId.toString(),
        label: country.countryName,
      })),
      col: 6,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        methods.setValue("countryId", e.target.value);
        methods.setValue("stateId", "");
        methods.setValue("cityId", "");
      },
    },
    {
      name: "stateId",
      label: "State",
      icon: <ChevronDown />,
      placeholder: "Select State",
      select: true,
      options: states.map((state) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      })),
      col: 6,
      disabled: !countryId,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        methods.setValue("stateId", e.target.value);
        methods.setValue("cityId", "");
      },
    },
    {
      name: "cityId",
      label: "City",
      icon: <ChevronDown />,
      select: true,
      placeholder: "Select City",
      options: cities.map((city) => ({
        value: city.cityId.toString(),
        label: city.cityName,
      })),
      col: 6,
      disabled: !stateId,
    },
    {
      name: "zipCode",
      label: "Zip Code",
      placeholder: "Enter Zip Code",
      col: 6,
    },
  ];

  return (
    <CustomModal
      title={isEditing ? "Edit Location" : "Create Location"}
      open={isModalOpen}
      onClose={handleClose}
      actions={[
        { text: "Discard", onClick: handleClose, bgColor: "transparent" },
        {
          text: selectedLocation ? "Update Location" : "Create Location",
          type: "submit",
          onClick: () => {
            methods.handleSubmit(onSubmit)();
          },
        },
      ]}
    >
      <Box mt={3}>
        <CustomHeading
          text={selectedLocation ? "Edit Location" : "Add Location"}
          variant="h5"
        />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={3} mt={0}>
              {fields.map((field, index) => (
                <Grid item xs={12} sm={field.col} key={index}>
                  <CustomTextField {...field} />
                </Grid>
              ))}
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={methods.watch("markAsDefault")}
                  {...methods.register("markAsDefault")}
                  onChange={(e) => {
                    methods.setValue("markAsDefault", e.target.checked);
                  }}
                />
              }
              label="Make A default location"
              sx={{ mt: 2 }}
            />
          </form>
        </FormProvider>
      </Box>
    </CustomModal>
  );
};

export default AddLocation;



