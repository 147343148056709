import { axiosInstanceWithAuth } from "../utils/axios";

export interface CreateLocationData {
  locationId?: number;
  locationName: string;
  address: string;
  countryId: number;
  cityId: number;
  stateId: number;
  zipCode?: string;
  markAsDefault?: boolean;
}

export interface updateLocationData {
  updateName?: true;
  locationId: number;
  locationName: string;
  countryId: number;
  cityId: number;
  stateId: number;
  address: string;
  zipCode?: string;
  markAsDefault: boolean;
}

export interface ErrorResponse {
  response?: {
    data?: {
      message?: string;
    };
    status?: number;
    statusText?: string;
  };
  message?: string;
}

export interface GetLocationData {
  search?: string;
  countries: string[];
  page: number;
  size: number;
  sort: string;
}

export const getLocations = async (data: GetLocationData) => {
  try {
    const response = await axiosInstanceWithAuth.get("/location", {
      params: {
        search: data.search,
        countries: data.countries,
        page: data.page,
        size: data.size,
        sort: data.sort,
      },
    });
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Location data failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get Location Data failed.";
  }
};

export const createLocation = async (data: CreateLocationData) => {
  try {
    const response = await axiosInstanceWithAuth.post("/location", data);
    return response.data;
  } catch (error: any) {
    console.error(
      "Create Location failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Create Location failed.";
  }
};

export const updateLocation = async (data: updateLocationData) => {
  try {
    const response = await axiosInstanceWithAuth.put(`/location`, data);
    return response.data;
  } catch (error: any) {
    console.error(
      "Update Location failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Update Location failed.";
  }
};

export const deleteLocation = async (locationId: number) => {
  try {
    const response = await axiosInstanceWithAuth.delete(`/location/${locationId}`);
    return response.data;
  } catch (error: any) {
    console.error(
      "Delete Location failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Delete Location failed.";
  }
};

export const setDefaultLocation = async (locationId: number) => {
  try {
    const response = await axiosInstanceWithAuth.post(
      `/location/${locationId}/set-default`
    );

    return response.data;
  } catch (error: any) {
    console.error(
      "Set Default Location failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Set Default Location failed.";
  }
};

export const getLocationsCountries = async () => {
  try {
    const response = await axiosInstanceWithAuth.get("/location/countries");
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Location Countries data failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message || "Get Location Countries Data failed."
    );
  }
};

export const getLocationsStates = async (countryId: number) => {
  try {
    const response = await axiosInstanceWithAuth.get(`/location/${countryId}/state`);
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Location States data failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message || "Get Location States Data failed."
    );
  }
};


export const getLocationsCity = async (stateId: number) => {
  try {
    const response = await axiosInstanceWithAuth.get(`/location/${stateId}/city`);

    return response.data;
  } catch (error: any) {
    console.error(
      "Get Location City data failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message || "Get Location City Data failed."
    );
  }
};



