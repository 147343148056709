
import { Grid, InputAdornment } from "@mui/material";
import {
  MapIcon,
  MarkerPinIcon,
  UserIcon,
  UsersIcon,
  WindIcon,
  ChevronDown,
} from "../../../icons";
import { CustomTextField } from "../../../ui";
import { useQuery } from "@tanstack/react-query";
import {  getIndustries } from "../../../api/profile-api";

interface CompanyDetailFormProps {
  isViewProfile?: boolean;
}

const CompanyDetailForm: React.FC<CompanyDetailFormProps> = ({
  isViewProfile,
}) => {


  const { data: industriesResponse } = useQuery({
    queryKey: ["industrySector"],
    queryFn: getIndustries,
  });


  const industriesData = industriesResponse?.data || []; 

  const fields = [
    {
      name: "companyName",
      label: "Company Name",
      placeholder: "Enter Company Name",
      icon: <UserIcon />,
    },
    {
      name: "companySize",
      label: "Company Size",
      placeholder: "Select Number of Employees",
      icon: <UsersIcon />,
      select: true,
      options: [
        { value: "0-10", label: "0-10 " },
        { value: "10-50", label: "10-50" },
        { value: "50-100", label: "50-100" },
        { value: "100-200", label: "100-200" },
        { value: "200-500", label: "200-500" },
        { value: "500-1000", label: "500-1000" },
        { value: "1000+", label: "1000+" },
      ],
    },
    {
      name: "industrySector",
      label: "Industry Sector",
      placeholder: "Enter Your Industry Type",
      icon: <WindIcon />,
      // icon: <ChevronDown />,
        select: true,
        options: industriesData.map((industry: any) => ({
          value: industry.industrySector.toString(),
          label: industry.industrySector,
        })),
    },
    {
      name: "headquartersLocation",
      label: "Headquarter Location",
      placeholder: "Enter your City",
      icon: <MarkerPinIcon />,
    },
    {
      name: "companyAddress",
      label: "Company Address",
      placeholder: "Enter Your Company Address",
      icon: <MapIcon />,
    },
  ];

  return (
    <Grid container spacing={3} mt={2}>
      {fields.map((field, index) => (
        <Grid
          item
          xs={12}
          sm={index === fields.length - 1 ? 12 : 6}
          key={index}
        >
          <CustomTextField {...field} disabled={isViewProfile} />
        </Grid>
      ))}
    </Grid>
  );
};


export default CompanyDetailForm;

