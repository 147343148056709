import { Box, Stack } from "@mui/material";
import {
  CustomHeading,
  CustomSubHeading,
  TabsCard,
  VerticalTabsCard,
} from "../../../../../ui";
import useCustomTabs from "../../../../../hooks/useCustomTabs";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { showToast } from "../../../../../utils/showToast.util";
import LocationConfiguration from "./scope1-location-configuration";
import { getAllLocation } from "../../../../../api/scope2-location-api";
import BuildingConfiguration from "../building/scope1-building-configuration";

const tabItems = [
  { label: "Building", content: <BuildingConfiguration /> },
  { label: "Location", content: <LocationConfiguration /> },
];

const Scope1ConfigurationLocationTabs: React.FC<{
  activeTab: number | undefined;
  activeListTab: number | undefined;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  selectedTab: string;
  setIsTabsView: Dispatch<SetStateAction<{ show: boolean; active: number | undefined }>>;
  setIsConfigurationTabsView: Dispatch<SetStateAction<{ show: boolean; active: number | undefined }>>;

}> = ({ activeTab, activeListTab, setSelectedTab, selectedTab, setIsTabsView, setIsConfigurationTabsView }) => {
  const [locations, setLocations] = useState<any[]>([]);

  const isFetchCalled = useRef(false);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await getAllLocation();
        setLocations(response.data);
      } catch (error: any) {
        console.error("Error fetching locations:", error);
        showToast(error, "error");
      }
    };

    if (!isFetchCalled.current) {
      fetchLocations();
      isFetchCalled.current = true;
    }
  }, []);
  const tabItemslist = locations.map((location) => ({
    label: location.locationName,
    content: <LocationConfiguration locationId={location.locationId} setIsTabsView={setIsTabsView} setIsConfigurationTabsView={setIsConfigurationTabsView}/>,
  }));

  const { TabsHeader } = useCustomTabs(
    tabItems, 
    activeTab, 
    "horizontal", 
    selectedTab === "building" ? 0 : 1, 
    (e, val) => setSelectedTab && setSelectedTab(val === 0 ? "building" : "location")
  );
  
  const { TabsHeader: ListHeader, TabContent: ListContent } = useCustomTabs(
    tabItemslist,
    activeListTab,
    "vertical"
  );

  return (
    <Stack spacing={3}>
      <TabsCard>
        <TabsHeader />
      </TabsCard>

      <VerticalTabsCard content={<ListContent />}>
        <Box sx={{ p: "24px 12px 16px" }}>
          <CustomHeading text="Location Configuration" fontSize="16px" />
          <CustomSubHeading
            text="Please select the location you want to apply the configuration on!"
            fontSize="14px"
            sx={{ pt: "8px" }}
          />
        </Box>
        <ListHeader />
      </VerticalTabsCard>
    </Stack>
  );
};

export default Scope1ConfigurationLocationTabs;
