import * as React from "react";

function AppleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0331 10.6242C15.0641 13.6451 17.9678 14.6503 18 14.6632C17.9754 14.734 17.536 16.0958 16.4702 17.5023C15.5488 18.7183 14.5925 19.9299 13.0861 19.955C11.6059 19.9796 11.13 19.1624 9.43767 19.1624C7.7459 19.1624 7.21708 19.9299 5.81591 19.9796C4.36184 20.0293 3.25458 18.6647 2.32554 17.4531C0.427169 14.9748 -1.02358 10.4499 0.92441 7.39558C1.89213 5.87878 3.62152 4.91828 5.49862 4.89365C6.92648 4.86906 8.27421 5.76109 9.14707 5.76109C10.0194 5.76109 11.6572 4.68834 13.3789 4.84589C14.0997 4.87298 16.1229 5.1088 17.4221 6.82599C17.3174 6.88459 15.0079 8.09862 15.0331 10.6242ZM12.2512 3.20626C13.0232 2.36246 13.5427 1.18781 13.401 0.019043C12.2883 0.0594271 10.9427 0.688617 10.1446 1.53195C9.42926 2.27877 8.80283 3.47409 8.97185 4.61973C10.2121 4.70638 11.4792 4.0506 12.2512 3.20626Z"
        fill="black"
      />
    </svg>
  );
}

export default AppleIcon;
