import React from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomHeading, CustomModal, CustomSubHeading } from "../../../../ui";

const modules = [
  "Identification of Emission sources module",
  "Measurement and quantification module",
  "Data Collection module",
  "Calculation of emission module",
  "Documentation and reporting module",
  "Shared workspaces",
];

const permissions = ["Create", "View", "Edit", "Delete"];

const CreateRole = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const isModalOpen = searchParams.get("action") === "create";

  const methods = useForm<FormData>({
    mode: "onChange",
  });
  const onSubmit = (data: FormData) => {};

  const handleClose = () => {
    methods.reset();
    navigate(location.pathname, { replace: true });
  };

  return (
    <CustomModal
      title="Create Role"
      open={isModalOpen}
      maxWidth="sm"
      onClose={handleClose}
      actions={[
        { text: "Discard", onClick: handleClose, bgColor: "transparent" },
        {
          text: "Create Role",
          type: "submit",
          onClick: () => {
            methods.handleSubmit(onSubmit)();
          },
        },
      ]}
    >
      <Box mt={3}>
        <CustomHeading text="Role Details" variant="h5" />
        <CustomSubHeading text="Please Provide the Following Details to create a New Role in the system!" />
      </Box>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box mt={3}>
            {modules.map((module, index) => (
              <Box key={index} mb={3}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontFamily: "PoppinsMedium", marginBottom: 1 }}
                >
                  {module}
                </Typography>
                <Box display="flex" gap={4} justifyContent="space-between">
                  {permissions.map((permission, permIndex) => (
                    <FormControlLabel
                      key={permIndex}
                      control={<Checkbox />}
                      label={
                        <CustomSubHeading
                          variant="subtitle2"
                          fontSize="0.875rem"
                          text={permission}
                          fontFamily="PoppinsMedium"
                        />
                      }
                    />
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default CreateRole;
