import React, { useState, FC, useEffect } from "react";
import { Card, CustomTable, CustomTextField } from "../../../../../ui";
import AddEditMobileEmission from "./add-edit-mobile-emission";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import theme from "../../../../../styles/theme";
import { ChevronDown, LayoutGridIcon, ListIcon } from "../../../../../icons";
import { FormProvider, useForm } from "react-hook-form";
import { CustomGridCard } from "../../../../../ui/custom-grid-card/custom-grid-card";
import ViewReasonMobileEmission from "./view-reason-mobile-emission";
import { Close as CloseIcon } from "@mui/icons-material";
import { getMobileSourceEmission, getMobileSourceEmissionByFileId } from "../../../../../api/mobile-source-api";
import { getUploadedFiles } from "../../../../../api/fuel-api";

const protocols = [
  { value: "csrd", label: "CSRD" },
  { value: "secr", label: "SECR" },
];

const MobileSourcesEmissions: FC = () => {
  const navigate = useNavigate();
  const [isGridView, setIsGridView] = useState(false);
  const [mobileSourceEmissionData, setMobileSourceEmissionData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [protocol, setProtocol] = useState<string>("CSRD");
  const [mobileSourceEmissionId, setMobileSourceEmissionId] = useState<any | null>(null);
  const [fileData, setFileData] = useState<any[]>([]);

  const methods = useForm<FormData>({});
  const [fileTableData, setFileTableData] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);

  const itemsPerPage = 8;
  const fetchMobileEmissionData = async (
    page: number,
    itemsPerPage: number,
    protocol?: string,
    sort?: string
  ) => {
    try {
      const response = await getMobileSourceEmission({
        page: page - 1,
        size: itemsPerPage,
        protocol: protocol ? protocol.toUpperCase() : "CSRD",
        sort: "createdDate,desc",
      });
      setMobileSourceEmissionData(response.data);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error("Failed to fetch fuel combustion data:", error);
    }
  };

  useEffect(() => {
    if (protocol !== "") {
      fetchMobileEmissionData(currentPage, itemsPerPage, protocol);
    }
  }, [protocol, currentPage]);

  const mobileData = mobileSourceEmissionData.map((item, i) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
  }));


  const handleRowUpdate = (updatedRow: any) => {
    setMobileSourceEmissionData((prevData) =>
      prevData.map((row) =>
        row.mobileSourceEmissionId === updatedRow.mobileSourceEmissionId
          ? { ...row, ...updatedRow } 
          : row
      )
    );
  };

  const handleProtocolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedProtocol = event.target.value;
    if (protocol !== selectedProtocol) {
      setProtocol(selectedProtocol);
    }
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const handleEditClick = (row: any) => {
    setMobileSourceEmissionId(row.mobileSourceEmissionId);
    navigate(`?action=edit`); 
  };


  const handleViewReasonClick = (row: any) => {
    setMobileSourceEmissionId(row.mobileSourceEmissionId);
    navigate(`?action=viewReason`);
  };


  const fetchUploadedFiles = async (page: number) => {
    try {
      const response = await getUploadedFiles({
        search: '',
        typeOfEmission: 'MOBILE',
        page: page - 1 ,
        size: 10,
        sort: "createdDate,desc",
      });
      setFileData(response.data);
      fetchMobileEmissionData(currentPage, itemsPerPage);
    } catch (error: any) {
      console.error(error, "error");
    }
  };

  useEffect(() => {
    fetchUploadedFiles(currentPage);
  }, [currentPage]);

  const formattedDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const file = fileData.map((item, i) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
    uploadDate: formattedDate(item.uploadDate),
  }));

  const handleViewDetails = async (fileId: number) => {
    try {
      const response = await getMobileSourceEmissionByFileId({
        fileId,
        page: currentPage - 1,
        size: 10,
        sort: "createdDate,desc",
       
      });
      setFileTableData(response.data);
      setOpenModal(true); 
    } catch (error) {
      console.error("Error fetching process emission details:", error);
    }
  };


  const fileTable = fileTableData.map((item, i) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
  }));


  const handleCloseModal = () => {
    setOpenModal(false); // close modal
  };

  return (
    <Card
      headerText="Mobile Source Emissions"
      headerBtnText="Add New"
      searchPlaceholder="Hinted search text"
      tableDataLength={mobileSourceEmissionData?.length}
      btnActions={[{ text: "Edit", onClick: () => {} }]}
      fetchUploadedFiles = {fetchUploadedFiles}
      currentPage={currentPage}
      uploadUrl="/mobile-source-emission/upload"
      fileType="MOBILE"
    >
      <AddEditMobileEmission 
      mobileSourceEmissionId={mobileSourceEmissionId}
      onMobileSourceData={setMobileSourceEmissionData}
      handleRowUpdate= {handleRowUpdate}
      fetchMobileEmissionData={fetchMobileEmissionData}
      currentPage={currentPage}
      fetchUploadedFiles = {fileData}
      />
      <ViewReasonMobileEmission mobileSourceEmissionId={mobileSourceEmissionId}/>
      
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 3,
          }}
        >
          {/* select protocol */}
          <TextField
          name="protocol"
          placeholder="Select Protocol"
          label="Protocol"
          select
          fullWidth
          value={protocol}
          onChange={handleProtocolChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ChevronDown />
              </InputAdornment>
            ),
          }}
          SelectProps={{
            displayEmpty: true,
            renderValue: (value: any) => {
              if (!value) {
                return (
                  <span style={{ color: theme.palette.grey[200] }}>
                    Select Protocol
                  </span>
                );
              }
              const selectedOption = protocols.find(
                (option) => option.value === value
              );
              return selectedOption ? selectedOption.label : value;
            },
          }}
        >
          {protocols.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
          {/* toggle list/grid view */}
          <Stack direction="row" gap={1} ml={3}>
            <IconButton
              sx={{
                backgroundColor: isGridView ? "none" : "#fff5cc",
                borderRadius: "8px",
                padding: "10px",
                "&:hover": {
                  backgroundColor: "#fff5cc",
                },
              }}
              onClick={() => setIsGridView(false)}
            >
              <ListIcon />
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: !isGridView ? "none" : "#fff5cc",
                borderRadius: "8px",
                padding: "10px",
                "&:hover": {
                  backgroundColor: "#fff5cc",
                },
              }}
              onClick={() => setIsGridView(true)}
            >
              <LayoutGridIcon />
            </IconButton>
          </Stack>
        </Box>
      
      {isGridView ? (
        <CustomGridCard
          columns={columns}
          row={mobileData}
          rowActions={[
            {
              text: "Edit Details",
              onClick: (row) => {
                handleEditClick(row)
              },
            },
            {
              text: "View Reason",
              onClick: (row) => {
                handleViewReasonClick(row)
              },
            },
          ]}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      ) : (
        <CustomTable
          columns={columns}
          data={mobileData}
          rowActions={[
            {
              text: "Edit Details",
              onClick: (row) => {
                handleEditClick(row)
              },
            },
            {
              text: "View Reason",
              onClick: (row) => {
                handleViewReasonClick(row)
              },
            },
          ]}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
      <Box mt={4}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "1.875rem",
              lineHeight: "1",
              fontFamily: "PoppinsSemibold",
              color: "#000",
            }}
          >
            Mobile Source Emission Files
          </Typography>

          <FormProvider {...methods}>
            <form>
              <CustomTextField
                name="search"
                margin="normal"
                type="text"
                placeholder="Hinted search text"
                icon="search"
                sx={{ margin: 0 }}
              />
            </form>
          </FormProvider>
        </Box>

        <Box mt={3}>
          <CustomTable
            columns={columns2}
            data={file}
            rowActions={[
              {
                text: "View Details",
                onClick: (row: any) =>{handleViewDetails(row.fileId)},
              },
            ]}
          />
        </Box>
        <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="md">
          <DialogTitle>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">Mobile Source Details</Typography>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <CustomTable columns={columns} data={fileTable} />
          </DialogContent>
        </Dialog>
      </Box>
    </Card>
  );
};

export default MobileSourcesEmissions;

const columns = [
  { key: "id", label: "Sr." },
  { key: "fuelType", label: "Fuel Type" },
  { key: "typeOfVehicle", label: "Vehicles Type" },
  { key: "vehicleQuantity", label: "Vehicles Qt" },
  { key: "fuelConsumption", label: "Consumption" },
  { key: "distanceTravelled", label: "Distance" },
  { key: "mobileSourceDataCollectionMethod", label: "Data Collection" },
  { key: "emissionFactor", label: "Emission Factor" },
  { key: "calculatedEmission", label: "Calculated Emission" },
];
const columns2 = [
  { key: "fileId", label: "File ID" },
  { key: "fileName", label: "File Name" },
  { key: "uploadDate", label: "Upload Date" },
  { key: "totalEmissions", label: "Total Emissions" },
];


