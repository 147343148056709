import React, { FC, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomTextField from "../custom-textfield/custom-textfield";
import { showToast } from "../../utils/showToast.util";
import { styles } from "../custom-accordion-table/custom-accordion-table.style";
import { calculateScope1LocationEmission } from "../../api/scope1-location-api";
import { useLocationContext } from "../../context/location-context";

interface Column {
  key: string;
  label: string;
  flexBasis: string;
}

interface Row {
  facilityName: string;
  amount: string;
  emissions: string;
  actions: string;
  detailsType?: string;
}

interface Scope1LocationAccordionProps {
  columns: Column[];
  rows: Row[];
  expandedRowIndex: number | null;
  onExpandRow: (index: number | null) => void;
  onEmissionTypeChange: (emissionType: string) => void;
  locationId: number;
  onEmissionsUpdate: (data: any) => void;
}

const Scope1LocationAccordion: FC<Scope1LocationAccordionProps> = ({
  columns,
  rows,
  expandedRowIndex,
  onExpandRow,
  onEmissionTypeChange,
  locationId,
  onEmissionsUpdate,
}) => {
  const { scope1locationPreferences } = useLocationContext();

  const [emissions, setEmissions] = useState<{
    [key: string]: number | null;
  }>({
    naturalGas: null,
    landfillGas: null,
    propaneOrLiquefiedPetroleumGas: null,
    numberTwoOilOrRedDieselGasFuel: null,
    numberSixFuelOil: null,
    onSiteFossilFuel: null,
  });

  const handleChange = (index: number) => {
    onExpandRow(expandedRowIndex === index ? null : index);
    const selectedRow = rows[index];
    if (selectedRow.detailsType) {
      onEmissionTypeChange(selectedRow.detailsType);
    }
  };

  const handleInputBlur = async (value: string, emissionType: string) => {
    const measuringValue = parseFloat(value);
    const emissionTypeMapping: { [key: string]: string } = {
      naturalGas: "NATURAL GAS",
      landfillGas: "LANDFILL GAS",
      propaneOrLiquefiedPetroleumGas: "PROPANE LIQUEFIED PETROLEUM GAS",
      numberTwoOilOrRedDieselGasFuel: "TWO FUEL OIL RED DIESEL GAS FUEL",
      numberSixFuelOil: "SIX FUEL OIL",
      onSiteFossilFuel: "ON SITE FOSSIL FUEL",
    };

    const mappedEmissionType = emissionTypeMapping[emissionType];

    if (!isNaN(measuringValue) && value.trim() !== "" && mappedEmissionType) {
      try {
        const requestBody = {
          scopeOneLocationEmissionType: mappedEmissionType,
          locationId: locationId,
          measuringValue: measuringValue,
        };

        onEmissionsUpdate((prevEmissions: any) =>
          Array.isArray(prevEmissions)
            ? [...prevEmissions, requestBody]
            : [requestBody]
        );

        const response = await calculateScope1LocationEmission(requestBody);
        const calculatedEmission = response.data?.calculatedEmission;

        if (calculatedEmission !== undefined) {
          setEmissions((prev) => ({
            ...prev,
            [emissionType]: calculatedEmission,
          }));
        }
      } catch (error: any) {
        console.error("Error calculating emission:", error);
        showToast(error, "error");
      }
    }
  };

  return (
    <>
      <Accordion disableGutters>
        <AccordionSummary
          sx={{
            ...styles.tableTextHead,
            background: "#F3F4F6",
            borderRadius: "10px",
            padding: "8px 16px",
          }}
        >
          <Box
            sx={styles.container}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            width="100%"
          >
            <Box sx={{ flexBasis: "5%" }}>
              <ExpandMoreIcon />
            </Box>
            {columns.map((col) => (
              <Typography
                key={col.key}
                sx={{
                  ...styles.text,
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#2A2A2A",
                  flexBasis: col.flexBasis,
                  textAlign: "left",
                }}
              >
                {col.label}
              </Typography>
            ))}
          </Box>
        </AccordionSummary>
      </Accordion>

      {rows.map((row, index) => (
        <Accordion
          key={index}
          expanded={expandedRowIndex === index}
          onChange={() => handleChange(index)}
          disableGutters
        >
          <AccordionSummary
            sx={{
              ...styles.tableTextHead,
              background: "white",
              borderRadius: "10px",
              padding: "8px 16px",
            }}
          >
            <Box
              sx={styles.container}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
            >
              <Box sx={{ flexBasis: "5%" }}>
                <ExpandMoreIcon />
              </Box>
              {columns.map((col) => (
                <Typography
                  key={col.key}
                  sx={{
                    ...styles.text,
                    fontSize: 14,
                    flexBasis: col.flexBasis,
                    textAlign: "left",
                  }}
                >
                  {row[col.key as keyof Row]}
                </Typography>
              ))}
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              ...styles.tableBody,
              padding: "16px",
              margin: "0px",
            }}
          >
            {row.detailsType === "stationaryCombustion" && (
              <Box sx={{ padding: "8px 16px" }}>
                {scope1locationPreferences?.stationaryCombustion
                  ?.liquidFuelOrGas?.naturalGas && (
                  <Box
                    display="flex"
                    alignItems="center"
                    marginBottom="12px"
                    marginRight="150px"
                  >
                    <Box flex="0 1 60%">
                      <Typography sx={{ marginLeft: "100px" }}>
                        Natural Gas
                      </Typography>
                    </Box>
                    <Box flex="0 1 20%">
                      <CustomTextField
                        name="naturalGas"
                        type="number"
                        placeholder="0.1234"
                        onBlur={(e) =>
                          handleInputBlur(e.target.value, "naturalGas")
                        }
                        sx={{ width: "60%", textAlign: "center" }}
                      />
                    </Box>
                    <Box flex="0 1 20%">
                      <Typography>
                        {emissions.naturalGas !== null
                          ? emissions.naturalGas.toFixed(2)
                          : "0000"}
                        <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                      </Typography>
                    </Box>
                  </Box>
                )}

                {/* Landfill Gas */}
                {scope1locationPreferences?.stationaryCombustion
                  ?.liquidFuelOrGas?.landfillGas && (
                  <Box
                    display="flex"
                    alignItems="center"
                    marginBottom="12px"
                    marginRight="150px"
                  >
                    <Box flex="0 1 60%">
                      <Typography sx={{ marginLeft: "100px" }}>
                        Landfill Gas
                      </Typography>
                    </Box>
                    <Box flex="0 1 20%">
                      <CustomTextField
                        name="landfillGas"
                        type="number"
                        placeholder="0.2345"
                        onBlur={(e) =>
                          handleInputBlur(e.target.value, "landfillGas")
                        }
                        sx={{ width: "60%", textAlign: "center" }}
                      />
                    </Box>
                    <Box flex="0 1 20%">
                      <Typography>
                        {emissions.landfillGas !== null
                          ? emissions.landfillGas.toFixed(2)
                          : "0000"}
                        <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                      </Typography>
                    </Box>
                  </Box>
                )}

                {/* Liquefied Petroleum Gas */}
                {scope1locationPreferences?.stationaryCombustion
                  ?.liquidFuelOrGas?.propaneOrLiquefiedPetroleumGas && (
                  <Box
                    display="flex"
                    alignItems="center"
                    marginBottom="12px"
                    marginRight="150px"
                  >
                    <Box flex="0 1 60%">
                      <Typography sx={{ marginLeft: "100px" }}>
                        Liquefied Petroleum Gas
                      </Typography>
                    </Box>
                    <Box flex="0 1 20%">
                      <CustomTextField
                        name="propaneOrLiquefiedPetroleumGas"
                        type="number"
                        placeholder="0.3456"
                        onBlur={(e) =>
                          handleInputBlur(
                            e.target.value,
                            "propaneOrLiquefiedPetroleumGas"
                          )
                        }
                        sx={{ width: "60%", textAlign: "center" }}
                      />
                    </Box>
                    <Box flex="0 1 20%">
                      <Typography>
                        {emissions.propaneOrLiquefiedPetroleumGas !== null
                          ? emissions.propaneOrLiquefiedPetroleumGas.toFixed(2)
                          : "0000"}
                        <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                      </Typography>
                    </Box>
                  </Box>
                )}

                {/* Number Two Oil or Red Diesel Gas Fuel */}
                {scope1locationPreferences?.stationaryCombustion
                  ?.liquidFuelOrGas?.numberTwoOilOrRedDieselGasFuel && (
                  <Box
                    display="flex"
                    alignItems="center"
                    marginBottom="12px"
                    marginRight="150px"
                  >
                    <Box flex="0 1 60%">
                      <Typography sx={{ marginLeft: "100px" }}>
                        Number Two Oil/Red Diesel Gas Fuel
                      </Typography>
                    </Box>
                    <Box flex="0 1 20%">
                      <CustomTextField
                        name="numberTwoOilOrRedDieselGasFuel"
                        type="number"
                        placeholder="0.4567"
                        onBlur={(e) =>
                          handleInputBlur(
                            e.target.value,
                            "numberTwoOilOrRedDieselGasFuel"
                          )
                        }
                        sx={{ width: "60%", textAlign: "center" }}
                      />
                    </Box>
                    <Box flex="0 1 20%">
                      <Typography>
                        {emissions.numberTwoOilOrRedDieselGasFuel !== null
                          ? emissions.numberTwoOilOrRedDieselGasFuel.toFixed(2)
                          : "0000"}
                        <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                      </Typography>
                    </Box>
                  </Box>
                )}

                {/* Number Six Fuel Oil */}
                {scope1locationPreferences?.stationaryCombustion
                  ?.liquidFuelOrGas?.numberSixFuelOil && (
                  <Box
                    display="flex"
                    alignItems="center"
                    marginBottom="12px"
                    marginRight="150px"
                  >
                    <Box flex="0 1 60%">
                      <Typography sx={{ marginLeft: "100px" }}>
                        Number Six Fuel Oil
                      </Typography>
                    </Box>
                    <Box flex="0 1 20%">
                      <CustomTextField
                        name="numberSixFuelOil"
                        type="number"
                        placeholder="0.5678"
                        onBlur={(e) =>
                          handleInputBlur(e.target.value, "numberSixFuelOil")
                        }
                        sx={{ width: "60%", textAlign: "center" }}
                      />
                    </Box>
                    <Box flex="0 1 20%">
                      <Typography>
                        {emissions.numberSixFuelOil !== null
                          ? emissions.numberSixFuelOil.toFixed(2)
                          : "0000"}
                        <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                      </Typography>
                    </Box>
                  </Box>
                )}
                {scope1locationPreferences?.stationaryCombustion
                  ?.onSiteFossilFuel && (
                  <Box
                    display="flex"
                    alignItems="center"
                    marginBottom="12px"
                    marginRight="150px"
                  >
                    <Box flex="0 1 60%">
                      <Typography sx={{ marginLeft: "100px" }}>
                        On Site Fossile Fuel
                      </Typography>
                    </Box>
                    <Box flex="0 1 20%">
                      <CustomTextField
                        name="onSiteFossilFuel"
                        type="number"
                        placeholder="0.6789"
                        onBlur={(e) =>
                          handleInputBlur(e.target.value, "onSiteFossilFuel")
                        }
                        sx={{ width: "60%", textAlign: "center" }}
                      />
                    </Box>
                    <Box flex="0 1 20%">
                      <Typography>
                        {emissions.onSiteFossilFuel !== null
                          ? emissions.onSiteFossilFuel.toFixed(2)
                          : "0000"}
                        <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default Scope1LocationAccordion;
