import { Box } from "@mui/material";
import {
  CustomHeading,
  CustomSubHeading,
  VerticalTabsCard,
} from "../../../../ui";
import FuelCombustion from "./fuel-combustion";
import FugitiveEmissions from "./fugitive-emission";
import ProcessEmissions from "./process-emissions";
import useCustomTabs from "../../../../hooks/useCustomTabs";
import MobileSourcesEmissions from "./mobile-sources-emission";

const tabItems = [
  { label: "Fuel Combustion Emissions", content: <FuelCombustion /> },
  { label: "Process Emissions", content: <ProcessEmissions /> },
  { label: "Fugitive Emissions", content: <FugitiveEmissions /> },
  { label: "Mobile Sources Emissions", content: <MobileSourcesEmissions /> },
];

export const Scope1EmissionTabs: React.FC<{
  activeTab: number | undefined;
}> = ({ activeTab }) => {
  const { TabsHeader, TabContent } = useCustomTabs(
    tabItems,
    activeTab,
    "vertical"
  );
  return (
    <>
      {activeTab !== undefined ? (
        <VerticalTabsCard content={<TabContent />}>
          <>
            <Box sx={{ p: "24px 12px 16px" }}>
              <CustomHeading text="Scope 01 Emissions" fontSize="16px" />
              <CustomSubHeading
                text="Please Select any emissions you want to check"
                fontSize="14px"
                sx={{ pt: "8px" }}
              />
            </Box>
            <TabsHeader />
          </>
        </VerticalTabsCard>
      ) : (
        null
      )}
    </>
  );
};
