import React, { FC, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styles } from "./custom-accordion-table.style";
import CustomTextField from "../custom-textfield/custom-textfield";
import { useBuildingContext } from "../../context/building-context";
import { calculateBuildingEmission } from "../../api/scope2-building-api";
import { showToast } from "../../utils/showToast.util";

interface Column {
  key: string;
  label: string;
  flexBasis: string;
}

interface Row {
  facilityName: string;
  amount: string;
  emissions: string;
  actions: string;
  detailsType?: string;
}

interface Scope1BuildingAccordionProps {
  columns: Column[];
  rows: Row[];
  expandedRowIndex: number | null;
  onExpandRow: (index: number | null) => void;
  onEmissionTypeChange: (emissionType: string) => void;
  buildingId: number;
  onEmissionsUpdate: (data: any) => void;
}

const CustomBuildingAccordion: FC<Scope1BuildingAccordionProps> = ({
  columns,
  rows,
  expandedRowIndex,
  onExpandRow,
  onEmissionTypeChange,
  buildingId,
  onEmissionsUpdate,
}) => {
  const { buildingPreferences } = useBuildingContext();

  const [emissions, setEmissions] = useState<{
    [key: string]: number | null;
  }>({
    gridElectricity: null,
    onSiteRenewables: null,
    steam: null,
    heating: null,
    electricDrivenChiller: null,
    naturalGasAdsorptionChiller: null,
    engineDrivenNaturalGasChiller: null,
    unknown: null,
  });

  // State to handle expanding the cooling section
  const [isCoolingExpanded, setIsCoolingExpanded] = useState(false);

  const handleChange = (index: number) => {
    onExpandRow(expandedRowIndex === index ? null : index);
    const selectedRow = rows[index];
    if (selectedRow.detailsType) {
      onEmissionTypeChange(selectedRow.detailsType);
    }
  };

  const handleInputBlur = async (value: string, emissionType: string) => {
    const measuringValue = parseFloat(value);
    const emissionTypeMapping: { [key: string]: string } = {
      gridElectricity: "GRID ELECTRICITY",
      onSiteRenewables: "ON SITE RENEWABLES",
      steam: "STEAM",
      heating: "HEATING",
      electricDrivenChiller: "ELECTRIC DRIVEN CHILLER",
      naturalGasAdsorptionChiller: "NATURAL GAS ADSORPTION CHILLER",
      engineDrivenNaturalGasChiller: "ENGINE DRIVEN NATURAL GAS CHILLER",
      unknown: "UNKNOWN",
    };

    const mappedEmissionType = emissionTypeMapping[emissionType];

    if (!isNaN(measuringValue) && value.trim() !== "" && mappedEmissionType) {
      try {
        const requestBody = {
          buildingEmissionType: mappedEmissionType,
          measuringValue: measuringValue,
          buildingId: buildingId,
        };

        onEmissionsUpdate((prevEmissions: any) =>
          Array.isArray(prevEmissions)
            ? [...prevEmissions, requestBody]
            : [requestBody]
        );

        const response = await calculateBuildingEmission(requestBody);
        const calculatedEmission = response.data?.calculatedEmission;

        if (calculatedEmission !== undefined) {
          setEmissions((prev) => ({
            ...prev,
            [emissionType]: calculatedEmission,
          }));
        }
      } catch (error: any) {
        console.error("Error calculating emission:", error);
        showToast(error, "error");
      }
    }
  };

  const hasDetails = (row: Row) => {
    if (row.detailsType === "electricity") {
      return (
        buildingPreferences?.electricityPreference?.gridElectricity ||
        buildingPreferences?.electricityPreference?.onSiteRenewables
      );
    }
    if (row.detailsType === "steamCooling") {
      return (
        buildingPreferences?.purchasedSteamHeatAndCooling?.steam ||
        buildingPreferences?.purchasedSteamHeatAndCooling?.heating ||
        hasCoolingDetails()
      );
    }
    return false;
  };

  const hasCoolingDetails = () => {
    const coolingPreference =
      buildingPreferences?.purchasedSteamHeatAndCooling?.coolingPreference ||
      {};
    return (
      coolingPreference.electricDrivenChiller ||
      coolingPreference.naturalGasAdsorptionChiller ||
      coolingPreference.engineDrivenNaturalGasChiller ||
      coolingPreference.unknown
    );
  };

  return (
    <>
      {/* Header Accordion */}
      <Accordion disableGutters>
        <AccordionSummary
          sx={{
            ...styles.tableTextHead,
            background: "#F3F4F6",
            borderRadius: "10px",
            padding: "8px 16px",
          }}
        >
          <Box
            sx={styles.container}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            width="100%"
          >
            <Box sx={{ flexBasis: "5%" }}>
              <ExpandMoreIcon />
            </Box>
            {columns.map((col) => (
              <Typography
                key={col.key}
                sx={{
                  ...styles.text,
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#2A2A2A",
                  flexBasis: col.flexBasis,
                  textAlign: "left",
                }}
              >
                {col.label}
              </Typography>
            ))}
          </Box>
        </AccordionSummary>
      </Accordion>

      {rows.map((row, index) => (
        <Accordion
          key={index}
          expanded={expandedRowIndex === index}
          onChange={() => handleChange(index)}
          disableGutters
        >
          <AccordionSummary
            sx={{
              ...styles.tableTextHead,
              background: "white",
              borderRadius: "10px",
              padding: "8px 16px",
            }}
          >
            <Box
              sx={styles.container}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
            >
              <Box sx={{ flexBasis: "5%" }}>
                <ExpandMoreIcon />
              </Box>
              {columns.map((col) => (
                <Typography
                  key={col.key}
                  sx={{
                    ...styles.text,
                    fontSize: 14,
                    flexBasis: col.flexBasis,
                    textAlign: "left",
                  }}
                >
                  {row[col.key as keyof Row]}
                </Typography>
              ))}
            </Box>
          </AccordionSummary>
          {hasDetails(row) && (
            <AccordionDetails
              sx={{
                ...styles.tableBody,
                padding: "16px",
                margin: "0px",
              }}
            >
              {/* Render electricity details */}
              {row.detailsType === "electricity" && (
                <Box sx={{ padding: "8px 16px" }}>
                  {buildingPreferences?.electricityPreference
                    ?.gridElectricity && (
                    <Box
                      display="flex"
                      alignItems="center"
                      marginBottom="12px"
                      marginRight="150px"
                    >
                      <Box flex="0 1 60%">
                        <Typography sx={{ marginLeft: "100px" }}>
                          Grid electricity
                        </Typography>
                      </Box>
                      <Box flex="0 1 20%">
                        <CustomTextField
                          name="gridElectricity"
                          placeholder="0.1234"
                          type="number"
                          onBlur={(e) =>
                            handleInputBlur(e.target.value, "gridElectricity")
                          }
                          sx={{ width: "80%" }}
                        />
                      </Box>
                      <Box flex="0 1 20%">
                        {emissions.gridElectricity !== null
                          ? emissions.gridElectricity.toFixed(2)
                          : "0000"}
                        <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                      </Box>
                    </Box>
                  )}

                  {buildingPreferences?.electricityPreference
                    ?.onSiteRenewables && (
                    <Box
                      display="flex"
                      alignItems="center"
                      marginBottom="12px"
                      marginRight="150px"
                    >
                      <Box flex="0 1 60%">
                        <Typography sx={{ marginLeft: "100px" }}>
                          On-site renewables
                        </Typography>
                      </Box>
                      <Box flex="0 1 20%">
                        <CustomTextField
                          name="onSiteRenewables"
                          type="number"
                          placeholder="0.1234"
                          onBlur={(e) =>
                            handleInputBlur(e.target.value, "onSiteRenewables")
                          }
                          sx={{ width: "80%" }}
                        />
                      </Box>
                      <Box flex="0 1 20%">
                        <Typography>
                          {emissions.onSiteRenewables !== null
                            ? emissions.onSiteRenewables.toFixed(2)
                            : "0000"}
                          <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}

              {/* Render steam, heating, and cooling details */}
              {row.detailsType === "steamCooling" && (
                <Box sx={{ padding: "8px 16px" }}>
                  {buildingPreferences?.purchasedSteamHeatAndCooling?.steam && (
                    <Box
                      display="flex"
                      alignItems="center"
                      marginBottom="12px"
                      marginRight="150px"
                    >
                      <Box flex="0 1 60%">
                        <Typography sx={{ marginLeft: "100px" }}>
                          Steam
                        </Typography>
                      </Box>
                      <Box flex="0 1 20%">
                        <CustomTextField
                          name="steam"
                          type="number"
                          placeholder="0.5678"
                          onBlur={(e) =>
                            handleInputBlur(e.target.value, "steam")
                          }
                          sx={{ width: "80%", textAlign: "center" }}
                        />
                      </Box>
                      <Box flex="0 1 20%">
                        <Typography>
                          {emissions.steam !== null
                            ? emissions.steam.toFixed(2)
                            : "0000"}
                          <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                        </Typography>
                      </Box>
                    </Box>
                  )}

                  {buildingPreferences?.purchasedSteamHeatAndCooling
                    ?.heating && (
                    <Box
                      display="flex"
                      alignItems="center"
                      marginBottom="12px"
                      marginRight="150px"
                    >
                      <Box flex="0 1 60%">
                        <Typography sx={{ marginLeft: "100px" }}>
                          Heating
                        </Typography>
                      </Box>
                      <Box flex="0 1 20%">
                        <CustomTextField
                          name="heating"
                          type="number"
                          placeholder="0.7890"
                          onBlur={(e) =>
                            handleInputBlur(e.target.value, "heating")
                          }
                          sx={{ width: "80%" }}
                        />
                      </Box>
                      <Box flex="0 1 20%">
                        <Typography>
                          {emissions.heating !== null
                            ? emissions.heating.toFixed(2)
                            : "0000"}
                          <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                        </Typography>
                      </Box>
                    </Box>
                  )}

                  {hasCoolingDetails() && (
                    <Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        onClick={() => setIsCoolingExpanded(!isCoolingExpanded)}
                        sx={{ cursor: "pointer", marginBottom: "12px" }}
                      >
                        <ExpandMoreIcon
                          sx={{
                            transform: isCoolingExpanded
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                            transition: "transform 0.3s",
                            marginLeft: "50px",
                          }}
                        />
                        <Typography sx={{ marginLeft: "20px" }}>
                          Cooling
                        </Typography>
                      </Box>

                      {isCoolingExpanded && (
                        <>
                          {buildingPreferences?.purchasedSteamHeatAndCooling
                            ?.coolingPreference?.electricDrivenChiller && (
                            <Box
                              display="flex"
                              alignItems="center"
                              marginBottom="12px"
                              marginRight="150px"
                            >
                              <Box flex="0 1 60%">
                                <Typography sx={{ marginLeft: "100px" }}>
                                  Electric Driven Chiller
                                </Typography>
                              </Box>
                              <Box flex="0 1 20%">
                                <CustomTextField
                                  name="electricDrivenChiller"
                                  type="number"
                                  placeholder="0.2345"
                                  onBlur={(e) =>
                                    handleInputBlur(
                                      e.target.value,
                                      "electricDrivenChiller"
                                    )
                                  }
                                  sx={{ width: "80%" }}
                                />
                              </Box>
                              <Box flex="0 1 20%">
                                <Typography>
                                  {emissions.electricDrivenChiller !== null
                                    ? emissions.electricDrivenChiller.toFixed(2)
                                    : "0000"}
                                  <span style={{ marginLeft: "40px" }}>
                                    {"Kwh"}
                                  </span>
                                </Typography>
                              </Box>
                            </Box>
                          )}

                          {buildingPreferences?.purchasedSteamHeatAndCooling
                            ?.coolingPreference
                            ?.naturalGasAdsorptionChiller && (
                            <Box
                              display="flex"
                              alignItems="center"
                              marginBottom="12px"
                              marginRight="150px"
                            >
                              <Box flex="0 1 60%">
                                <Typography sx={{ marginLeft: "100px" }}>
                                  Natural Gas Adsorption Chiller
                                </Typography>
                              </Box>
                              <Box flex="0 1 20%">
                                <CustomTextField
                                  name="naturalGasAdsorptionChiller"
                                  type="number"
                                  placeholder="0.3456"
                                  onBlur={(e) =>
                                    handleInputBlur(
                                      e.target.value,
                                      "naturalGasAdsorptionChiller"
                                    )
                                  }
                                  sx={{ width: "80%" }}
                                />
                              </Box>
                              <Box flex="0 1 20%">
                                <Typography>
                                  {emissions.naturalGasAdsorptionChiller !==
                                  null
                                    ? emissions.naturalGasAdsorptionChiller.toFixed(
                                        2
                                      )
                                    : "0000"}
                                  <span style={{ marginLeft: "40px" }}>
                                    {"Kwh"}
                                  </span>
                                </Typography>
                              </Box>
                            </Box>
                          )}

                          {/* Engine Driven Natural Gas Chiller */}
                          {buildingPreferences?.purchasedSteamHeatAndCooling
                            ?.coolingPreference
                            ?.engineDrivenNaturalGasChiller && (
                            <Box
                              display="flex"
                              alignItems="center"
                              marginBottom="12px"
                              marginRight="150px"
                            >
                              <Box flex="0 1 60%">
                                <Typography sx={{ marginLeft: "100px" }}>
                                  Engine Driven Natural Gas Chiller
                                </Typography>
                              </Box>
                              <Box flex="0 1 20%">
                                <CustomTextField
                                  name="engineDrivenNaturalGasChiller"
                                  type="number"
                                  placeholder="0.4567"
                                  onBlur={(e) =>
                                    handleInputBlur(
                                      e.target.value,
                                      "engineDrivenNaturalGasChiller"
                                    )
                                  }
                                  sx={{ width: "80%" }}
                                />
                              </Box>
                              <Box flex="0 1 20%">
                                <Typography>
                                  {emissions.engineDrivenNaturalGasChiller !==
                                  null
                                    ? emissions.engineDrivenNaturalGasChiller.toFixed(
                                        2
                                      )
                                    : "0000"}
                                  <span style={{ marginLeft: "40px" }}>
                                    {"Kwh"}
                                  </span>
                                </Typography>
                              </Box>
                            </Box>
                          )}

                          {/* Unknown Cooling */}
                          {buildingPreferences?.purchasedSteamHeatAndCooling
                            ?.coolingPreference?.unknown && (
                            <Box
                              display="flex"
                              alignItems="center"
                              marginBottom="12px"
                              marginRight="150px"
                            >
                              <Box flex="0 1 60%">
                                <Typography sx={{ marginLeft: "100px" }}>
                                  Unknown
                                </Typography>
                              </Box>
                              <Box flex="0 1 20%">
                                <CustomTextField
                                  name="unknown"
                                  type="number"
                                  placeholder="0.5678"
                                  onBlur={(e) =>
                                    handleInputBlur(e.target.value, "unknown")
                                  }
                                  sx={{ width: "80%" }}
                                />
                              </Box>
                              <Box flex="0 1 20%">
                                <Typography>
                                  {emissions.unknown !== null
                                    ? emissions.unknown.toFixed(2)
                                    : "0000"}
                                  <span style={{ marginLeft: "40px" }}>
                                    {"Kwh"}
                                  </span>
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </AccordionDetails>
          )}
        </Accordion>
      ))}
    </>
  );
};

export default CustomBuildingAccordion;
