import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { ChevronDown, InfoCircleICon } from "../../../../../icons";
import {
  CustomButton,
  CustomHeading,
  CustomModal,
  CustomSubHeading,
  CustomTextField,
} from "../../../../../ui";
import React, { useEffect, useState } from "react";
import {
  calculateProcessEmission,
  CalculateProcessEmissionData,
  getMaterial,
  getProcessEmissionById,
  getProcessEmissionFactor,
  getProcessType,
  updateProcessEmission,
} from "../../../../../api/process-api";
import { showToast } from "../../../../../utils/showToast.util";
import { useProcessEmission } from "./useProcessEmission";

// Define your validation schema
const schema = z.object({
  processTypeId: z.string().min(1, "Fuel Type is required"),
  quantityOfMaterial: z.preprocess(
    (val) => (val === "" ? null : Number(val)),
    z
      .number()
      .nullable()
      .refine((val) => val === null || val > 0, "Must be a positive number")
      .refine(
        (val) => val === null || val.toString().length >= 3,
        "Minimum 3 digits are required"
      )
      .refine(
        (val) => val === null || val.toString().length <= 15,
        "Maximum 15 digits are allowed"
      )
      .refine(
        (val) => val === null || /^\d+(\.\d{1,3})?$/.test(val.toString()),
        "Decimal places up to 3 are allowed"
      )
  ),
  materialId: z.string().min(1, "Material Id is required"),
  materialUnitId: z.string().min(1, "Material Unit Id is required"),
  emissionFactorId: z.preprocess(
    (val) => (val === "" ? null : Number(val)),
    z
      .number()
      .nullable()
      .refine((val) => val === null || val > 0, "Must be a positive number")
      .refine(
        (val) => val === null || val.toString().length >= 1,
        "Minimum 1 digits are required"
      )
      .refine(
        (val) => val === null || val.toString().length <= 15,
        "Maximum 15 digits are allowed"
      )
      .refine(
        (val) => val === null || /^\d+(\.\d{1,3})?$/.test(val.toString()),
        "Decimal places up to 3 are allowed"
      )
  ),
  dataCollectionMethod: z.string().min(1, "Data Collection Method is required"),
  processDescription: z
    .string()
    .optional()
    .refine((val) => !val || (val.length >= 3 && val.length <= 50), {
      message: "Reason must be between 3 and 50 characters if provided",
    }),
  protocol: z.string().min(1, "Protocol is required"),
  reason: z
    .string()
    .optional()
    .refine((val) => !val || (val.length >= 3 && val.length <= 50), {
      message: "Reason must be between 3 and 50 characters if provided",
    }),
});

type FormData = z.infer<typeof schema>;

interface AddEditProcessEmissionProps {
  onProcessEmissionData: (data: any[]) => void;
  processEmissionId: any;
  handleRowUpdate: any;
  fetchProcessEmissionData: any;
  currentPage: number;
  fetchUploadedFile: any;
}
const AddEditProcessEmission: React.FC<AddEditProcessEmissionProps> = ({
  onProcessEmissionData,
  processEmissionId,
  handleRowUpdate,
  fetchProcessEmissionData,
  currentPage,
  fetchUploadedFile,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const isEditing = searchParams.get("action") === "edit";
  const modalTitle = isEditing ? "Edit Emission" : "Add Data Manually";
  const isModalOpen = isEditing || searchParams.get("action") === "add";

  const [processTypes, setProcessTypes] = useState<any[]>([]);
  const [material, setMaterial] = useState<any[]>([]);
  const [calculatedEmission, setCalculatedEmission] = useState<number | null>(
    0
  );
  const [unitName, setUnitName] = useState<string>("");
  const [filteredUnits, setFilteredUnits] = useState<any[]>([]);

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      processTypeId: "",
      materialId: "",
      materialUnitId: "",
      emissionFactorId: null,
      quantityOfMaterial: null,
      processDescription: "",
      protocol: "",
      dataCollectionMethod: "",
      reason: "",
    },
  });

  const { watch, setValue, reset } = methods;

  useEffect(() => {
    if (!isEditing) {
      reset({
        processTypeId: "",
        materialId: "",
        materialUnitId: "",
        emissionFactorId: null,
        quantityOfMaterial: null,
        processDescription: "",
        protocol: "",
        dataCollectionMethod: "",
        reason: "",
      });
      setCalculatedEmission(null);
    }
  }, [isModalOpen, isEditing]);

  const processType = watch("processTypeId");
  const selectedMaterialId = watch("materialId");
  const unitId = watch("materialUnitId");

  useEffect(() => {
    const fetchProcessTypes = async () => {
      try {
        const data = await getProcessType();
        setProcessTypes(data);
      } catch (error) {
        console.error("Fetch Process Type Failed!", error);
      }
    };
    if (!processTypes.length) {
      fetchProcessTypes();
    }
  }, [processTypes]);

  useEffect(() => {
    const fetchMaterial = async () => {
      try {
        const data = await getMaterial();
        setMaterial(data);
      } catch (error) {
        console.error("Fetch Material Failed!", error);
      }
    };
    if (!material.length) {
      fetchMaterial();
    }
  }, [material]);

  useEffect(() => {
    if (selectedMaterialId) {
      const selectedMaterial = material.find(
        (mat) => mat.materialId.toString() === selectedMaterialId
      );
      // setValue("materialUnitId", "");

      if (selectedMaterial) {
        setFilteredUnits(selectedMaterial.unit);
      }
    }
  }, [selectedMaterialId, material, setValue]);

  useEffect(() => {
    const fetchEmissionFactor = async () => {
      if (processType && selectedMaterialId && unitId) {
        try {
          const response = await getProcessEmissionFactor({
            processId: parseInt(processType),
            materialId: parseInt(selectedMaterialId),
            unitId: parseInt(unitId),
          });
          setValue("emissionFactorId", response.data.factor);
        } catch (error) {
          console.error(error, "error");
        }
      }
    };
    fetchEmissionFactor();
  }, [processType, selectedMaterialId, unitId, setValue]);

  const { mutate: createProcessEmission } = useProcessEmission({
    onSuccess: (data) => {
      const { message } = data;
      showToast(message);
      fetchProcessEmissionData(currentPage, 8);
      fetchUploadedFile(currentPage, 8);
      handleClose();
    },
    onError: (error: any) => {
      showToast(error, "error");
    },
  });

  const calculateEmission = async (data: FormData) => {
    try {
      const payload: CalculateProcessEmissionData = {
        measuringValue: Number(data.quantityOfMaterial),
        emissionFactorId: Number(data.processTypeId),
        materialUnitId: Number(data.materialUnitId),
      };

    
      const response = await calculateProcessEmission(payload);
      setCalculatedEmission(response.data.calculatedEmission);
      setUnitName(response.data.unitName);
     
    } catch (error: any) {
      console.error(error, "error");
      showToast(error, "error");
    }
  };

  const fetchProcessEmissionById = async () => {
    try {
      const data = await getProcessEmissionById(processEmissionId);
      reset({
        processTypeId: data?.data.processTypeDto?.processId.toString() || "",
        materialId: data?.data.material.id.toString() || "",
        materialUnitId: data?.data.unit?.id.toString() || "",
        quantityOfMaterial: data.data.quantityOfRawMaterial || null,
        emissionFactorId: data?.data.processEmissionFactorDto?.factor || null,
        dataCollectionMethod: data?.data.dataCollectionMethod || "",
        protocol: data?.data.protocol || "",
        processDescription: data?.data.processDescription || "",
        reason: data.reason || "",
      });
      setCalculatedEmission(data?.data.calculatedProcessEmission || null);
    } catch (error: any) {
      console.error(error, "error");
      showToast(error, "error");
    }
  };

  useEffect(() => {
    if (processEmissionId > 0 && isEditing) {
      fetchProcessEmissionById();
    }
  }, [processEmissionId, isEditing]);

  const onSubmit = async (data: FormData) => {
    if (data.quantityOfMaterial === null || data.emissionFactorId === null) {
      console.error("Measurement value or emission factor is missing");
      return;
    }
    const payload = {
      ...data,
      processTypeId: Number(data.processTypeId),
      materialId: Number(data.materialId),
      materialUnitId: Number(data.materialUnitId),
      emissionFactorId: Number(data.processTypeId),
      quantityOfMaterial: data.quantityOfMaterial,
      protocol: data.protocol.toUpperCase(),
      dataCollectionMethod: data.dataCollectionMethod,
      processDescription: data.processDescription || "",
    };

    try {
      if (isEditing) {
        const response = await updateProcessEmission(
          processEmissionId,
          payload
        );

        if (handleRowUpdate) {
          const updatedRow = {
            processEmissionId,
            processTypeId: data.processTypeId,
            materialId: data.materialId,
            materialUnitId: data.materialUnitId,
            emissionFactorId: data.emissionFactorId,
            quantityOfMaterial: data.quantityOfMaterial,
            protocol: data.protocol.toUpperCase(),
            dataCollectionMethod: data.dataCollectionMethod,
            processDescription: data.processDescription || "",
          };
          showToast(response.message);
          handleRowUpdate(updatedRow);
          fetchProcessEmissionData(currentPage, 8);
        }
      } else {
        await createProcessEmission(payload);
      }
      handleClose();
    } catch (error: any) {
      console.error(error, "error");
      showToast(error, "error");
    }
  };

  const handleClose = () => {
    methods.reset();
    navigate(location.pathname, { replace: true });
  };

  const fields = [
    {
      name: "processTypeId",
      label: "Type of Process",
      placeholder: "Select Process Type",
      select: true,
      icon: <ChevronDown />,
      options: processTypes.map((processType) => ({
        value: processType.processId.toString(),
        label: processType.processName,
      })),
      col: 12,
    },
    {
      name: "quantityOfMaterial",
      label: "Quantity of Material",
      placeholder: "Enter material value",
      type: "number",
      helperText: "Enter a value between 3 and 15 with up to 3 decimal places",
      col: 12,
    },
    {
      name: "materialId",
      label: "Material Value",
      placeholder: "Select material",
      select: true,
      icon: <ChevronDown />,
      options: material.map((materialValue) => ({
        value: materialValue.materialId.toString(),
        label: materialValue.materialName,
      })),
      col: 12,
      onChange: (e: any) => {
        setValue("materialUnitId", "");
      },
    },
    {
      name: "materialUnitId",
      label: "Unit",
      placeholder: "Select material unit",
      select: true,
      icon: <ChevronDown />,
      options: filteredUnits.map((unit) => ({
        value: unit.id.toString(),
        label: unit.unitName,
      })),
      col: 12,
      disabled: !selectedMaterialId,
    },
    {
      name: "protocol",
      label: "Protocol",
      placeholder: "Select Protocol",
      select: true,
      icon: <ChevronDown />,
      options: [
        { value: "csrd", label: "CSRD" },
        { value: "secr", label: "SECR" },
      ],
      col: 12,
    },
    {
      name: "emissionFactorId",
      label: "Emission Factor",
      placeholder: "Enter Emission Factor",
      type: "number",
      disabled: true,
      col: 6,
    },
    {
      name: "dataCollectionMethod",
      label: "Data Collection Method",
      placeholder: "Select Data Collection Method",
      icon: <ChevronDown />,
      select: true,
      options: [
        { value: "Meter Reading", label: "Meter Reading" },
        { value: "Direct Measurement", label: "Direct Measurement" },
      ],
      col: 6,
    },
    {
      name: "processDescription",
      label: "Process Description",
      placeholder: "Meter Reading is used in this proces",
      type: "textarea",
      col: 12,
    },
  ];

  const extraFields = isEditing
    ? [
        {
          name: "reason",
          label: "Reason To edit",
          placeholder: "Enter The Reason why you want to edit Emission",
          type: "textarea",
          col: 12,
        },
      ]
    : [];

  const finalFields = fields.concat(extraFields);

  return (
    <CustomModal
      title={modalTitle}
      open={isModalOpen}
      onClose={handleClose}
      maxWidth="sm"
      actions={[
        { text: "Discard", onClick: handleClose, bgColor: "transparent" },
        {
          text: isEditing ? "Update Emission" : "Save Emission",
          type: "submit",
          onClick: () => {
            methods.handleSubmit(onSubmit)();
          },
        },
      ]}
    >
      <Box mt={3}>
        <CustomHeading text="Process Emissions" variant="h5" />
        <CustomSubHeading text="Please Provide the Following details to Calculate the Process Emissions" />
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={3} mt={0}>
            {finalFields.map((field, index) => (
              <Grid item xs={12} sm={field.col} key={index}>
                <CustomTextField {...field} />
              </Grid>
            ))}
          </Grid>
        </form>
      </FormProvider>
      <Box mt={3}>
        <CustomButton
          fullWidth
          text="Calculate Emission"
          onClick={methods.handleSubmit(calculateEmission)}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
            mb: 4,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "#090909",
              fontFamily: "PoppinsMedium",
              display: "flex",
              alignItems: "center",
              gap: 1,
              lineHeight: 1,
            }}
          >
            <InfoCircleICon />
            Emission Calculated
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: "#090909",
              fontSize: 18,
              fontWeight: 600,
              lineHeight: 1,
            }}
          >
            {calculatedEmission !== null
              ? `${calculatedEmission} ${unitName}`
              : ""}
          </Typography>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default AddEditProcessEmission;
