import React, { FC, useState, useEffect } from "react";
import { Card, CustomTable } from "../../../../ui";
import CreateAssets from "./create-assets";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteAsset, getAsset } from "../../../../api/assets-api";
import { showToast } from "../../../../utils/showToast.util";
import DeleteModal from "../location/delete-location";
import { getLocations } from "../../../../api/locations-api";
export const Assets: FC = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedAssets, setSelectedAssets] = useState<any>(null);
  const [assetToDelete, setAssetToDelete] = useState<any>(null); 
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); 

  const [locationData, setLocationData] = useState<any[]>([]);


  const itemsPerPage = 10 || 8;
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isModalOpen =
  searchParams.get("action") === "add" ||  
  searchParams.get("action") === "create" ||  
  searchParams.get("action") === "edit";

  const fetchAssets = async (page: number) => {
    try {
      const response = await getAsset({
        countries: [],
        search: searchQuery,
        page: page - 1,
        size: itemsPerPage,
        sort: "createdDate,desc",
      });
      setRows(response?.data || []);
      setTotalPages(response?.totalPages || 1);
    } catch (error: any) {
      console.error("Failed to fetch assets:", error);
    }
  };

  useEffect(() => {
    fetchAssets(currentPage);
  }, [currentPage, searchQuery,]);

  const handleEdit = (assets: any) => {
    navigate(`?action=edit&id=${assets.assetId}`);
    setSelectedAssets(assets)
  };

  const handleUpdateRow = (updatedAsset: any) => {
    setRows((prevRows: any) =>
      prevRows.map((row: any) =>
        row.assetId === updatedAsset.assetId ? updatedAsset : row
      )
    );
  };
  const rowsDisplay = rows.map((item, i) => ({
    ...item,
    locationName: item?.location?.locationName || "",
    address: item?.location?.address || "",
    countryId: item?.location?.country.countryName || "",
    stateId: item?.location?.state.stateName || "",
    cityId: item?.location?.city.cityName || "",
    zipCode: item?.location?.zipCode || "",
    id: (currentPage - 1) * itemsPerPage + i + 1,
  }));

  const handleDelete = (asset: any) => {
    setAssetToDelete(asset); 
    setIsDeleteModalOpen(true); 
  };

  const handleDeleteConfirm = async () => {
    if (assetToDelete) {
      try {
        const response = await deleteAsset(assetToDelete.assetId);
        setRows((prevRows) =>
          prevRows.filter(
            (row:any) => row.assetId !== assetToDelete.assetId
          )
        ); 
        showToast(response.message);
        setIsDeleteModalOpen(false);
      } catch (error) { 
        console.error("Failed to delete asset:", error);
      }
    }
  };
  const handleClose = () => {
    navigate(location.pathname);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };


  const fetchData = async (page: number) => {
    try {
      const response = await getLocations({
        search: "",
        countries: [],
        page: page - 1,
        size: itemsPerPage,
        sort: "createdDate,desc",
      });
      setLocationData(response?.data || []);
      setTotalPages(response?.totalPages || 1);
    } catch (error: any) {
      console.error(error?.message || "Failed to fetch locations.");
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  return (
    <React.Fragment>
      <Card
      headerText="Assets List" 
      headerBtnText="Create Asset" 
      tableDataLength={rows?.length}  
      setSearchQuery = {setSearchQuery}
      searchQuery ={searchQuery}
      >
        
        <CreateAssets 
          handleClose={handleClose}
          selectedAssets={selectedAssets}
          handleUpdateRow={handleUpdateRow} 
          fetchAssets={fetchAssets} 
          currentPage={currentPage} 
          isModalOpen={isModalOpen}
          locationData= {locationData}
          />
          
        <CustomTable
          columns={columns}
          data={rowsDisplay}
          rowActions={[
            {
              text: "Edit Details",
              onClick: (row) => handleEdit(row),
            },
            {
              text: "Delete Asset",
              onClick: (row) => handleDelete(row),
            },
          ]}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </Card>
      <DeleteModal
         isAsset = {true}
        isOpen={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onDelete={handleDeleteConfirm}
      />
    </React.Fragment>
  );
};

const columns = [
  { key: "id", label: "Sr." },
  { key: "assetName", label: "Asset Name" },
  {key: "locationName", label: "Location Name"},
  { key: "address", label: "Address" },
  { key: "countryId", label: "Country" },
  { key: "cityId", label: "City" },
  { key: "zipCode", label: "ZIP Code" },
  { key: "stateId", label: "State" },
  { key: "assetType", label: "Asset Type" },
];

export default Assets;
