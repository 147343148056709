
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Popper from "@mui/material/Popper"; // Dropdown menu positioning
import Paper from "@mui/material/Paper"; // Paper for the dropdown menu styling
import ClickAwayListener from "@mui/material/ClickAwayListener"; // To close the dropdown when clicking outside
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { CSSObject, Theme, styled } from "@mui/material/styles";
import * as React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { planetPulse } from "../../assets/images";
import { ChevronUpIcon, LogoMonogram } from "../../icons";
import useAppStore from "../../stores/useAppStore";
import { NavigationItem, NavigationSubItem, navigation } from "./sidebar-data";
import { ProfileDropdown } from "../../ui";
import useStorage from "../../utils/useStorage.util";
import { showToast } from "../../utils/showToast.util";
import { useUserContext } from "../../context/user-context";

const drawerWidth = 256;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)})`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)})`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "12px",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: theme.palette.background.default,
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.05)",
  ...(!open && {
    width: `calc(100% - ${theme.spacing(7)})`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${theme.spacing(8)})`,
    },
  }),

  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  zIndex: "9999",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: "#003B1F",
  position: "relative",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      backgroundColor: "#003B1F",
      padding: "24px 16px",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      backgroundColor: "#003B1F",
      padding: "24px 16px",
    },
  }),
}));

const Main = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  padding: "24px",
  backgroundColor: "#fafafa",
  minHeight: "100vh",
  ...(!open && {
    width: `calc(100% - ${theme.spacing(7)})`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${theme.spacing(8)})`,
    },
  }),

  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function DashboardLayout() {
  const { dashboardTitle, setDashboardTitle } = useAppStore();
  const [open, setOpen] = React.useState(true);
  const [openSubmenu, setOpenSubmenu] = React.useState<string | null>(null);
  const [activeItem, setActiveItem] = React.useState<string | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const location = useLocation();
  const navigate = useNavigate();
  const { updateUser } = useUserContext();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleItemClick = (item: NavigationItem, event: React.MouseEvent<HTMLElement>) => {
    if (item.hasChild) {
      if (!open) {
        // Set anchor for the dropdown menu if drawer is collapsed
        setAnchorEl(event.currentTarget);
      } else {
        setOpenSubmenu((prev) => (prev === item.id ? null : item.id));
      }
    } else {
      setActiveItem(item.id);
      setDashboardTitle(item.title);
      navigate(item.href);
    }
  };

  const handleSubItemClick = (subitem: NavigationSubItem, title: string) => {
    setDashboardTitle(title);
    navigate(subitem.href);
    setAnchorEl(null); // Close dropdown after selection
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null); // Close dropdown on clicking away
  };

  const isActive = (href: string) => {
    return location.pathname?.includes(href) || activeItem?.includes(href);
  };

  const handleLogout = async () => {
    useStorage.clearStorage();
    useStorage.removeItem("planetPulseToken");
    useStorage.removeItem("planetPulseUser");
    useStorage.setItem("logout", Date.now().toString());
    updateUser(null);
    showToast("You are successfully logged out");
    navigate("/");
  };

  return (
    <Box sx={{ display: "flex", position: "relative" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: "PoppinsMedium", color: "rgba(0, 0, 0, 0.87" }}
          >
            {dashboardTitle}
          </Typography>
          <ProfileDropdown handleLogout={handleLogout} />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} sx={{ position: "relative" }}>
        <DrawerHeader>
          <LogoMonogram />
          {open && <Box component="img" src={planetPulse} alt="Planet Pulse" />}
          <IconButton
            sx={{
              color: "white",
              boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.45)",
              background: "#003B1F",
              zIndex: "1300 !important",
              width: "24px",
              height: "24px",
              position: "fixed",
              left: open ? "230px" : "50px",
              transition: "ease-in-out .2s",
              transitionProperty: "left",
              "&:hover": {
                backgroundColor: "#003B1F",
              },
            }}
            onClick={handleDrawerToggle}
          >
            {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Box
          sx={{
            borderRadius: "2px",
            background: "rgba(254, 254, 254, 0.10)",
            height: "2px",
            my: 3,
          }}
        />
        <List
          sx={{
            p: 0,
            display: "flex",
            flexDirection: "column",
            gap: !open ? "12px" : "8px",
          }}
        >
          {navigation.map((item) => (
            <React.Fragment key={item.id}>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={(e) => handleItemClick(item, e)}
                  className="custom-list-button"
                  sx={{
                    justifyContent: open ? "space-between" : "center",
                    display: "flex",
                    alignItems: "center",
                    padding: open ? "12px" : "1px 12px",
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontFamily: "PoppinsMedium",
                    backgroundColor:
                      isActive(item.href) && !item.hasChild
                        ? "primary.main"
                        : "transparent",
                    "& .MuiListItemIcon-root": {
                      color: isActive(item.href)
                        ? item.hasChild
                          ? "primary.main"
                          : "#000000"
                        : "#fff",
                    },
                    "&:hover, &:focus": {
                      backgroundColor: !item.hasChild
                        ? "primary.main"
                        : "transparent",
                      "& .MuiListItemText-root": {
                        color: item.hasChild
                          ? "primary.main"
                          : "primary.contrastText",
                      },
                      "& .MuiListItemIcon-root": {
                        color: item.hasChild ? "primary.main" : "#000000",
                      },
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? "12px" : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <item.icon
                      stroke={
                        isActive(item.href)
                          ? item.hasChild
                            ? "primary.main"
                            : "#000000"
                          : "white"
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{
                      color: isActive(item.href)
                        ? item.hasChild
                          ? "primary.main"
                          : "primary.contrastText"
                        : "white",
                      opacity: open ? 1 : 0,
                    }}
                    primaryTypographyProps={{ fontWeight: 500 }}
                  />
                  {item.hasChild && open && (
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        justifyContent: "center",
                        transition: "ease-in-out .2s",
                        transform:
                          openSubmenu !== item.id
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                      }}
                    >
                      <ChevronUpIcon />
                    </ListItemIcon>
                  )}
                </ListItemButton>
              </ListItem>

              {item.hasChild && !open && (
                <Popper
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  placement="right"
                >
                  <ClickAwayListener onClickAway={handleCloseDropdown}>
                    <Paper elevation={3} sx={{ p: 1 }}>
                      {item.children?.map((subitem) => (
                        <ListItemButton
                          key={subitem.id}
                          onClick={() => handleSubItemClick(subitem, subitem.text)}
                        >
                          <ListItemText primary={subitem.text} />
                        </ListItemButton>
                      ))}
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              )}

              {item.hasChild && open && (
                <List
                  sx={{
                    display: openSubmenu === item.id ? "block" : "none",
                    pl: "24px",
                    pt: 0,
                  }}
                >
                  {item.children?.map((subitem) => (
                    <ListItem
                      disablePadding
                      key={subitem.id}
                      sx={{ pt: "8px" }}
                    >
                      <ListItemButton
                        sx={{
                          padding: open ? "8px" : "4px 8px",
                          borderRadius: "8px",
                          justifyContent: open ? "initial" : "center",
                          fontSize: "14px",
                          fontFamily: "PoppinsMedium",
                          backgroundColor: isActive(subitem.href)
                            ? "#0A270B"
                            : "transparent",
                          "&:hover, &:focus": {
                            backgroundColor: "#0A270B",
                          },
                        }}
                        onClick={() =>
                          handleSubItemClick(subitem, subitem.text)
                        }
                      >
                        <ListItemText
                          primary={subitem.text}
                          sx={{ color: "white", opacity: open ? 1 : 0, m: 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </React.Fragment>
          ))}
        </List>
        <Divider color="#004C28" sx={{ my: "24px" }} />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <React.Suspense fallback={<span>Loading...</span>}>
          <Outlet />
        </React.Suspense>
      </Main>
    </Box>
  );
}
