import * as React from "react";

function EyeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.92012 12.7132C2.78394 12.4975 2.71584 12.3897 2.67772 12.2234C2.64909 12.0985 2.64909 11.9015 2.67772 11.7766C2.71584 11.6103 2.78394 11.5025 2.92012 11.2868C4.04553 9.50484 7.3954 5 12.5004 5C17.6054 5 20.9553 9.50484 22.0807 11.2868C22.2169 11.5025 22.285 11.6103 22.3231 11.7766C22.3517 11.9015 22.3517 12.0985 22.3231 12.2234C22.285 12.3897 22.2169 12.4975 22.0807 12.7132C20.9553 14.4952 17.6054 19 12.5004 19C7.3954 19 4.04553 14.4952 2.92012 12.7132Z"
        stroke="#8E98A8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5004 15C14.1573 15 15.5004 13.6569 15.5004 12C15.5004 10.3431 14.1573 9 12.5004 9C10.8435 9 9.5004 10.3431 9.5004 12C9.5004 13.6569 10.8435 15 12.5004 15Z"
        stroke="#8E98A8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EyeIcon;
