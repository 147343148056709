import { Box, Stack } from "@mui/material";
import {
  CustomHeading,
  CustomSubHeading,
  TabsCard,
  VerticalTabsCard,
} from "../../../../../ui";
import useCustomTabs from "../../../../../hooks/useCustomTabs";
import React, { useEffect, useRef, useState } from "react";
import { getAllBuilding } from "../../../../../api/scope2-building-api";
import { showToast } from "../../../../../utils/showToast.util";
import { getAllLocation } from "../../../../../api/scope2-location-api";
import BuildingConfiguration from "../building/building-configuration";
import LocationConfiguration from "./location-configuration";

const tabItems = [
  { label: "Building", content: <BuildingConfiguration /> },
  { label: "Location", content: <LocationConfiguration />},
];



 const ConfigurationLocationTabs: React.FC<{
  activeTab: number | undefined;
  activeListTab: number | undefined;
  setIsTabsView: any,
  setIsConfigurationTabsView: any,
  setSelectedTab: any,  
  selectedTab: any,
}> = ({ activeTab, activeListTab, setIsTabsView, setIsConfigurationTabsView, setSelectedTab, selectedTab }) => {

const[locations, setLocations] = useState<any[]>([]);

  const isFetchCalled = useRef(false); 

useEffect(() => {
  const fetchLocations = async () => {
    try {
      const response = await getAllLocation();
      setLocations(response.data);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  if (!isFetchCalled.current) {
    fetchLocations();
    isFetchCalled.current = true;
  }
}, []);
const tabItemslist = locations.map((location) => ({
  label: location.locationName,
  content: <LocationConfiguration  locationId = {location.locationId} setIsTabsView={setIsTabsView} setIsConfigurationTabsView={setIsConfigurationTabsView}/>,
}));

const { TabsHeader } = useCustomTabs(
  tabItems, 
  activeTab, 
  "horizontal", 
  selectedTab === "building" ? 0 : 1,
  (e, val) => setSelectedTab && setSelectedTab(val === 0 ? "building" : "location")
);

  const { TabsHeader: ListHeader, TabContent: ListContent } = useCustomTabs(
    tabItemslist,
    activeListTab,
    "vertical"
  );



  return (
    <Stack spacing={3}>
      <TabsCard>
        <TabsHeader />
      </TabsCard>

      <VerticalTabsCard content={<ListContent />}>
        <Box sx={{ p: "24px 12px 16px" }}>
          <CustomHeading text="Location Configuration" fontSize="16px" />
          <CustomSubHeading
            text="Please select the location you want to apply the configuration on!"
            fontSize="14px"
            sx={{ pt: "8px" }}
          />
        </Box>
        <ListHeader />
      </VerticalTabsCard>
    </Stack>
  );
};
export default ConfigurationLocationTabs