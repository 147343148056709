import { create } from "zustand";

interface AppState {
  dashboardTitle: string;
  setDashboardTitle: (title: string) => void;
}

const useAppStore = create<AppState>((set) => ({
  dashboardTitle: "Dashboard",
  setDashboardTitle: (title: string) => set({ dashboardTitle: title }),
}));

export default useAppStore;
