import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { CustomModal } from "../../../../../ui";
import { getProcessEmissionReason } from "../../../../../api/process-api";

interface ViewReasonProcessEmissionProps {
  processEmissionId: number,
}

const ViewReasonProcessEmission: React.FC<ViewReasonProcessEmissionProps> = ({ processEmissionId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isModalOpen = searchParams.get("action") === "viewReason";
  const [reason, setReason] = useState<string | null>(null);
  const [formattedDate, setFormattedDate] = useState<string | null>(null);

  useEffect(() => {
    const fetchReasonData = async () => {
      if (processEmissionId) {
        try {
          const response = await getProcessEmissionReason(processEmissionId);
          const { reason, updatedAt } = response.data;
          const dateObj = new Date(updatedAt);
          const year = dateObj.getFullYear();
          const month = String(dateObj.getMonth() + 1).padStart(2, '0'); 
          const day = String(dateObj.getDate()).padStart(2, '0');
          const time = dateObj.toLocaleTimeString('en', { hour12: false });

          const formattedDate = `${year}-${month}-${day} ${time}`;
          
          setReason(reason);
          setFormattedDate(formattedDate);
        } catch (error) {
          console.error("Failed to fetch reason:", error);
        }
      }
    };

    if (isModalOpen) {
      fetchReasonData();
    }
  }, [processEmissionId, isModalOpen]);

  const handleClose = () => {
    navigate(location.pathname, { replace: true });
  };

  return (
    <CustomModal open={isModalOpen} onClose={handleClose} maxWidth="sm">
      <Box sx={{ position: "relative", padding: "24px" }}>
        <Typography variant="h6" sx={{ textAlign: "left", fontWeight: "bold", marginBottom: "16px" }}>
          Reason
        </Typography>

        <IconButton onClick={handleClose} sx={{ position: "absolute", top: "16px", right: "16px", color: "black" }}>
          <CloseIcon />
        </IconButton>

        <Box>
          <Typography variant="h6" gutterBottom sx={{ textAlign: "left", marginBottom: "8px", color: "#9CA3AF" }}>
            Emission Edit Reason
          </Typography>
          <Box sx={{ backgroundColor: "#F3F4F6", padding: "16px", borderRadius: "8px", mt: 2 }}>
            <Typography variant="body1" sx={{ color: "#4B5563", textAlign: "left" }}>
              {reason || "No reason available"}
            </Typography>
            <Typography variant="body2" sx={{ display: "block", textAlign: "right", color: "#9CA3AF", mt: 1, fontSize: "14px" }}>
              {formattedDate || ""}
            </Typography>
          </Box>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default ViewReasonProcessEmission;
