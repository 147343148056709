import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { MutationConfig } from "../../../../../utils/queryClient";
import { createFugitive, CreateFugitiveData } from "../../../../../api/fugitive-emission-api";


export const useFugitiveEmission = (
  config?: MutationConfig<typeof createFugitive>
): UseMutationResult<void, AxiosError, CreateFugitiveData> => {
  return useMutation<void, AxiosError, CreateFugitiveData>({
    mutationFn: createFugitive,
    ...config,
  });
};



