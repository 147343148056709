import * as React from "react";

function EmailIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 7L10.6649 12.7154C11.3261 13.1783 11.6567 13.4097 12.0163 13.4993C12.3339 13.5785 12.6661 13.5785 12.9837 13.4993C13.3433 13.4097 13.6739 13.1783 14.3351 12.7154L22.5 7M7.3 20H17.7C19.3802 20 20.2202 20 20.862 19.673C21.4265 19.3854 21.8854 18.9265 22.173 18.362C22.5 17.7202 22.5 16.8802 22.5 15.2V8.8C22.5 7.11984 22.5 6.27976 22.173 5.63803C21.8854 5.07354 21.4265 4.6146 20.862 4.32698C20.2202 4 19.3802 4 17.7 4H7.3C5.61984 4 4.77976 4 4.13803 4.32698C3.57354 4.6146 3.1146 5.07354 2.82698 5.63803C2.5 6.27976 2.5 7.11984 2.5 8.8V15.2C2.5 16.8802 2.5 17.7202 2.82698 18.362C3.1146 18.9265 3.57354 19.3854 4.13803 19.673C4.77976 20 5.61984 20 7.3 20Z"
        stroke="#8E98A8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EmailIcon;
