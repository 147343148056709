
import Search from "@mui/icons-material/Search";
import { axiosInstanceWithAuth   } from "../utils/axios";

export interface CreateFuelData {
fuelTypeId: number,
measuringUnitId: number,
emissionFactorId: number,
measuringValue: number,
protocol: string,
dataCollectionMethod: string,
}


export interface MeasurementData {
  fuelTypeId: number,
}

export interface EmissionFactorData{
  fuelTypeId: number,
  measureId?: number,
}

export interface GetFuelCombustionData {
  // search?: string,
protocol?: string,
page?: number,
size?: number,
sort?: string 
}

export interface getFuelCombustionByFileIdData{
  fileId: number,
  page?: number,
size?: number,
sort?: string 
}


export interface GetFuelCombustionDataById {
  fuelCombustionEmissionId: number,
}

export interface UpdateFuelCombustionData {
  fuelTypeId: number,
  measuringUnitId: number,
  emissionFactorId: number,
  measuringValue: number,
  protocol: string,
  dataCollectionMethod: string,
  reason : string
}

export interface GetUploadedFileData {
  search?: string;
  typeOfEmission: string;
  page?: number;
  size?: number;
  sort?: string;
}

export interface CalculateFuelEmissionData{
  measuringValue: number,
  emissionFactorId: number,
  measuringUnitId: number,
}

export interface ErrorResponse {
    response?: {
      data?: {
        message?: string;
      };
      status?: number;
      statusText?: string;
    };
    message?: string;
  }

export const createFuelCombustion = async (data: CreateFuelData) => {
    try {
      const response = await axiosInstanceWithAuth.post(`/fuel-combustion-emission`, data);
      return response.data;
    } catch (error: any) {
      console.error("Create Fuel Combustion failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Create Fuel Combustion failed.";
    }
  };

  export const calculateFuelEmission = async (data: CalculateFuelEmissionData) => {
    try {
      const response = await axiosInstanceWithAuth.post(`/fuel-combustion-emission/calculate-emission`, data);
      console.log("response of calculate Emission Data", response.data)
      return response.data;
    } catch (error: any) {
      console.error("Create Fuel Combustion failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Create Fuel Combustion failed.";
    }
  };



  export const getFuelType = async () => {
    try {
      const response = await axiosInstanceWithAuth.get('/fuel-type');
      return response.data.data;
    } catch (error: any) {
      console.error("Get Fuel Type failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Get Fuel Type failed.";
    }
  };

  export const getMeasurementValue = async (data: MeasurementData) => {
    try {
      const response = await axiosInstanceWithAuth.get(`/unit/${data.fuelTypeId}`);
      return response.data;
    } catch (error: any) {
      console.error("Get Measurement data failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Get Measurement Data failed.";
    }
  };
  
  export const getEmissionFactor = async (data: EmissionFactorData) => {
    try {
      const response = await axiosInstanceWithAuth.get(`/emission-factor/${data.fuelTypeId}/${data.measureId}`);
      return response.data;
    } catch (error: any) {
      console.error("Get Emission Factor failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Get Emission Factor failed.";
    }
  };

  export const getFuelCombustion = async (data: GetFuelCombustionData) => {
    try {
      const response = await axiosInstanceWithAuth.get(
        `/fuel-combustion-emission`, 
        {
          params: {
            // search: data.search,
            protocol: data.protocol, 
            page: data.page,      
            size: data.size,      
            sort: data.sort,         
          }
        }
      );
      return response.data;
    } catch (error: any) {
      console.error("Get Fuel Combustion By Protocol failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Get Fuel Combustion By Protocol failed.";
    }
  };

  export const getFuelCombustionById = async (id: number) => {
    try {
      const response = await axiosInstanceWithAuth.get(`/fuel-combustion-emission/${id}`);
      return response.data;
    } catch (error: any) {
      console.error("Get Fuel data  By Id failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Get Fuel Data By Id failed.";
    }
  }


  export const updateFuelCombustion = async ( id: number, data: UpdateFuelCombustionData) => {
    try {
      const response = await axiosInstanceWithAuth.put(`/fuel-combustion-emission/${id}`, data)
      return response.data;
    } catch (error: any) {
      console.error("Update Fuel data failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Update Fuel Data failed.";
    }
  };


  export const getFuelCombustionReason = async ( id: number) => {
    try {
      const response = await axiosInstanceWithAuth.get(`/fuel-combustion-emission/${id}/reason`)
      console.log("reason api", response)
      return response.data;
    } catch (error: any) {
      console.error("Update Fuel data failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Update Fuel Data failed.";
    }
  };

  export const uploadCsvFile = async (file: File, uploadUrl: string) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      console.log("formDtaa", formData)
      const response = await axiosInstanceWithAuth.post(uploadUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      
      console.log("File upload response:", response);
      return response.data;
    } catch (error: any) {
      console.error("File upload failed", error.response?.data || error.message);
      throw error.response?.data?.message || "File upload failed.";
    }
  };

  export const getFuelCombustionByFileId = async (data: getFuelCombustionByFileIdData) => {
    try {
      const response = await axiosInstanceWithAuth.get(
        `/fuel-combustion-emission`, 
        {
          params: {
            fileId: data.fileId, 
            page: data.page,      
            size: data.size,      
            sort: data.sort,         
          }
        }
      );
      return response.data;
    } catch (error: any) {
      console.error("Get Fuel Combustion Emission By File Id failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Get Fuel Combustion Emission By File Id failed.";
    }
  };

 
  
  export const getUploadedFiles = async (data: GetUploadedFileData) => {
    try {
      const response = await axiosInstanceWithAuth.get(`/emission-file`, {
        params: {
          search: data.search || "", 
          typeOfEmission: data.typeOfEmission,
          page: data.page || 0, 
          size: data.size || 10,
          sort: data.sort || "createdDate,desc",
        },
      });
     
      return response.data; 
      
    } catch (error: any) {
      console.error("Error fetching uploaded files", error.response?.data || error.message);
      throw error.response?.data?.message || "Error fetching uploaded files.";
    }
  }; 


  export const fuelCombustionEmission = async (data: CreateFuelData) => {
    try {
      const response = await axiosInstanceWithAuth.post('/fuel-combustion-emission', data);
      console.log("Fuel Emission response:", response.data);
      return response.data;
    } catch (error: any) {
      console.error("Fuel Combustion Emission failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Fuel Combustion Emission failed.";
    }
  };

  
  export const sampleDownloadTemplate = async (sampleName: string) => {
    try {
      const response = await axiosInstanceWithAuth.get(`/aws/download-sample?sampleName=${sampleName}`);
      console.log("Fuel Emission response:", response.data);
      return response.data;
    } catch (error: any) {
      console.error("Fuel Combustion Emission failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Fuel Combustion Emission failed.";
    }
  };
  
  



