import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CustomButton,
  CustomHeading,
  CustomModal,
  CustomSubHeading,
  CustomTextField,
} from "../../../../../ui";
import {
  getEmissionFactor,
  getFuelType,
  getMeasurementValue,
  getFuelCombustionById,
  updateFuelCombustion,
  CalculateFuelEmissionData,
  calculateFuelEmission,
} from "../../../../../api/fuel-api";
import { ChevronDown } from "../../../../../icons";
import { useFuel } from "./useFuel";
import { showToast } from "../../../../../utils/showToast.util";

const schema = z.object({
  fuelType: z.string().min(1, "Fuel Type is required"),
  measurementValue: z.preprocess(
    (val) => (val === "" ? null : Number(val)),
    z
      .number()
      .nullable()
      .refine((val) => val === null || val > 0, "Must be a positive number")
      .refine(
        (val) => val === null || val.toString().length >= 3,
        "Minimum 3 digits are required"
      )
      .refine(
        (val) => val === null || val.toString().length <= 15,
        "Maximum 15 digits are allowed"
      )
      .refine(
        (val) => val === null || /^\d+(\.\d{1,3})?$/.test(val.toString()),
        "Decimal places up to 3 are allowed"
      )
  ),
  unit: z.string().min(1, "Unit is required"),
  dataCollectionMethod: z.string().min(1, "Data Collection Method is required"),
  emissionFactor: z.preprocess(
    (val) => (val === "" ? null : Number(val)),
    z
      .number()
      .nullable()
      .refine((val) => val === null || val > 0, "Must be a positive number")
  ),
  protocol: z.string().min(1, "Protocol is required"),
  reason: z.string().optional(),
});

type FormData = z.infer<typeof schema>;

interface AddEditFuelCombustionProps {
  onFuelCombustionData: (data: any[]) => void;
  combustionEmissionId: any;
  handleRowUpdate: any;
  fetchFuelCombustionData: any;
  currentPage: number;
  currentFilePage: number;
  fetchUploadedFiles: any;
}

const AddEditFuelCombustion: React.FC<AddEditFuelCombustionProps> = ({
  onFuelCombustionData,
  combustionEmissionId,
  handleRowUpdate,
  fetchFuelCombustionData,
  currentPage,
  currentFilePage,
  fetchUploadedFiles
  
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [fuelTypes, setFuelTypes] = useState<any[]>([]);
  const [measureValue, setMeasureValue] = useState<any[]>([]);
  
  const [emissionFactorId, setEmissionFactorId] = useState<number | null>(null); 
  const [calculatedEmissions, setCalculatedEmission] = useState<number | null>(null);
  const [unitName, setUnitName] = useState<string>("");

  const isEditing = searchParams.get("action") === "edit";
  const modalTitle = isEditing ? "Edit Emission" : "Add Data Manually";
  const isModalOpen =
    searchParams.get("action") === "add" ||
    searchParams.get("action") === "edit";

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      fuelType: "",
      measurementValue: null,
      unit: "",
      emissionFactor: null,
      dataCollectionMethod: "",
      protocol: "",
      reason: "",
    },
  });

  const { watch, setValue, reset } = methods;
  const fuelType = watch("fuelType");
  const unit = watch("unit");

  const { mutate: createFuelCombustion } = useFuel({
    onSuccess: (data) => {
      const {message} = data;
      showToast(message);
      fetchFuelCombustionData(currentPage)
      fetchUploadedFiles(currentFilePage)
      handleClose();
    },
    onError: (error: any) => {
      
      showToast(error, "error");
    },
  });

  const fetchCombustionById = async () => {
    try {
      const data = await getFuelCombustionById(combustionEmissionId);
      reset({
        fuelType: data?.data.fuelType?.id.toString() || "",
        measurementValue: data?.data.measuringValue || null,
        unit: data?.data.measuringUnit?.id.toString() || "",
        emissionFactor: data?.data.emissionFactor?.factor || null,
        protocol: data?.data.protocol || "",
        dataCollectionMethod: data?.data.dataCollectionMethod || "",
        reason: data?.data.reason || "",
      });
      setCalculatedEmission(data?.data.calculatedEmission || null);
    } catch (error: any) {
      console.error(error, "error");
    }
  };

  useEffect(() => {
    if (!isEditing && isModalOpen) {
      reset({
        fuelType: "",
        measurementValue: null,
        unit: "",
        emissionFactor: null,
        dataCollectionMethod: "",
        protocol: "",
        reason: "",
      });
      setCalculatedEmission(null);
    }
  }, [isModalOpen, isEditing, reset]);

  useEffect(() => {
    if (combustionEmissionId > 0 && isEditing) {
      fetchCombustionById(); 
    }
  }, [combustionEmissionId, isEditing]); 

  useEffect(() => {
    const fetchFuelTypes = async () => {
      try {
        const data = await getFuelType();
        setFuelTypes(data);
      } catch (error) {
        console.error("Fetch Fuel Type Failed!", error);
      }
    };
    if (!fuelType) {
      fetchFuelTypes();
    }
  }, [fuelType]);

  useEffect(() => {
    const fetchMeasuringUnits = async () => {
      if (!fuelType) return;
      try {
        const data = await getMeasurementValue({
          fuelTypeId: Number(fuelType),
        });
        setMeasureValue(data.data);
      } catch (error) {
        console.error("Fetch Measurement Value Failed!", error);
      }
    };
    fetchMeasuringUnits();
  }, [fuelType]);

  useEffect(() => {
    const fetchEmissionFactor = async () => {
      if (!fuelType || !unit) return;

      try {
        const response = await getEmissionFactor({
          fuelTypeId: Number(fuelType),
          measureId: Number(unit),
        });
        setEmissionFactorId(response.data.id);
        setValue("emissionFactor", response.data.factor);
      } catch (error) {
        console.error("Fetch Emission Factor Failed!", error);
      }
    };

    fetchEmissionFactor();
  }, [fuelType, unit, setValue]);

  const calculateEmission = async (data: FormData) => {
    try {
      const payload: CalculateFuelEmissionData = {
        measuringValue: Number(data.measurementValue),
        emissionFactorId: Number(emissionFactorId),
        measuringUnitId: Number(data.unit),
      };
      const response = await calculateFuelEmission(payload);
      setCalculatedEmission(response.data.calculatedEmission);
      setUnitName(response.data.unitName);
   
    } catch (error: any) {
      console.error("Calculate Emission failed", error);
      showToast(error, "error");
      
    }
  };

  

  const saveEmission = async (data: FormData) => {
    if (
      data.measurementValue === null ||
      data.emissionFactor === null ||
      !emissionFactorId
    ) {
      console.error(
        "Measurement value, emission factor, or emission factor ID is missing"
      );
      return;
    }
  
    const payload = {
      fuelTypeId: Number(data.fuelType),
      measuringUnitId: Number(data.unit),
      emissionFactorId: emissionFactorId,
      measuringValue: data.measurementValue,
      protocol: data.protocol.toUpperCase() || "",
      dataCollectionMethod: data.dataCollectionMethod || "",
      reason: data.reason || "",
    };
  
    try {
      if (isEditing) {
        const response = await updateFuelCombustion(
          combustionEmissionId,
          payload
        );
        if (handleRowUpdate) {
          const updatedRow = {
            combustionEmissionId,
            fuelType: data.fuelType, 
            measurementValue: data.measurementValue,
            unit: data.unit,
            emissionFactor: data.emissionFactor,
            protocol: data.protocol,
            dataCollectionMethod: data.dataCollectionMethod,
            reason: data.reason,
          };
          showToast(response.message);
          fetchFuelCombustionData(currentPage)
          handleRowUpdate(updatedRow);
        }
      } else {
       await createFuelCombustion(payload);
      }
      handleClose();
    } catch (error: any) {
      showToast(error, "error");
    }
  };
  const handleClose = () => {
    methods.reset();
    navigate(location.pathname, { replace: true });
  };
  const extraFields = isEditing
    ? [
        {
          name: "reason",
          label: "Reason To edit",
          placeholder: "Enter The Reason why you want to edit Emission",
          type: "textarea",
          col: 12,
        },
      ]
    : [];

  const fields = [
    {
      name: "fuelType",
      label: "Type of Fuel",
      placeholder: "Select Fuel Type",
      select: true,
      icon: <ChevronDown />,
      options: fuelTypes.map((fuelType) => ({
        value: fuelType.id.toString(),
        label: fuelType.name,
      })),
      col: 12,
      onChange: (e: any) => {
        setValue("unit", "");
      },
    },
    {
      name: "measurementValue",
      label: "Measurement Value",
      placeholder: "Enter value",
      type: "number",
      col: 12,
    },
    {
      name: "unit",
      label: "Unit",
      placeholder: "Select Unit",
      select: true,
      icon: <ChevronDown />,
      options: measureValue.map((measure) => ({
        value: measure.id.toString(),
        label: measure.unitName,
      })),
      col: 12, 
      disabled: !fuelType,
    
    },
    {
      name: "protocol",
      label: "Protocol",
      placeholder: "Select Protocol",
      select: true,
      icon: <ChevronDown />,
      options: [
        { value: "csrd", label: "CSRD" },
        { value: "secr", label: "SECR" },
      ],
      col: 12,
    },
    {
      name: "emissionFactor",
      label: "Emission Factor",
      placeholder: "Enter Emission Factor",
      type: "number",
      disabled: true,
      col: 6,
    },

    {
      name: "dataCollectionMethod",
      label: "Data Collection Method",
      placeholder: "Select Data Collection Method",
      select: true,
      icon: <ChevronDown />,
      options: [
        { value: "Meter Reading", label: "Meter Reading" },
        { value: "Direct Measurement", label: "Direct Measurement" },
      ],
      col: 6,
    },
    ...extraFields,
  ];

  return (
    <CustomModal
      title={modalTitle}
      open={isModalOpen}
      onClose={handleClose}
      maxWidth="sm"
      actions={[
        { text: "Discard", onClick: handleClose, bgColor: "transparent" },
        {
          text: isEditing ? "Update Emission" : "Save Emission",
          type: "submit",
          onClick: () => {
            methods.handleSubmit(saveEmission)();
          },
        },
      ]}
    >
      <Box mt={3}>
        <CustomHeading text="Fuel Combustion Emissions" variant="h5" />
        <CustomSubHeading text="Please provide the following details to calculate the fuel combustion emissions." />
      </Box>
      <FormProvider {...methods}>
        <form>
          <Grid container spacing={3} mt={0}>
            {fields.map((field, index) => (
              <Grid item xs={12} sm={field.col} key={index}>
                <CustomTextField {...field} />
              </Grid>
            ))}
          </Grid>
        </form>
      </FormProvider>
      <Box mt={3}>
        <CustomButton
          fullWidth
          text="Calculate Emission"
          onClick={methods.handleSubmit(calculateEmission)}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
            mb: 4,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "#090909",
              fontFamily: "PoppinsMedium",
              display: "flex",
              alignItems: "center",
              gap: 1,
              lineHeight: 1,
            }}
          >
            Emission Calculated
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: "#090909",
              fontSize: 18,
              fontWeight: 600,
              lineHeight: 1,
            }}
          >
            {calculatedEmissions !== null ? `${calculatedEmissions} ${unitName}` : ""}
          </Typography>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default AddEditFuelCombustion;
