import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { FC } from "react";
import { signupImage } from "../../assets/images";
import { Logo } from "../../icons";
import { CustomHeading, CustomSubHeading } from "../../ui";

interface AuthLayoutProps {
  title: string;
  subTitle: string;
  children: React.ReactNode;
  margin?: boolean;
}

const AuthLayout: FC<AuthLayoutProps> = ({
  title = "",
  subTitle = "",
  children,
  margin = false,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("laptop"));
  return (
    <Grid
      container
      sx={{ minHeight: "100vh", p: "20px", gap: matches ? 0 : 2 }}
    >
      <Grid
        item
        xs={12}
        laptop={6}
        sx={{
          display: "grid",
          px: !matches ? "0px" : 16,
          position: "relative",
          placeItems: !matches ? "center" : "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            left: matches ? "128px" : 0,
          }}
        >
          <Logo />
        </Box>
        <Box
          sx={{
            width: "100%",
            mt: 13,
          }}
        >
          <CustomHeading
            gutterBottom
            text={title}
            sx={{ color: "#090909" }}
            fontSize="2.25rem"
          />
          <CustomSubHeading text={subTitle} gutterBottom />
          <Box mt={3}>{children}</Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        laptop={6}
        sx={{
          backgroundColor: "primary.main",
          p: 4,
          borderRadius: "16px",
          textAlign: "center",
          aspectRatio: "3/2",
        }}
      >
        <CustomHeading
          gutterBottom
          text="Transform Your Sustainability Management"
          textAlign="center"
          sx={{ color: "#090909", fontWeight: "700", marginTop: "80px" }}
          fontSize="2.25rem"
        />

        <CustomSubHeading
          text="Track, analyze, and reduce your business emissions with ease. Planet
          Pulse offers comprehensive tools."
          textAlign="center"
          gutterBottom
          sx={{ color: "#1F1F1F" }}
        />

        <Box
          component="img"
          src={signupImage}
          alt="Dashboard preview"
          style={{
            marginTop: "1rem",
            width: "100%",
            maxWidth: "600px",
            height: "auto",
          }}
        ></Box>
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
