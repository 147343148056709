import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { MutationConfig } from "../../../../../utils/queryClient";
import { CreateProcessData, createProcessEmission } from "../../../../../api/process-api";


export const useProcessEmission = (
  config?: MutationConfig<typeof createProcessEmission>
): UseMutationResult<void, AxiosError, CreateProcessData> => {
  return useMutation<void, AxiosError, CreateProcessData>({
    mutationFn: createProcessEmission,
    ...config,
  });
};



