import { axiosInstanceWithAuth } from "../utils/axios";

export interface CalculateLocationEmissionData{
 
  scopeTwoLocationEmissionType: string;
  measuringValue: number;
 locationId: number
}

export interface SaveLocationEmissionParams{
  locationId: number;
  data: {
    locationEmissionType: string;
    measuringValue: number;
  }[];
}

export const getAllLocation = async () => {
    try {
      const response = await axiosInstanceWithAuth.get(`/location`);
      return response.data;
    } catch (error: any) {
      console.error("Get All Location failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Get All Location failed.";
    }
  }

  export const createOrUpdateLocationPreferences = async (locationId: number, preferences: any) => {
    try {
      const response = await axiosInstanceWithAuth.put(`/scope-two/location-preferences/${locationId}`, preferences);
      return response.data;
    } catch (error: any) {
      console.error("Error updating location preferences", error.response?.data || error.message);
      throw error.response?.data?.message || "Error updating location preferences.";
    }
  };

  export const getLocationPreferences = async (id: number) => {
    try {
      const response = await axiosInstanceWithAuth.get(`/scope-two/location-preferences/${id}`);
      return response.data;
    } catch (error: any) {
      console.error("Get Location Preferences By Id failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Get Location Preferences By Id failed.";
    }
  }

  export const calculateScope2LocationEmission = async(data: CalculateLocationEmissionData)=>{
   
      try {
        const response = await axiosInstanceWithAuth.post("/scope-two/calculate-location-emission", data);
        return response.data;
      } catch (error) {
        console.error("Error calculating building emission:", error);
        throw error;
      }
  }


  export const saveScope2LocationEmission = async (data: SaveLocationEmissionParams) => {
    try {
      const response = await axiosInstanceWithAuth.post(`/scope-two/location-emission`, data);
      return response.data;
    } catch (error) {
      console.error("Error fetching location emission data:", error);
      throw error;
    }
  }; 


  export const getScope2LocationEmission = async (locationId: number) => {
    try {
      const response = await axiosInstanceWithAuth.get(`/scope-two/location-emission?locationId=${locationId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching location emission data:", error);
      throw error;
    }
  };

  export const deleteScope2LocationEmission = async (emisssionId: number) => {
    try {
      const response = await axiosInstanceWithAuth.delete(`/scope-two/location-emission?emissionId=${emisssionId}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting building emission data:", error);
      throw error;
    }
  };  
