import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomButton, CustomHeading, CustomSubHeading } from "../../../../ui";
import { BuildingEmissionTabs } from "./building/building-emission-tabs";
import { LocationEmissionTabs } from "./location/location-emission-tabs";
import ConfigurationBuildingTabs from "./building/configuration-building-tabs";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ConfigurationLocationTabs from "./location/configuration-location-tabs";

const emissionsData = [
  {
    title: "Building & Location",
    description: "Calculate your building & location Emissions",
  },
];

const Scope2Emission: React.FC = () => {
  const [activeListTab, setIsActiveListTab] = useState<{
    show: boolean;
    active: number | undefined;
  }>({ show: false, active: undefined });

  const [isTabsView, setIsTabsView] = useState<{
    show: boolean;
    active: number | undefined;
  }>({ show: false, active: undefined });

  const [isConfigurationTabsView, setIsConfigurationTabsView] = useState<{
    show: boolean;
    active: number | undefined;
  }>({ show: false, active: undefined });

  const [selectedTab, setSelectedTab] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const activeEmission = searchParams.get("emission"); 
  
  const handleTrackEmission = () => {
    setIsTabsView({ show: true, active: undefined });
    setIsActiveListTab({ show: true, active: undefined });
    setIsConfigurationTabsView({ show: false, active: undefined })
    navigate(`/measure/scope2-emissions?emission=building`);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const emissionParam = queryParams.get("emission");
    if (emissionParam === "building" || emissionParam === "location") {
      setSelectedTab(emissionParam);
      setIsTabsView({
        show: true,
        active: emissionParam === "building" ? 0 : 1,
      });
    }
  }, [activeEmission]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const emissionParam = queryParams.get("emission");
    const actionParam = queryParams.get("action");

    if (emissionParam === "building" || emissionParam === "location") {
      setSelectedTab(emissionParam);
      setIsTabsView({
        show: true,
        active: emissionParam === "building" ? 0 : 1,
      });
    }

    if (actionParam === "config") {
      setIsConfigurationTabsView({ show: true, active: undefined });
      setIsTabsView({ show: false, active: undefined });
    }
  }, [activeEmission]);

  useEffect(() => {
    if (isConfigurationTabsView.show) {
      setIsTabsView({ show: false, active: undefined });
    }
  }, [isConfigurationTabsView]); 

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const emissionParam = queryParams.get("emission");
    if (emissionParam === "building" || emissionParam === "location") {
      setSelectedTab(emissionParam);
      setIsTabsView({
        show: true,
        active: emissionParam === "building" ? 0 : 1, 
      });
    }
  }, [activeEmission]);


  useEffect(() => {
    if (
      selectedTab === "building" ||
      selectedTab === "location" ||
      activeEmission === "building" ||
      activeEmission === "location"
    ) {
      setIsTabsView({
        show: true,
        active:
          selectedTab === "building" || activeEmission === "building" ? 0 : 1,
      });
      setIsConfigurationTabsView({ show: false, active: undefined });
      navigate(
        `/measure/scope2-emissions?emission=${activeEmission ?? selectedTab}`
      );
    }
  }, [selectedTab]);


  const TabMap = {
    building: (
      <BuildingEmissionTabs
        setIsConfigurationTabsView={setIsConfigurationTabsView}
        activeTab={isTabsView.active}
        activeListTab={activeListTab.active}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />
    ),
    location: (
      <LocationEmissionTabs
        setIsConfigurationTabsView={setIsConfigurationTabsView}
        activeTab={isTabsView.active}
        activeListTab={activeListTab.active}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />
    ),
    buildingConfig: (
      <ConfigurationBuildingTabs
        activeTab={isTabsView.active}
        activeListTab={activeListTab.active}
        setIsTabsView={setIsTabsView}
        setIsConfigurationTabsView={setIsConfigurationTabsView}
      />
    ),
    locationConfig: (
      <ConfigurationLocationTabs
        activeTab={isTabsView.active}
        activeListTab={activeListTab.active}
        setIsTabsView={setIsTabsView}
        setIsConfigurationTabsView={setIsConfigurationTabsView}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />
    ),
  };

  const renderContent = () => {
    type TabKey = keyof typeof TabMap;
    if (
      isTabsView.show &&
      !isConfigurationTabsView.show &&
      activeEmission &&
      selectedTab
    ) {
      return TabMap[selectedTab as TabKey];
    }

    if (
      !isTabsView.show &&
      isConfigurationTabsView.show &&
      activeEmission &&
      selectedTab
    ) {
      return selectedTab === "building"
        ? TabMap["buildingConfig"]
        : TabMap["locationConfig"];
    }

    return null;
  };
  return (
    <>
      {(!activeEmission ||
        (!isTabsView.show && !isConfigurationTabsView.show)) && (
        <Box
          sx={{
            background: "#fff",
            borderRadius: "24px",
            p: { sm: "70px 23px", xs: "30px 16px" },
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <CustomHeading text="Scope 2 Emissions Tracking" fontSize="30px" />
            <CustomSubHeading
              text="Scope 2 emissions tracking monitors direct greenhouse gases to ensure"
              fontSize="14px"
              sx={{ pt: "12px" }}
            />
            <CustomSubHeading
              text="compliance and boost sustainability."
              fontSize="14px"
            />
          </Box>
          <Grid container spacing={"24px"} sx={{ pt: "40px" }}>
            {emissionsData.map(({ title, description }, index) => (
              <Grid item xs={12} sm={6} lg={4} xl={3} key={index}>
                <Box
                  sx={{
                    border: "1px solid #D0D5DD",
                    p: "16px",
                    borderRadius: "16px",
                  }}
                >
                  <CustomHeading text={title} fontSize="18px" />
                  <CustomSubHeading
                    text={description}
                    fontSize="14px"
                    sx={{ pt: "8px" }}
                  />
                  <Box sx={{ pt: "70px" }}>
                    <CustomButton
                      text="Track Emissions"
                      onClick={handleTrackEmission}
                      sx={{ fontWeight: 500 }}
                    />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {renderContent()}
    </>
  );
};

export default Scope2Emission;
