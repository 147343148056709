import React from "react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyles from "./styles/GlobalStyles";
import theme from "./styles/theme";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./utils/queryClient";
import { CrossIcon } from "./icons";
import { UserProvider } from "./context/user-context";
import { ProfileProvider } from "./context/profile-context";
import { BuildingProvider } from "./context/building-context";
import { LocationProvider } from "./context/location-context";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      closeButton={<CrossIcon />}
    />
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles theme={theme} />
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <BuildingProvider>
              <LocationProvider>
                <ProfileProvider>
                  <App />
                </ProfileProvider>
              </LocationProvider>
            </BuildingProvider>
          </UserProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
