import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyEmail } from "../../../api/auth-api";
import { showToast } from "../../../utils/showToast.util";
import { CircularProgress, Box } from "@mui/material";

const EmailVerification: React.FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [keyValue, setKeyValue] = useState<string>("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const searchParams = new URLSearchParams(location.search);
    const keyParam = searchParams.get("key");

    if (keyParam) {
      setKeyValue(keyParam);
    } else if (!keyParam) {
      console.error("Email Verification Key is missing in the URL");
      showToast("Email Verification Key is missing in the URL.");
      setTimeout(() => navigate("/signup"), 3000);
    }
  }, [location.search]);

  useEffect(() => {
    if (keyValue !== "") {
      emailVerification(keyValue);
    }
  }, [keyValue]);

  const emailVerification = async (key: string) => {
    if (!key) {
      console.error("Email Verification key is missing");
      return;
    }
    try {
      const response = await verifyEmail({ key });
      setKeyValue("");
      setLoading(false);
      if (response.success) {
        localStorage.setItem("verifiedEmail", response.data.email);
        localStorage.setItem("isNewProfileSetup", "true");
        showToast(response.message);
        setTimeout(() => {
          navigate(`/create-profile?key=${key}`);
        }, 3000);
      } else {
        showToast("Email verification failed.");
        setTimeout(() => {
          navigate("/signup");
        }, 3000);
      }
    } catch (error: any) {
      setKeyValue("");
      setLoading(false);
      showToast(error);
      setTimeout(() => {
        navigate("/signup");
      }, 3000);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      {isLoading && <CircularProgress />}
    </Box>
  );
};

export default EmailVerification;
