import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { SignupData, signupUser } from "../../../api/auth-api";
import { MutationConfig } from "../../../utils/queryClient";

export const useSignup = (
  config?: MutationConfig<typeof signupUser>
): UseMutationResult<void, AxiosError, SignupData> => {
  return useMutation<void, AxiosError, SignupData>({
    mutationFn: signupUser,
    ...config,
  });
};

