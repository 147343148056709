import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { SignupData, loginUser } from "../../../api/auth-api";
import { MutationConfig } from "../../../utils/queryClient";

export const useLogin = (
  config?: MutationConfig<typeof loginUser>
): UseMutationResult<void, AxiosError, SignupData> => {
  return useMutation<void, AxiosError, SignupData>({
    mutationFn: loginUser,
    ...config,
  });
};