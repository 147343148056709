import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Box, Stack, Tabs, Tab } from "@mui/material";
import {
  CustomHeading,
  CustomSubHeading,
  TabsCard,
  VerticalTabsCard,
} from "../../../../../ui";
import useCustomTabs from "../../../../../hooks/useCustomTabs";
import { getAllBuilding } from "../../../../../api/scope2-building-api";
import { showToast } from "../../../../../utils/showToast.util";
import BuildingEmissions from "./scope1-building-emissions";
import Scope1BuildingEmissions from "./scope1-building-emissions";
import LocationEmissions from "../location/scope1-location-emissions";

export const Scope1BuildingEmissionTabs: React.FC<{
  activeTab: number | undefined;
  activeListTab: number | undefined;
  setIsConfigurationTabsView?: Dispatch<SetStateAction<{ show: boolean; active: number | undefined; }>>;
  setSelectedTab?: (tab: string) => void;
  selectedTab: string;
}> = ({
  activeTab,
  activeListTab,
  setIsConfigurationTabsView,
  selectedTab,
  setSelectedTab,
}) => {
  const [buildings, setBuildings] = useState<any[]>([]);

  const isFetchCalled = useRef(false);

  useEffect(() => {
    const fetchBuildings = async () => {
      try {
        const response = await getAllBuilding();
        setBuildings(response.data);
      } catch (error: any) {
        console.error("Error fetching buildings:", error);
        showToast(error, "error");
      }
    };
    if (!isFetchCalled.current) {
      fetchBuildings();
      isFetchCalled.current = true;
    }
  }, [buildings]);

  const tabItems = [
    {
      label: "Building",
      content: (
        <Scope1BuildingEmissions
          setIsConfigurationTabsView={setIsConfigurationTabsView} 
          buildingId = {0}
        />
      ),
    },
    {
      label: "Location",

      content: (
        <LocationEmissions
          setIsConfigurationTabsView={setIsConfigurationTabsView}
        />
      ),
    },
  ];
  const tabItemslist = buildings.map((building) => ({
    label: building.buildingName,
    content: (
      <Scope1BuildingEmissions
        setIsConfigurationTabsView={setIsConfigurationTabsView}
        buildingId={building.buildingId}
      />
    ),
  }));

  const { TabsHeader } = useCustomTabs(
    tabItems,
    activeTab,
    "horizontal",
    selectedTab === "building" ? 0 : 1,
    (e, val) =>
      setSelectedTab && setSelectedTab(val === 0 ? "building" : "location")
  );
  const { TabsHeader: ListHeader, TabContent: ListContent } = useCustomTabs(
    tabItemslist,
    activeListTab,
    "vertical"
  );

  return (
    <Stack spacing={3}>
      <TabsCard>
        <TabsHeader />
      </TabsCard>
      <VerticalTabsCard content={<ListContent />}>
        <Box sx={{ p: "24px 12px 16px" }}>
          <CustomHeading text="Building Emissions" fontSize="16px" />
          <CustomSubHeading
            text="Please select the building you want to apply the configuration on!"
            fontSize="14px"
            sx={{ pt: "8px" }}
          />
        </Box>
        <ListHeader />
      </VerticalTabsCard>
    </Stack>
  );
};
