import React, { FC, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomTextField from "../custom-textfield/custom-textfield";
import { useBuildingContext } from "../../context/building-context";
import { showToast } from "../../utils/showToast.util";
import { styles } from "../custom-accordion-table/custom-accordion-table.style";
import { calculateScope1BuildingEmission } from "../../api/scope1-building-api";

interface Column {
  key: string;
  label: string;
  flexBasis: string;
}

interface Row {
  facilityName: string;
  amount: string;
  emissions: string;
  actions: string;
  detailsType?: string;
}

interface Scope1BuildingAccordionProps {
  columns: Column[];
  rows: Row[];
  expandedRowIndex: number | null;
  onExpandRow: (index: number | null) => void;
  onEmissionTypeChange: (emissionType: string) => void;
  buildingId: number;
  onEmissionsUpdate: (data: any) => void;
  onEmissionBuildingTypeChange: (emissionBuildingType: string) => void;
  setRows: any;
}

const Scope1BuildingAccordion: FC<Scope1BuildingAccordionProps> = ({
  columns,
  rows,
  setRows,
  expandedRowIndex,
  onExpandRow,
  onEmissionTypeChange,
  buildingId,
  onEmissionBuildingTypeChange,
  onEmissionsUpdate
}) => {
  const { scope1BuildingPreferences } = useBuildingContext();

  const [emissions, setEmissions] = useState<{
    [key: string]: number | null;
  }>({
    naturalGas: null,
    landfillGas: null,
    liquefiedPetroleumGas: null,
    numberTwoOilOrRedDieselGasFuel: null,
    numberSixFuelOil: null,
    keroseneOrBurningOil: null,
    solidFuelWoodOrCoal: null,
    fireSuppressants: null,
    refrigerants: null,
  });

  const handleChange = (index: number) => {
    onExpandRow(expandedRowIndex === index ? null : index);
    const selectedRow = rows[index];
    if (selectedRow.detailsType) {
      onEmissionTypeChange(selectedRow.detailsType);
    }
  };
  const handleInputBlur = async (value: string, emissionType: string) => {
    const measuringValue = parseFloat(value);
    const emissionTypeMapping: { [key: string]: string } = {
      naturalGas: "NATURAL GAS",
      landfillGas: "LANDFILL GAS",
      liquefiedPetroleumGas: "LIQUEFIED PETROLEUM GAS",
      numberTwoOilOrRedDieselGasFuel: "TWO FUEL OIL RED DIESEL GAS FUEL",
      numberSixFuelOil: "SIX FUEL OIL",
      keroseneOrBurningOil: "KEROSENE BURNING OIL",
      solidFuelWoodOrCoal: "SOLID FUEL WOOD COAL",
      fireSuppressants: "FIRE SUPPRESSANTS",
      refrigerants: "REFRIGERANTS",
    };
  
    const mappedEmissionType = emissionTypeMapping[emissionType];
    onEmissionBuildingTypeChange(mappedEmissionType); 

   
  
    if (!isNaN(measuringValue) && value.trim() !== "" && mappedEmissionType) {
      try {
        const requestBody = {
          buildingEmissionType: mappedEmissionType,
          measuringValue: measuringValue,
          buildingId: buildingId,
        };
  
        // Update individual row emissions first
        const response = await calculateScope1BuildingEmission(requestBody);
        const calculatedEmission = response.data?.calculatedEmission;
  
        if (calculatedEmission !== undefined) {

          setEmissions((prevEmissions) => ({
            ...prevEmissions,
            [emissionType]: calculatedEmission,
          }));

          onEmissionsUpdate((prevEmissions: any) => Array.isArray(prevEmissions) ? [...prevEmissions, requestBody] : [requestBody]);
          // Update the individual subcategory row (like natural gas)
          
  
          
  
            

            // console.log("subcategories", subcategories)
            // const totalMeasuringValue = Object.values(subcategories).reduce(
            //   (acc: any, curr: any) => acc + (curr.amount || '0'),
            //   0
            // );
            // const totalEmissions = Object.values(subcategories).reduce(
            //   (acc: any, curr: any) => acc + (curr.emissions || '0'),
            //   0
            // );
  
            // Update the parent "stationaryCombustion" row with the total values
            // return updatedRows.map((row: any) =>
            //   row.detailsType === "stationaryCombustion"
            //     ? {
            //         ...row,
            //         amount: totalMeasuringValue,
            //         emissions: totalEmissions,
            //       }
            //     : row
            // );
        }
      } catch (error: any) {
        console.error("Error calculating emission:", error);
        showToast(error, "error");
      }
    }
  };

  return (
    <>
      <Accordion disableGutters>
        <AccordionSummary
          sx={{
            ...styles.tableTextHead,
            background: "#F3F4F6",
            borderRadius: "10px",
            padding: "8px 16px",
          }}
        >
          <Box
            sx={styles.container}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            width="100%"
          >
            <Box sx={{ flexBasis: "0 1 20%" }}>
              <ExpandMoreIcon />
            </Box>
            {columns.map((col) => (
              <Typography
                key={col.key}
                sx={{
                  ...styles.text,
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#2A2A2A",
                  flexBasis: col.flexBasis,
                  textAlign: "left",
                }}
              >
                {col.label}
              </Typography>
            ))}
          </Box>
        </AccordionSummary>
      </Accordion>

      {rows.map((row, index) => (
        <Accordion
          key={index}
          expanded={expandedRowIndex === index}
          onChange={() => handleChange(index)}
          disableGutters
        >
          <AccordionSummary
            sx={{
              ...styles.tableTextHead,
              background: "white",
              borderRadius: "10px",
              padding: "8px 16px",
            }}
          >
            <Box
              sx={styles.container}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
            >
              <Box sx={{ flexBasis: "5%" }}>
                <ExpandMoreIcon />
              </Box>
              {columns.map((col) => (
                <Typography
                  key={col.key}
                  sx={{
                    ...styles.text,
                    fontSize: 14,
                    flexBasis: col.flexBasis,
                    textAlign: "left",
                  }}
                >
                  {col.key === "amount"
                    ? row.amount 
                    : col.key === "emissions"
                      ? row.emissions
                      : row[col.key as keyof Row]}
                </Typography>
              ))}
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              ...styles.tableBody,
              padding: "16px",
              margin: "0px",
            }}
          >
            {row.detailsType === "stationaryCombustion" && (
              <Box sx={{ padding: "8px 16px" }}>
                {scope1BuildingPreferences?.stationaryCombustionDto
                  ?.scopeOneLiquidFuelOrGas?.naturalGas && (
                  <Box
                    display="flex"
                    alignItems="center"
                    marginBottom="12px"
                    marginRight="150px"
                  >
                    <Box flex="0 1 60%">
                      <Typography sx={{ marginLeft: "100px" }}>
                        Natural Gas
                      </Typography>
                    </Box>
                    <Box flex="0 1 20%">
                      <CustomTextField
                        name="naturalGas"
                        type="number"
                        placeholder="0.1234"
                        onBlur={(e) =>
                          handleInputBlur(e.target.value, "naturalGas")
                        }
                        sx={{ width: "60%" }}
                      />
                    </Box>
                    <Box flex="0 1 20%">
                      <Typography>
                        {emissions.naturalGas !== null
                          ? emissions.naturalGas
                          : "0000"} 
                          <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                          
                      </Typography>
                    </Box>
                  </Box>
                )}

                {/* Landfill Gas */}
                {scope1BuildingPreferences?.stationaryCombustionDto
                  ?.scopeOneLiquidFuelOrGas?.landfillGas && (
                  <Box
                    display="flex"
                    alignItems="center"
                    marginBottom="12px"
                    marginRight="150px"
                  >
                    <Box flex="0 1 60%">
                      <Typography sx={{ marginLeft: "100px" }}>
                        Landfill Gas
                      </Typography>
                    </Box>
                    <Box flex="0 1 20%">
                      <CustomTextField
                        name="landfillGas"
                        type="number"
                        placeholder="0.2345"
                        onBlur={(e) =>
                          handleInputBlur(e.target.value, "landfillGas")
                        }
                        sx={{ width: "60%" }}
                      />
                    </Box>
                    <Box flex="0 1 20%">
                      <Typography>
                        {emissions.landfillGas !== null
                          ? emissions.landfillGas.toFixed(2)
                          : "0000"}
                           <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                      </Typography>
                    </Box>
                   
                  </Box>
                )}

                {/* Liquefied Petroleum Gas */}
                {scope1BuildingPreferences?.stationaryCombustionDto
                  ?.scopeOneLiquidFuelOrGas?.liquefiedPetroleumGas && (
                  <Box
                    display="flex"
                    alignItems="center"
                    marginBottom="12px"
                    marginRight="150px"
                  >
                    <Box flex="0 1 60%">
                      <Typography sx={{ marginLeft: "100px" }}>
                        Liquefied Petroleum Gas
                      </Typography>
                    </Box>
                    <Box flex="0 1 20%">
                      <CustomTextField
                        name="liquefiedPetroleumGas"
                        type="number"
                        placeholder="0.3456"
                        onBlur={(e) =>
                          handleInputBlur(
                            e.target.value,
                            "liquefiedPetroleumGas"
                          )
                        }
                        sx={{ width: "60%" }}
                      />
                    </Box>
                    <Box flex="0 1 20%">
                      <Typography>
                        {emissions.liquefiedPetroleumGas !== null
                          ? emissions.liquefiedPetroleumGas.toFixed(2)
                          : "0000"}
                           <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                      </Typography>
                    </Box>
                   
                  </Box>
                )}

                {/* Number Two Oil or Red Diesel Gas Fuel */}
                {scope1BuildingPreferences?.stationaryCombustionDto
                  ?.scopeOneLiquidFuelOrGas?.numberTwoOilOrRedDieselGasFuel && (
                  <Box
                    display="flex"
                    alignItems="center"
                    marginBottom="12px"
                    marginRight="150px"
                  >
                    <Box flex="0 1 60%">
                      <Typography sx={{ marginLeft: "100px" }}>
                        Number Two Oil/Red Diesel Gas Fuel
                      </Typography>
                    </Box>
                    <Box flex="0 1 20%">
                      <CustomTextField
                        name="numberTwoOilOrRedDieselGasFuel"
                        type="number"
                        placeholder="0.4567"
                        onBlur={(e) =>
                          handleInputBlur(
                            e.target.value,
                            "numberTwoOilOrRedDieselGasFuel"
                          )
                        }
                        sx={{ width: "60%" }}
                      />
                    </Box>
                    <Box flex="0 1 20%">
                      <Typography>
                        {emissions.numberTwoOilOrRedDieselGasFuel !== null
                          ? emissions.numberTwoOilOrRedDieselGasFuel.toFixed(2)
                          : "0000"}
                           <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                      </Typography>
                    </Box>
                    
                  </Box>
                )}

                {/* Number Six Fuel Oil */}
                {scope1BuildingPreferences?.stationaryCombustionDto
                  ?.scopeOneLiquidFuelOrGas?.numberSixFuelOil && (
                  <Box
                    display="flex"
                    alignItems="center"
                    marginBottom="12px"
                    marginRight="150px"
                  >
                    <Box flex="0 1 60%">
                      <Typography sx={{ marginLeft: "100px" }}>
                        Number Six Fuel Oil
                      </Typography>
                    </Box>
                    <Box flex="0 1 20%">
                      <CustomTextField
                        name="numberSixFuelOil"
                        type="number"
                        placeholder="0.5678"
                        onBlur={(e) =>
                          handleInputBlur(e.target.value, "numberSixFuelOil")
                        }
                        sx={{ width: "60%" }}
                      />
                    </Box>
                    <Box flex="0 1 20%">
                      <Typography>
                        {emissions.numberSixFuelOil !== null
                          ? emissions.numberSixFuelOil.toFixed(2)
                          : "0000"}
                           <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                      </Typography>
                    </Box>
                    
                  </Box>
                )}

                {/* Kerosene or Burning Oil */}
                {scope1BuildingPreferences?.stationaryCombustionDto
                  ?.scopeOneLiquidFuelOrGas?.keroseneOrBurningOil && (
                  <Box
                    display="flex"
                    alignItems="center"
                    marginBottom="12px"
                    marginRight="150px"
                  >
                    <Box flex="0 1 60%">
                      <Typography sx={{ marginLeft: "100px" }}>
                        Kerosene/Burning Oil
                      </Typography>
                    </Box>
                    <Box flex="0 1 20%">
                      <CustomTextField
                        name="keroseneOrBurningOil"
                        type="number"
                        placeholder="0.6789"
                        onBlur={(e) =>
                          handleInputBlur(
                            e.target.value,
                            "keroseneOrBurningOil"
                          )
                        }
                        sx={{ width: "60%" }}
                      />
                    </Box>
                    <Box flex="0 1 20%">
                      <Typography>
                        {emissions.keroseneOrBurningOil !== null
                          ? emissions.keroseneOrBurningOil.toFixed(2)
                          : "0000"}
                           <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                      </Typography>
                    </Box>
                   
                  </Box>
                )}
                {scope1BuildingPreferences?.stationaryCombustionDto
                  ?.solidFuelWoodOrCoal && (
                  <Box
                    display="flex"
                    alignItems="center"
                    marginBottom="12px"
                    marginRight="150px"
                  >
                    <Box flex="0 1 60%">
                      <Typography sx={{ marginLeft: "100px" }}>
                        Solid Fuel (Wood or Coal)
                      </Typography>
                    </Box>
                    <Box flex="0 1 20%">
                      <CustomTextField
                        name="solidFuelWoodOrCoal"
                        type="number"
                        placeholder="0.6789"
                        onBlur={(e) =>
                          handleInputBlur(e.target.value, "solidFuelWoodOrCoal")
                        }
                        sx={{ width: "60%" }}
                      />
                    </Box>
                    <Box flex="0 1 20%">
                      <Typography>
                        {emissions.solidFuelWoodOrCoal !== null
                          ? emissions.solidFuelWoodOrCoal.toFixed(2)
                          : "0000"}
                           <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                      </Typography>
                    </Box>
                   
                  </Box>
                )}
              </Box>
            )}
            {row.detailsType === "fireSuppressants" && (
              <Box sx={{ padding: "8px 16px" }}>
                {scope1BuildingPreferences?.fireSuppressants && (
                  <Box
                    display="flex"
                    alignItems="center"
                    marginBottom="12px"
                    marginRight="150px"
                  >
                    <Box flex="0 1 60%">
                      <Typography sx={{ marginLeft: "100px" }}>
                        Fire Suppressants
                      </Typography>
                    </Box>
                    <Box flex="0 1 20%">
                      <CustomTextField
                        name="fireSuppressants"
                        type="number"
                        placeholder="0.1234"
                        onBlur={(e) =>
                          handleInputBlur(e.target.value, "fireSuppressants")
                        }
                        sx={{ width: "60%" }}
                      />
                    </Box>
                    <Box flex="0 1 20%">
                      <Typography>
                        {emissions.fireSuppressants !== null
                          ? emissions.fireSuppressants.toFixed(2)
                          : "0000"}
                           <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                      </Typography>
                    </Box>
                  
                  </Box>
                )}
              </Box>
            )}

            {/* Refrigerants Details */}
            {row.detailsType === "refrigerants" && (
              <Box sx={{ padding: "8px 16px" }}>
                {scope1BuildingPreferences?.refrigerants && (
                  <Box
                    display="flex"
                    alignItems="center"
                    marginBottom="12px"
                    marginRight="150px"
                  >
                    <Box flex="0 1 60%">
                      <Typography sx={{ marginLeft: "100px" }}>
                        Refrigerants
                      </Typography>
                    </Box>
                    <Box flex="0 1 20%">
                      <CustomTextField
                        name="refrigerants"
                        type="number"
                        placeholder="0.1234"
                        onBlur={(e) =>
                          handleInputBlur(e.target.value, "refrigerants")
                        }
                        sx={{ width: "60%" }}
                      />
                    </Box>
                    <Box flex="0 1 20%">
                      <Typography>
                        {emissions.refrigerants !== null
                          ? emissions.refrigerants.toFixed(2)
                          : "0000"}
                           <span style={{ marginLeft: "40px" }}>{"Kwh"}</span>
                      </Typography>
                    </Box>
                   
                  </Box>
                )}
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default Scope1BuildingAccordion;
