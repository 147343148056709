import { axiosInstanceWithAuth } from "../utils/axios";

export interface CalculateBuildingEmissionData{
 buildingId: number,
  buildingEmissionType: string;
  measuringValue: number;
}
export interface GetBuildingEmissionParams{
  buildingEmissionType: string, 
  protocol?: string,
  buildingId: number
}
export interface AddBuildingNoteParams {
  emissionId: number,
  note: string,
}

export interface SaveBuildingEmissionParams {
  buildingId: number;
  data: {
    buildingEmissionType: string;
    measuringValue: number;
    protocol?: string;
  }[];
}

export const getAllBuilding = async () => {
    try {
      const response = await axiosInstanceWithAuth.get(`/asset/building`);
      return response.data;
    } catch (error: any) {
      console.error("Get All Building failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Get All Building failed.";
    }
  }

  export const createOrUpdateBuildingPreferences = async (buildingId: number, preferences: any) => {
    try {
      const response = await axiosInstanceWithAuth.put(`/scope-two/building-preferences/${buildingId}`, preferences);
      console.log("response of create or update building preferences:", response);
      return response.data;
    } catch (error: any) {
      console.error("Error updating building preferences", error.response?.data || error.message);
      throw error.response?.data?.message || "Error updating building preferences.";
    }
  };




  export const getBuildingPreferences = async (id: number) => {
    try {
      const response = await axiosInstanceWithAuth.get(`/scope-two/building-preferences/${id}`); 
      console.log(response, "response of get building preferences");
      return response.data;
    } catch (error: any) {
      console.error("Get Building Preferences By Id failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Get Building Preferences By Id failed.";
    }
  }

  export const calculateBuildingEmission = async(data: CalculateBuildingEmissionData)=>{
   
      try {
        const response = await axiosInstanceWithAuth.post("/scope-two/calculate-building-emission", data);
        return response.data;
      } catch (error) {
        console.error("Error calculating building emission:", error);
        throw error;
      }
  }


  export const getBuildingEmission = async (data: GetBuildingEmissionParams) => {
    try {
      const response = await axiosInstanceWithAuth.get(`/scope-two/building-emission`, {
        params: {
          buildingEmissionType: data.buildingEmissionType,
          protocol: data.protocol,
          buildingId: data.buildingId
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching building emission data:", error);
      throw error;
    }
  };

  export const saveScope2BuildingEmission = async (data: SaveBuildingEmissionParams) => {
    try {
      const response = await axiosInstanceWithAuth.post(`/scope-two/building-emission`,data);
      return response.data;
    } catch (error) {
      console.error("Error fetching building emission data:", error);
      throw error;
    }
  };

  export const getScope2BuildingEmission = async (buildingId: number) => {
    try {
      const response = await axiosInstanceWithAuth.get(`/scope-two/building-emission?buildingId=${buildingId}`);
      console.log(`response of get scope 1 building emission for buildingId=${buildingId}`, response);
      return response.data;
    } catch (error) {
      console.error("Error fetching building emission data:", error);
      throw error;
    }
  }; 

  

  export const addBuildingNote = async (data: AddBuildingNoteParams) => {
    try {
      const response = await axiosInstanceWithAuth.post("/scope-two/building-emission/add-note", data);
      return response.data;
    } catch (error) {
      console.error("Error Add note:", error);
      throw error;
    }
  };

  

  export const deleteScope2BuildingEmission = async (emisssionId: number) => {
    try {
      const response = await axiosInstanceWithAuth.delete(`/scope-two/building-emission?emissionId=${emisssionId}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting building emission data:", error);
      throw error;
    }
  };  