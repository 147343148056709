import { Stack } from "@mui/material";
import React from "react";
import useCustomTabs from "../../../hooks/useCustomTabs";
import { TabsCard } from "../../../ui";
import { Assets } from "./assets";
import { Locations } from "./location";

const ManageComponent: React.FC = () => {
  const tabItems = [
    { label: "Assets", content: <Assets /> },
    { label: "Location", content: <Locations /> },
  ];

  const { TabsHeader, TabContent } = useCustomTabs(tabItems);

  return (
    <React.Fragment>
      <Stack spacing={3}>
        <TabsCard>
          <TabsHeader />
        </TabsCard>
        <TabContent />
      </Stack>
    </React.Fragment>
  );
};

export default ManageComponent;
