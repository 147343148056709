
import { axiosInstanceWithAuth   } from "../utils/axios";

export interface CreateProcessData {
processTypeId: number,
materialId: number,
materialUnitId: number,
emissionFactorId: number,
quantityOfMaterial: number,
processDescription: string,
protocol: string,
dataCollectionMethod: string,
}

export interface EmissionFactorData{
  processId: number,
  materialId?: number,
  unitId?:number,
}

export interface GetProcessEmissionData {
protocol?: string,
page?: number,
size?: number,
sort?: string 
}

export interface GetProcessEmissionByFileIdData{
  fileId?: number,
  page?: number,
size?: number,
sort?: string 
}

export interface GetFuelCombustionDataById {
  fuelCombustionEmissionId: number,
}

export interface UpdateProcessEmissionData {
  processTypeId: number,
  materialId: number,
  materialUnitId: number,
  emissionFactorId: number,
  quantityOfMaterial: number,
  processDescription: string,
  protocol: string,
  dataCollectionMethod: string,
}

export interface CalculateProcessEmissionData{
  measuringValue: number,
  emissionFactorId: number,
  materialUnitId: number,
}

export interface ErrorResponse {
    response?: {
      data?: {
        message?: string;
      };
      status?: number;
      statusText?: string;
    };
    message?: string;
  }

export const createProcessEmission = async (data: CreateProcessData) => {
    try {
      const response = await axiosInstanceWithAuth.post(`/process-emission`, data);
      return response.data;
    } catch (error: any) {
      console.error("Create Process Emission failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Create Process Emission failed.";
    }
  };


  export const getProcessType = async () => {
    try {
      const response = await axiosInstanceWithAuth.get('/process-type');
      return response.data.data;
    } catch (error: any) {
      console.error("Get Process Type failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Get Process Type failed.";
    }
  };

  export const getMaterial = async () => {
    try {
      const response = await axiosInstanceWithAuth.get("/material");
      return response.data.data;
    } catch (error: any) {
      console.error("Get Material data failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Get Material Data failed.";
    }
  };


  export const getProcessEmissionFactor = async (data: EmissionFactorData) => {
    console.log("data in api ", data)
    try {
      const response = await axiosInstanceWithAuth.get(`/process-emission-factor/${data.processId}/${data.materialId}/${data.unitId}`);
      return response.data;
    } catch (error: any) {
      console.error("Get Process Emission Factor failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Get Process Emission Factor failed.";
    }
  };

  export const getProcessEmissionByProtocol = async (data: GetProcessEmissionData) => {
    try {
      const response = await axiosInstanceWithAuth.get(
        `/process-emission`, 
        {
          params: {
            protocol: data.protocol, 
            page: data.page,      
            size: data.size,      
            sort: data.sort,         
          }
        }
      );
      return response.data;
    } catch (error: any) {
      console.error("Get Process Emission By Protocol failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Get Process Emission By Protocol failed.";
    }
  };

  export const getProcessEmissionByFileId = async (data: GetProcessEmissionByFileIdData) => {
    try {
      const response = await axiosInstanceWithAuth.get(
        `/process-emission`, 
        {
          params: {
            fileId: data.fileId, 
            page: data.page,      
            size: data.size,      
            sort: data.sort,         
          }
        }
      );
      return response.data;
    } catch (error: any) {
      console.error("Get Process Emission By File Id failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Get Process Emission By File Id failed.";
    }
  };

  
  export const calculateProcessEmission = async (data: CalculateProcessEmissionData) => {
    try {
      const response = await axiosInstanceWithAuth.post(`/process-emission/calculate-emission`, data);
      console.log("response of calculate Emission Data", response.data)
      return response.data;
    } catch (error: any) {
      console.error("Create Fuel Combustion failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Create Fuel Combustion failed.";
    }
  };

  
  

  export const getProcessEmissionById = async (id: number) => {
    try {
      const response = await axiosInstanceWithAuth.get(`/process-emission/${id}`);
      return response.data;
    } catch (error: any) {
      console.error("Get Process Emission By Id failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Get Process Emission By Id failed.";
    }
  }


  export const updateProcessEmission = async ( id: number, data: UpdateProcessEmissionData) => {
    try {
      const response = await axiosInstanceWithAuth.put(`/process-emission/${id}`, data)
      return response.data;
    } catch (error: any) {
      console.error("Update Process Emission failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Update Process Emission failed.";
    }
  };


  
  export const getProcessEmissionReason = async ( id: number) => {
    try {
      const response = await axiosInstanceWithAuth.get(`/process-emission/${id}/reason`)
      console.log("reason api", response)
      return response.data;
    } catch (error: any) {
      console.error("Reason Process Emission failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Reason Process Emission.";
    }
  };

