import { Box } from "@mui/material";
import { FC, ReactNode } from "react";

interface TabsCardProps {
  children?: ReactNode;
}

const TabsCard: FC<TabsCardProps> = ({ children }) => {
  return <Box sx={styles["card-container"]}>{children}</Box>;
};

export default TabsCard;

const styles = {
  "card-container": {
    minHeight: "64px",
    borderRadius: "12px",
    border: "0px solid #D0D5DD",
    padding: "0px 12px",
    background: "#FFF",
    boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.13)",
  },
};
