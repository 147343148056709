import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Typography } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { EmailIcon, ExclamationIcon } from "../../../icons";
import AuthLayout from "../../../layouts/auth-layout/auth-layout";
import theme from "../../../styles/theme";
import { CustomButton, CustomTextField } from "../../../ui";
import { showToast } from "../../../utils/showToast.util";
import { useForgotPassword } from "./useForgotPassword";

const schema = z.object({
  email: z.string().email("Invalid email address!"),
});

type FormData = z.infer<typeof schema>;

const ForgetPassword: React.FC = () => {
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const {
    formState: { isSubmitting },
  } = methods;

  const { mutate: forgotpassword, isPending } = useForgotPassword({
    onSuccess: (data) => {
      showToast(data.message);
    },
    onError: (error) => {
      console.error("Forgot password failed", error);
    },
  });

  const onSubmit = (data: FormData) => {
    forgotpassword(data);
  };

  return (
    <AuthLayout
      title="Forgot Your Password"
      subTitle="Please Enter your email address to get the Verification code"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CustomTextField
            name="email"
            margin="normal"
            label="Email Address"
            type="email"
            placeholder="Active Email Address"
            icon={<EmailIcon />}
          />

          <CustomButton
            sx={{ my: 3 }}
            rounded="12px"
            text="Reset Password"
            fullWidth
            type="submit"
            loading={isSubmitting || isPending}
          />
          <Box display="flex" alignItems="center" gap={2}>
            <ExclamationIcon />
            <Typography color={theme.palette.grey[100]}>
              Password reset link shall expire after 24 hours
            </Typography>
          </Box>
        </form>
      </FormProvider>
    </AuthLayout>
  );
};

export default ForgetPassword;
