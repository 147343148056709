
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import AuthLayout from "../../../layouts/auth-layout/auth-layout";
import { CustomButton, CustomTextField } from "../../../ui";
import PasswordValidator from "../password-validator";
import { useLocation, useNavigate } from "react-router-dom";
import { useResetPassword } from "./useResetPassword";
import { showToast } from "../../../utils/showToast.util";

const schema = z
  .object({
    newPassword: z
      .string()
      .min(8, "Password must be at least 8 characters")
      .refine(
        (value) => /[a-z]/.test(value) && /[A-Z]/.test(value),
        "Password must contain both upper and lower case letters"
      )
      .refine(
        (value) => /\d/.test(value),
        "Password must contain at least one digit"
      )
      .refine(
        (value) => /[!@#$%^&*(),.?":{}|<>]/.test(value),
        "Password must contain at least one special character"
      ),
    confirmPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords don't match!",
    path: ["confirmPassword"],
  });

type FormData = z.infer<typeof schema>;

const ResetPassword: React.FC = () => {
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      newPassword: "",
    },
  });

  const {
    formState: { isSubmitting },
    watch,
  } = methods;

  const newPassword = watch("newPassword");

  const { mutate: resetPassword, isPending } = useResetPassword();

   const [key, setKey] = useState<string | null>(null);

   const navigate = useNavigate();
   const location = useLocation(); 

   useEffect(() => {
     const searchParams = new URLSearchParams(location.search);
     const keyParam = searchParams.get('key');
     if (keyParam) {
       setKey(keyParam);
     } else {
       console.error("Reset key is missing in the URL");
     }
   }, [location.search]);

  const onSubmit = (data: FormData) => {
    if (!key) {
      console.error("Reset key is missing");
      return;
    }
    resetPassword(
      { key: key, newPassword: data.newPassword }, 
      {
        onSuccess: () => {
          showToast("Your password has been successfully reset.");
          setTimeout(() => navigate("/login"), 3000);
        },
        onError: (error: any) => {
          showToast(error.message || "Failed to reset password.");
        },
      }
    );
  };

  return (
    <AuthLayout
      title="Reset your password"
      subTitle="Please create your new password"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CustomTextField
            name="newPassword"
            margin="normal"
            label="New Password"
            type="password"
            variant="outlined"
            placeholder="Create New Password"
          />
          <CustomTextField
            name="confirmPassword"
            margin="normal"
            label="Re-type Password"
            type="password"
            variant="outlined"
            placeholder="Retype Password"
          />
          {newPassword?.length > 0 && (
            <PasswordValidator password={newPassword} />
          )}

          <CustomButton
            sx={{ mt: 3 }}
            rounded="12px"
            text="Create New Password"
            fullWidth
            loading={isSubmitting || isPending}
            type='submit'
          />
        </form>
      </FormProvider>
    </AuthLayout>
  );
};

export default ResetPassword;
