import { Grid } from "@mui/material";
import React from "react";
import { ChevronDown, EmailIcon, UserIcon } from "../../../icons";
import { CustomTextField } from "../../../ui";
import CustomPhoneInput from "../../../ui/custom-input-phone/custom-input-phone";

interface ProfileInfoFormProps {
  isViewProfile?: boolean;
  isNewProfileSetup?: boolean;
  countriesData: any;
  cityList: any;
 
}

const ProfileInfoForm: React.FC<ProfileInfoFormProps> = ({
  isViewProfile,
  isNewProfileSetup,
  countriesData,
  cityList,
 
}) => {
  const fields = [
    {
      name: "firstName",
      label: "First Name",
      placeholder: "Enter First Name",
      icon: <UserIcon />,
    },
    {
      name: "lastName",
      label: "Last Name",
      placeholder: "Enter Last Name",
      icon: <UserIcon />,
    },
    {
      name: "email",
      label: "Email Address",
      placeholder: "example@example.com",
      type: "email",
      icon: <EmailIcon />,
      disabled: true || isViewProfile || isNewProfileSetup,
    },
    {
      name: "country",
      label: "Country",
      placeholder: "Select Your Country",
      icon: <ChevronDown />,
      select: true,
      options: countriesData?.map((country: any) => ({
        value: country.countryId.toString(),
        label: country.countryName,
      })),
    
    },
    {
      name: "city",
      label: "City",
      placeholder: "Select Your City",
      icon: <ChevronDown />,
      select: true,
      options: cityList?.map((city: any) => ({
        value: city.cityId.toString(),
        label: city.cityName,
      })),
    },
  ];

  return (
    <Grid container spacing={3} mt={3}>
      {fields.map((field, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <CustomTextField
            {...field}
            disabled={isViewProfile || field.disabled}
          />
        </Grid>
      ))}
      <Grid item xs={12} sm={6}>
        <CustomPhoneInput
          name="phoneNumber"
          label="Phone Number"
          placeholder="Enter your phone number"
          phoneCountryCode="US"
          required
          disabled={isViewProfile}
        />
      </Grid>
    </Grid>
  );
};

export default ProfileInfoForm;
