import React, { ComponentType } from "react";
import { Navigate } from "react-router-dom";
import { useUserContext } from "../context/user-context";

// Define the props for PrivateRoute component
interface PrivateRouteProps<T extends object = {}> {
  component: ComponentType<T>;
  componentProps?: T;
}

function PrivateRoute<T extends object = {}>({
  component: Component,
  componentProps,
}: PrivateRouteProps<T>) {
  const { user } = useUserContext();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return <Component {...(componentProps as T & JSX.IntrinsicAttributes)} />;
}

export default PrivateRoute;
