import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomButton, CustomHeading, CustomSubHeading } from "../../../../ui";
import { Scope1EmissionTabs } from "./scope1-emission-tabs";
import { Scope1BuildingEmissionTabs } from "./building/scope1-building-emission-tabs";
import { Scope1LocationEmissionTabs } from "./location/scope1-location-emission-tabs";
import Scope1ConfigurationLocationTabs from "./location/scope1-configuration-location-tabs";
import Scope1ConfigurationBuildingTabs from "./building/scope1-configuration-building-tabs";
import { useNavigate, useSearchParams } from "react-router-dom";

const emissionsData = [
  {
    title: "Building & Location",
    description: "Calculate your building & location Emissions",
  },
  {
    id: 0,
    title: "Fuel Combustion",
    description: "Calculate your Fuel Combustion Emissions",
  },
  {
    id: 1,
    title: "Process Emissions",
    description: "Calculate your Process Emissions",
  },
  {
    id: 2,
    title: "Fugitive emissions",
    description: "Calculate your Fugitive Emissions",
  },
  {
    id: 3,
    title: "Mobile Source Emission",
    description: "Calculate your Mobile Source Emissions",
  },
];

const Scope1Emission: React.FC = () => {
  const [activeListTab, setIsActiveListTab] = useState<{
    show: boolean;
    active: number | undefined;
  }>({ show: false, active: undefined });
  const [isTabsView, setIsTabsView] = useState<{
    show: boolean;
    active: number | undefined;
  }>({ show: false, active: undefined });
  const [isConfigurationTabsView, setIsConfigurationTabsView] = useState<{
    show: boolean;
    active: number | undefined;
  }>({ show: false, active: undefined });
  const [selectedTab, setSelectedTab] = useState("building");

  useEffect(() => {
    if (isConfigurationTabsView.show) {
      setIsTabsView({ show: false, active: undefined });
    }
  }, [isConfigurationTabsView]);

  return (
    <>
      {!isTabsView.show && !isConfigurationTabsView.show && (
        <Box
          sx={{
            background: "#fff",
            borderRadius: "24px",
            p: { sm: "70px 23px", xs: "30px 16px" },
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <CustomHeading text="Scope 1 Emissions Tracking" fontSize="30px" />
            <CustomSubHeading
              text="Scope 1 emissions tracking monitors direct greenhouse gases to ensure"
              fontSize="14px"
              sx={{ pt: "12px" }}
            />
            <CustomSubHeading
              text="compliance and boost sustainability."
              fontSize="14px"
            />
          </Box>
          <Grid container spacing={"24px"} sx={{ pt: "40px" }}>
            {emissionsData.map(({ id, title, description }, index) => (
              <Grid item xs={12} sm={6} lg={4} xl={3} key={index}>
                <Box
                  sx={{
                    border: "1px solid #D0D5DD",
                    p: "16px",
                    borderRadius: "16px",
                    height: "15rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <CustomHeading text={title} fontSize="18px" />
                    <CustomSubHeading
                      text={description}
                      fontSize="14px"
                      sx={{ pt: "8px" }}
                    />
                  </Box>
                  <Box sx={{ pt: "70px" }}>
                    <CustomButton
                      text="Track Emissions"
                      onClick={() => {
                        setIsTabsView({ show: true, active: id });
                        setIsActiveListTab({ show: true, active: id });
                      }}
                      sx={{ fontWeight: 500 }}
                    />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {isTabsView.show &&
        !isConfigurationTabsView.show &&
        (isTabsView.active !== undefined ? (
          <Scope1EmissionTabs activeTab={isTabsView.active} />
        ) : selectedTab === "building" ? (
          <Scope1BuildingEmissionTabs
            setIsConfigurationTabsView={setIsConfigurationTabsView}
            activeTab={isTabsView.active}
            activeListTab={activeListTab.active}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
          />
        ) : (
          <Scope1LocationEmissionTabs
            setIsConfigurationTabsView={setIsConfigurationTabsView}
            activeTab={isTabsView.active}
            activeListTab={activeListTab.active}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
          />
        ))}

      {!isTabsView.show &&
        isConfigurationTabsView.show &&
        (selectedTab === "building" ? (
          <Scope1ConfigurationBuildingTabs
            activeTab={isTabsView.active}
            activeListTab={activeListTab.active}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
            setIsTabsView={setIsTabsView}
            setIsConfigurationTabsView={setIsConfigurationTabsView}
          />
        ) : (
          <Scope1ConfigurationLocationTabs
            activeTab={isTabsView.active}
            activeListTab={activeListTab.active}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
            setIsTabsView={setIsTabsView}
            setIsConfigurationTabsView={setIsConfigurationTabsView}
          />
        ))}
    </>
  );
};

export default Scope1Emission;
