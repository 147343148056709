
import { axiosInstanceWithAuth } from "../utils/axios";

  export interface createEmissionData {
  sourceName: string;
  description: string;
  controlStatus: string;  
  categoryId: number;          
  subCategoryId: number;       
  assetId: number;             
  scope: string;
  }


  export interface GetEmissionSourceData {
    search?: string;
    controlStatuses?: string[];
    categoryIds?: number[];
    assetIds?: number[];
    statuses?: string[];
    page?: number;
    size?: number;
    sort?: string;
  }


  
export interface EmissionSourceStatusData {
  emissionSourceId: number;
  status: string;
}




export const emissionCategory = async () => {
    try {
      const response = await axiosInstanceWithAuth.get("/category"); 
      return response.data
    } catch (error:any) {
      console.error("Category failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Category failed.";
    }
  }; 


  export const emissionSubCategory = async (id: number) => {
    try {
      const response = await axiosInstanceWithAuth.get(`/category/${id}`);
      return response.data;
    } catch (error: any) {
      console.error("Sub Category failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Sub Category failed.";
    }
  };
  
  export const emissionAssetTypes = async () => {
    try {
      const response = await axiosInstanceWithAuth.get("/asset-type"); 
      return response.data
    } catch (error:any) {
      console.error("Asset Types  failed", error.response?.data || error.message);
      throw error.response?.data?.message || "Asset Types  failed.";
    }
  }; 

 
export const createEmission = async (data: createEmissionData) => {
  try {
    const response = await axiosInstanceWithAuth.post("/emission-source", data);
    return response.data
  } catch (error:any) {
    console.error("Create Emission failed", error.response?.data || error.message);
    throw error.response?.data?.message || "Create Emission failed.";
  }
}; 


export const getEmissionSource = async (data: GetEmissionSourceData) => {
  try {
    let queryParams = `search=${encodeURIComponent(data.search || '')}`;

    if (data.controlStatuses && data.controlStatuses.length > 0) {
      queryParams += `&controlStatuses=${data.controlStatuses.join(',')}`;
    }

    if (data.categoryIds && data.categoryIds.length > 0) {
      queryParams += `&categoryIds=${data.categoryIds.join(',')}`;
    }

    if (data.assetIds && data.assetIds.length > 0) {
      queryParams += `&assetIds=${data.assetIds.join(',')}`;
    }

    if (data.statuses && data.statuses.length > 0) {
      queryParams += `&statuses=${data.statuses.join(',')}`;
    }

    queryParams += `&page=${data.page?.toString()}`;
    queryParams += `&size=${data.size?.toString()}`;
    queryParams += `&sort=${data.sort}`;
    const url = `/emission-source?${queryParams.replace(/%20/g, ' ')}`;


    const response = await axiosInstanceWithAuth.get(url);
    return response.data;
  } catch (error: any) {
    console.error("Get Emission Source failed", error.response?.data || error.message);
    throw error.response?.data?.message || "Get Emission Source failed.";
  }
};


export const emissionSourceStatus = async (payload: EmissionSourceStatusData) => {
  try {
    const response = await axiosInstanceWithAuth.post("/emission-source/update-status", payload);
    return response.data;
  } catch (error: any) {
    console.error("Update Emission Source Status failed", error.response?.data || error.message);
    throw error.response?.data?.message || "Update Emission Source Status failed.";
  }
};


export const deleteEmissionSource = async (emissionSourceId: number) => {
  try {
    const response = await axiosInstanceWithAuth.delete(`/emission-source/${emissionSourceId}`);
    return response.data;
  } catch (error: any) {
    console.error("Delete Emission Source failed", error.response?.data || error.message);
    throw error.response?.data?.message || "Delete Emission Source failed.";
  }
};

  