import { Opacity } from "@mui/icons-material";
import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    laptop: true;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      laptop: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      50: "#fff8e1",
      100: "#ffecb3",
      200: "#ffe082",
      300: "#ffd54f",
      400: "#ffca28",
      500: "#ffc107",
      600: "#E8CA51",
      700: "#ffa000",
      800: "#ff8f00",
      900: "#ff6f00",
      main: "#FFDE59", // The main color for primary
      contrastText: "#000000", // Text color on primary background
    },
    secondary: {
      50: "#e6eeea",
      100: "#c0cbc8",
      200: "#a0b6ac",
      300: "#82a698",
      400: "#648276",
      500: "#4b6354",
      600: "#34523e",
      700: "#20332d",
      800: "#10221f",
      900: "#0a1b13",
      main: "#00532C", // The main color for secondary
      contrastText: "#ffffff", // Text color on secondary background
    },
    success: {
      50: "#e7f8f1",
      100: "#c1f1da",
      200: "#9aeabf",
      300: "#72e4a5",
      400: "#4bdd8b",
      500: "#24d772",
      600: "#1bb75b",
      700: "#149545",
      800: "#0e7431",
      900: "#07531d",
      main: "#24d772", // The main color for success
      contrastText: "#ffffff", // Text color on success background
    },
    warning: {
      50: "#fff6e5",
      100: "#fee1b1",
      200: "#fec78b",
      300: "#fea854",
      400: "#fe8a25",
      500: "#ff7500",
      600: "#e66300",
      700: "#c85200",
      800: "#aa4100",
      900: "#8b3200",
      main: "#ff7500", // The main color for warning
      contrastText: "#ffffff", // Text color on warning background
    },
    error: {
      50: "#ffeceb",
      100: "#ffc4c1",
      200: "#ff8983",
      300: "#ff544b",
      400: "#ff2e22",
      500: "#ff0000",
      600: "#db0000",
      700: "#b70000",
      800: "#930000",
      900: "#700000",
      main: "#B3261E", // The main color for error
      contrastText: "#ffffff", // Text color on error background
    },
    grey: {
      50: "#79747E",
      100: "#575B76",
      200: "#8E98A8",
      300: "#b1b1b1",
      400: "#9e9e9e",
      500: "#7e7e7e",
      600: "#626262",
      700: "#515151",
      800: "#3b3b3b",
      900: "#222222",
    },
    background: {
      default: "#ffffff",
    },
    text: {
      primary: "#212121",
      secondary: "#757575",
    },
  },
  typography: {
    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    subtitle1: {
      fontSize: "1.125rem",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
            "&:hover .MuiOutlinedInput-notchedOutline": {
              // your hover styles here
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              // your focused styles here
            },
          },
          "& .MuiInputBase-input": {
            padding: "14.5px 14px",
            "&::placeholder": {
              color: "#8E98A",
              fontSize: "0.875rem",
            },
          },
          "& .MuiInputLabel-shrink": {
            color: "#475467",
            fontSize: "0.875rem",
            fontFamily: "PoppinsRegular",
          },

          "& .MuiSelect-icon": {
            display: "none",
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          padding: "14.5px 14px",
          fontSize: "0.875rem",
          span:{color:"#8E98A !important"},
        },
        
      },
    },
    

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

export default theme;
