import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  CheckboxProps,
  styled,
} from "@mui/material";
import {
  useFormContext,
  Controller,
  FieldValues,
  FieldPath,
  ControllerRenderProps,
  ControllerFieldState,
} from "react-hook-form";

type CustomCheckboxProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  FormControlLabelProps,
  "control" | "name"
> & {
  name: FieldPath<TFieldValues>;
  checkboxProps?: CheckboxProps;
  labelFontSize?: string;
  labelColor?: string;
};

const CustomCheckbox = <TFieldValues extends FieldValues = FieldValues>({
  name,
  label,
  checkboxProps,
  labelFontSize = "14px",
  labelColor = "#8E98A8",
  ...rest
}: CustomCheckboxProps<TFieldValues>): JSX.Element => {
  const { control } = useFormContext<TFieldValues>();

  return (
    <Controller<TFieldValues>
      name={name}
      control={control}
      render={({
        field,
        fieldState: { error },
      }: {
        field: ControllerRenderProps<TFieldValues, FieldPath<TFieldValues>>;
        fieldState: ControllerFieldState;
      }) => (
        <StyledFormControlLabel
          control={
            <StyledCheckbox
              {...field}
              checked={field.value}
              {...checkboxProps}
            />
          }
          label={label}
          $labelFontSize={labelFontSize}
          $labelColor={labelColor}
          {...rest}
        />
      )}
    />
  );
};

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({}));

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) =>
    prop !== "$labelFontSize" && prop !== "$labelColor",
})<{
  $labelFontSize?: string;
  $labelColor?: string;
}>(({ theme, $labelFontSize, $labelColor }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: $labelFontSize || theme.typography.body1.fontSize,
    color: $labelColor || theme.palette.text.primary,
  },
  marginRight: 0,
}));

export default CustomCheckbox;
