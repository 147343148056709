import * as React from "react";

function UploadCSVIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="38" height="31" viewBox="0 0 38 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.871094" width="36.2578" height="31" rx="5" fill="#FFFCEE" fill-opacity="0.5" />
            <path d="M20.9776 22.1065C20.9522 22.086 20.9307 22.0626 20.9131 22.0372C20.8623 21.9708 20.833 21.8868 20.833 21.797V19.6114L19.8506 20.5948C19.7784 20.6681 19.6827 20.7071 19.5869 20.712C19.4776 20.7169 19.3682 20.6778 19.2842 20.5948L19.1436 20.4532C18.9873 20.297 18.9873 20.044 19.1436 19.8878L20.9073 18.1222L21.0479 17.9806L21.0635 17.9659C21.2198 17.8243 21.4619 17.8292 21.6143 17.9806L21.7569 18.1212L21.7803 18.1456L23.5205 19.8868C23.5498 19.9161 23.5733 19.9483 23.5909 19.9835C23.6299 20.0587 23.6436 20.1437 23.6338 20.2267C23.6221 20.3097 23.585 20.3897 23.5205 20.4532L23.3799 20.5938C23.3291 20.6456 23.2647 20.6808 23.1983 20.6983C23.1709 20.7052 23.1436 20.7101 23.1162 20.711C23.0616 20.714 23.003 20.7042 22.9502 20.6827C22.9014 20.6632 22.8545 20.6339 22.8135 20.5938L21.833 19.6134V21.797C21.833 22.0187 21.6534 22.1974 21.4327 22.1974H21.2334C21.1709 22.1974 21.1123 22.1837 21.0596 22.1583C21.0303 22.1446 21.003 22.1271 20.9776 22.1065Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.66211 11.5254C10.5703 7.8291 13.6367 5 17.416 5C20.7344 5 23.5117 7.18945 24.748 10.2227C24.8379 10.2148 24.9238 10.2129 25.0098 10.2129C27.3027 10.2129 29.0723 12.0156 29.4492 14.2393C30.8887 14.4209 32.1289 15.748 32.1289 17.1689C32.1289 18.6982 30.9922 20.126 29.3711 20.126L28.5273 20.1318L28.4238 20.126H27.3313C27.2649 23.3809 24.6047 26 21.3332 26C18.0637 26 15.4075 23.3857 15.3352 20.1338L10.0039 20.1318L9.82422 20.1357H9.21485C9.14063 20.1357 9.06641 20.1279 8.99414 20.1113C7.84961 19.8525 6.95118 19.0352 6.41797 18.0049C6.06445 17.3242 5.87109 16.5508 5.87109 15.7842C5.87109 13.5801 7.46485 11.6055 9.66211 11.5254ZM28.5137 18.126H27.0344C26.6164 16.8525 25.7825 15.7666 24.6946 15.0293C23.7356 14.3799 22.5793 14 21.3332 14C18.6711 14 16.4133 15.7344 15.6282 18.1348L9.97461 18.1318L9.91602 18.1338L9.82422 18.1357H9.3418C8.59961 17.9082 7.8711 16.9648 7.8711 15.7842C7.8711 14.4316 8.83399 13.5225 9.80079 13.5225C9.94532 13.5225 10.084 13.541 10.2187 13.5762C10.4941 13.6475 10.7871 13.5986 11.0254 13.4404C11.2617 13.2822 11.4199 13.0293 11.4609 12.7471C11.9297 9.40723 14.4902 7 17.416 7C20.0684 7 22.4141 8.9707 23.1855 11.835C23.2637 12.126 23.4687 12.3662 23.7461 12.4893C23.9238 12.5684 24.1191 12.5928 24.3086 12.5625C24.4121 12.5459 24.5117 12.5137 24.6074 12.4648C24.7734 12.3789 24.8965 12.2979 24.9941 12.2256L25.0117 12.2129C26.3008 12.2139 27.5234 13.4141 27.5234 15.126L27.5215 15.2861C27.5039 15.626 27.6621 15.9502 27.9394 16.1475C28.2168 16.3447 28.832 16.3818 29.1484 16.2559C29.2187 16.2275 29.293 16.2119 29.3711 16.2119C29.6914 16.2119 30.1289 16.5371 30.1289 17.1689C30.1289 17.8008 29.6914 18.126 29.3711 18.126L28.6172 18.1318L28.5137 18.126ZM21.3332 16C19.1243 16 17.3332 17.791 17.3332 20C17.3332 22.209 19.1243 24 21.3332 24C23.5422 24 25.3332 22.209 25.3332 20C25.3332 17.791 23.5422 16 21.3332 16Z" fill="black" />
        </svg>
    );
}

export default UploadCSVIcon;