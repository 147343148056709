export const styles = {
    tableHeader: {
      background: "#F3F4F6",
    },
    tableTextHead: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      color: "#2A2A2A",
      textAlign: "center",
      flex: "0 1 15%",
    },
    tableBody: {
      color: "#6E7793",
      fontFamily: "Poppins",
      fontSize: "0.875rem",
      fontStyle: "normal",
    },

    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      padding: "8px 10px",
      gap: 6,
      borderRadius: "1px",
    },
    text: {
      fontWeight: 400,
      fontFamily: "Poppins",
      textAlign: "center",
    },
    evenRow: {
      background: "#FFFFFF",
    },
    oddRow: {
      background: "#FCFCFD",
    },
    
  };