import * as React from "react";

function EllipseSmallIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12.5" fill="#0A5D36" />
    </svg>
  );
}

export default EllipseSmallIcon;
