import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";
import { AppleIcon, EmailIcon, GoogleIcon, WindowsIcon } from "../../../icons";
import AuthLayout from "../../../layouts/auth-layout/auth-layout";
import theme from "../../../styles/theme";
import { CustomButton, CustomText, CustomTextField } from "../../../ui";
import { useLogin } from "./useLogin";
import { showToast } from "../../../utils/showToast.util";
import useStorage from "../../../utils/useStorage.util";
import { useUserContext } from "../../../context/user-context";

const schema = z.object({
  email: z
    .string()
    .min(1, "Email is required!")
    .email("Please enter a valid email address!"),
  password: z.string(),
});

type FormData = z.infer<typeof schema>;

const Login: React.FC = () => {
  const { updateUser } = useUserContext();
  const navigate = useNavigate();

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const {
    formState: { isSubmitting, isValid },
  } = methods;

  const { mutate: login, isPending } = useLogin({
    onSuccess: async (data: any) => handleLoginSuccess(data),
    onError: (error: any) => {
      showToast(error, "error");
    },
  });

  const handleLoginSuccess = async (data: any) => {
    const { token, emailVerified, profileSetup, key } = data.data;;

    if (emailVerified) {
      const redirectPath = profileSetup
        ? "/dashboard"
        : `/create-profile?key=${key}`;
      navigate(redirectPath);
      showToast(data.message);
      const isNewProfileSetup = !profileSetup;
      localStorage.setItem(
        "isNewProfileSetup",
        JSON.stringify(isNewProfileSetup)
      );
    }
    if (profileSetup) {
      useStorage.setItem("planetPulseToken", token);
      updateUser(data.data);
    }
  };

  const onSubmit = (data: FormData) => {
    login(data);
  };
  return (
    <AuthLayout title="Sign In" subTitle="Sign in to your account!">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CustomTextField
            name="email"
            margin="normal"
            label="Email Address"
            type="email"
            placeholder="Enter Email Address"
            icon={<EmailIcon />}
          />

          <CustomTextField
            name="password"
            margin="normal"
            label="Password"
            type="password"
            variant="outlined"
            placeholder="Enter Password"
          />
          <Box display="flex" justifyContent="end">
            <Link to="/forgot-password">
              <Typography color={theme.palette.grey[100]}>
                Forget Password?
              </Typography>
            </Link>
          </Box>

          <CustomButton
            sx={{ mt: 3 }}
            rounded="12px"
            text="Login"
            fullWidth
            type="submit"
            loading={isSubmitting || isPending}
            disabled={isSubmitting || isPending || !isValid}
          />
          <Box
            sx={{ display: "flex", alignItems: "center", width: "100%", my: 4 }}
          >
            <Divider sx={{ flexGrow: 1, borderColor: "#E7E7E7" }} />
            <Typography sx={{ mx: 2, color: "grey.200" }}>
              Or Continue With?
            </Typography>
            <Divider sx={{ flexGrow: 1, borderColor: "#E7E7E7" }} />
          </Box>
          <Stack spacing={3}>
            <CustomButton
              text="Sign in with Google"
              bgColor="trasnparent"
              icon={GoogleIcon}
            />
            <CustomButton
              text="Sign in with Apple"
              bgColor="trasnparent"
              icon={AppleIcon}
            />
            <CustomButton
              text="Sign in with Microsoft"
              bgColor="trasnparent"
              icon={WindowsIcon}
            />
          </Stack>
          <CustomText
            text="Don't have an account?"
            fontSize="16px"
            linkText="Signup"
            linkHref="/signup"
            mt={4}
            sx={{ textAlign: "center" }}
          />
        </form>
      </FormProvider>
    </AuthLayout>
  );
};

export default Login;
