import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  InputAdornment,
  MenuItem,
  TextField,
  TextFieldProps,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { ChevronDown, EyeIcon, EyeOffIcon } from "../../icons";
import theme from "../../styles/theme";

type IconType = "search" | "clear" | "password" | React.ReactNode;

type CustomTextFieldProps<TFieldValues extends FieldValues = FieldValues> =
  Omit<TextFieldProps, "name"> & {
    name: FieldPath<TFieldValues>;
    icon?: IconType;
    onIconClick?: () => void;
    select?: boolean;
    options?: { value: string; label: string }[];
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void; 
  };

const CustomTextField = <TFieldValues extends FieldValues = FieldValues>({
  name,
  type,
  icon,
  onIconClick,
  select,
  options = [],
  value, 
  onChange,
  onBlur, 
  ...rest
}: CustomTextFieldProps<TFieldValues>): JSX.Element => {
  const { control } = useFormContext<TFieldValues>();
  const [showPassword, setShowPassword] = useState(false);

  const handleIconClick = () => {
    if (type === "password") {
      setShowPassword((show) => !show);
    }
    onIconClick?.();
  };

  const renderIcon = () => {
    if (select) {
      return <ChevronDown />;
    }
    if (type === "password") {
      return showPassword ? <EyeOffIcon /> : <EyeIcon />;
    }

    switch (icon) {
      case "search":
        return <SearchIcon />;
      case "clear":
        return <ClearIcon />;
      default:
        return icon as React.ReactNode;
    }
  };

  const endAdornment =
    icon || type === "password" || type === "search" ? (
      <InputAdornment position="end">
        {type === "password" || type === "search" ? (
          <Box
            sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
            onClick={handleIconClick}
          >
            {renderIcon()}
          </Box>
        ) : (
          <>{renderIcon()}</>
        )}
      </InputAdornment>
    ) : null;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange: fieldOnChange, value: fieldValue, onBlur: fieldOnBlur, ...fieldRest },
        fieldState: { error },
      }) => (
        <StyledTextField
          {...rest}
          {...fieldRest}
          value={value ?? fieldValue} // Ensure value is passed
          onChange={(e) => {
            fieldOnChange(e); // react-hook-form handler
            onChange?.(e); // Your custom handler
          }}
          onBlur={(e) => {
            fieldOnBlur(); // react-hook-form blur handler
            onBlur?.(e); // Your custom blur handler
          }}
          select={select}
          autoComplete="new-password"
          type={type === "password" && showPassword ? "text" : type}
          error={!!error}
          multiline={type === "textarea"}
          helperText={error?.message}
          fullWidth
          variant="outlined"
          SelectProps={{
            displayEmpty: true,
            renderValue: (value: unknown) => {
              const selectedValue = value as string | undefined | number;
              if (!selectedValue) {
                return (
                  <span style={{ color: theme.palette.grey[200] }}>
                    {rest.placeholder || ""}
                  </span>
                );
              }
              const selectedOption = options.find(
                (option) => option.value === selectedValue
              );
              return selectedOption ? selectedOption.label : selectedValue;
            },
          }}
          InputLabelProps={{
            ...rest.InputLabelProps,
            shrink: true,
          }}
          InputProps={{
            ...rest.InputProps,
            endAdornment: endAdornment,
          }}
        >
          {select &&
            options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </StyledTextField>
      )}
    />
  );
};

export default CustomTextField;

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {},
    "&:hover .MuiOutlinedInput-notchedOutline": {},
    "&.Mui-error .MuiOutlinedInput-notchedOutline": {},
  },

  "& .MuiInputBase-input": {
    padding: "14.5px 14px",
    fontFamily: "PoppinsRegular",
    "&::placeholder": {
      color: "#8E98A",
      fontSize: "0.875rem",
    },
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {},
  },
  "& .MuiInputLabel-shrink": {
    color: "#475467",
    fontSize: "0.875rem",
    fontFamily: "PoppinsRegular",
  },
  "& .Mui-disabled": {},
  "& .MuiSelect-select": {},
  "& .MuiFormHelperText-root": {},
  "& input:-webkit-autofill": {},
  "& .MuiSelect-icon": {
    display: "none",
  },
}));
