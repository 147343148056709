import { Box } from "@mui/material";
import React from "react";

interface CustomContainerProps {
  paddingX?: string;
  paddingY?: string;
  children: React.ReactNode;
}

/**
 * CustomContainer component.
 * Renders a customizable container component.
 *
 * @param {CustomContainerProps} props - Props for the CustomContainer component.
 * @param {string} props.padding - Padding for the container.
 * @returns {JSX.Element} JSX code for rendering the CustomContainer component.
 */

const CustomContainer: React.FC<CustomContainerProps> = ({
  paddingX = "24px",
  paddingY = "24px",
  children,
  ...rest
}: CustomContainerProps): JSX.Element => {
  return (
    <Box paddingX={paddingX} paddingY={paddingY} {...rest}>
      {children}
    </Box>
  );
};

export default CustomContainer;

// usage example

// import CustomContainer from "path-to-custom-container"

/* 
<CustomContainer
padding="30px"
/>;
 */
