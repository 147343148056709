import { Tooltip, tooltipClasses, styled, TooltipProps } from "@mui/material";

// Custom styled tooltip
const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF", 
    color: "#6E7793",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)", 
    borderRadius: "8px", 
    padding: theme.spacing(1.5), 
    fontSize: "14px", 
    maxWidth: "300px", 
    lineHeight: "1.5", 
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFFFFF", 
  },
}));

// Function to truncate text
const truncateText = (text: string, maxLength: number) => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

interface TruncatedTextWithTooltipProps {
  text: string;
  maxLength: number;
}

const TruncatedTextWithTooltip: React.FC<TruncatedTextWithTooltipProps> = ({ text, maxLength }) => {
  const isTextLong = text.length > maxLength;

  return isTextLong ? (
    <CustomTooltip title={text}>
      <span>{truncateText(text, maxLength)}</span>
    </CustomTooltip>
  ) : (
    <span>{text}</span>
  );
};

export default TruncatedTextWithTooltip;
