import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Menu,
  MenuItem,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { CustomHeading } from "../../../../../ui";
import { styles } from "../../../../../ui/custom-accordion-table/custom-accordion-table.style";
import { getBuildingEmission } from "../../../../../api/scope2-building-api";
import { VerticalDotsIcon } from "../../../../../icons";
import { showToast } from "../../../../../utils/showToast.util";
import { getScope2LocationEmission } from "../../../../../api/scope2-location-api";
import { formatDateToDDMMYYYY } from "../../../../../utils/getStatusStyle";

interface Column {
  key: string;
  label: string;
}

interface Row {
  facilityName: string;
  amount: string;
  emissions: string;
  calculatedEmissionUnit: string;
  createdDate: string;
  actions: string;
  detailsType?: string;
}

interface RowAction {
  text: string;
  onClick: (row: Row) => void;
}

interface EmissionTableProps {
  columns: Column[];
  rows: Row[];
  emissionType: any;
  tableType: string;
  rowActions?: RowAction[];
  onScroll?: any;
}

const EmissionsAddedTable: React.FC<EmissionTableProps> = ({
  rows,
  columns,
  emissionType,
  tableType,
  rowActions,
  onScroll,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<Row | null>(null);

  useEffect(() => {
    const fetchEmissionData = async () => {
      if (!emissionType) return;
      try {
        const result =
          tableType === "Building"
            ? await getBuildingEmission(emissionType)
            : await getScope2LocationEmission(emissionType);
        return result.data || [];
      } catch (error: any) {
        console.error("Failed to fetch emission data:", error);
        showToast(error, "error");
      }
    };
    fetchEmissionData();
  }, [emissionType, tableType]);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    row: Row
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleActionClick = (action: RowAction) => {
    if (selectedRow) {
      action.onClick(selectedRow);
      handleMenuClose();
    }
  };

  return (
    <Box sx={{ padding: "12px" }}>
      <Box
        mt={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CustomHeading text="Emission Added" variant="h5" />
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            label="Start Date"
            type="date"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="End Date"
            type="date"
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Box>

      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", border: "1px solid #EAECF0", borderRadius: "8px", mt: 3, maxHeight: "400px", overflowY: "auto", }}
      >
        <Table>
          <TableHead>
            <TableRow sx={styles.tableHeader}>
              {columns.map((col) => (
                <TableCell key={col.key} sx={styles.tableTextHead}>
                  {col.label}
                </TableCell>
              ))}
              <TableCell sx={styles.tableTextHead}>Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  ...styles.tableBody,
                  ...(index % 2 === 0 ? styles.evenRow : styles.oddRow),
                }}
              >
                {columns.map((col) => (
                  <TableCell key={col.key} sx={styles.tableBody}>
                    {col.key === "createdDate" ? (
                      formatDateToDDMMYYYY(row.createdDate)
                    ) : col.key === "calculatedEmission" ? (
                      <Box sx={{ display: "flex", gap: 1 }}>
                        {row[col.key as keyof Row]}
                        {row.calculatedEmissionUnit}
                      </Box>
                    ) : (
                      row[col.key as keyof Row]
                    )}
                  </TableCell>
                ))}
                
                {/* {rowActions && rowActions?.length > 0 && ( */}
                      <TableCell>
                        <IconButton
                          aria-label="more"
                          onClick={(event) => handleMenuOpen(event, row)}
                        >
                          <VerticalDotsIcon />
                        </IconButton>
                      </TableCell>
                    
              </TableRow>
            ))}
          </TableBody>
          <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {rowActions?.map((action, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleActionClick(action)}
                  >
                    {action.text}
                  </MenuItem>
                ))}
              </Menu>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default EmissionsAddedTable;

