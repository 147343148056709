import * as React from "react";

function LogoMonogram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.451 13.4688H40.1592C39.2566 12.8119 38.3018 12.2425 37.3032 11.7516V12.1284C37.9163 12.2948 38.3807 12.8205 38.451 13.4688Z"
        fill="#FFDE59"
      />
      <path
        d="M36.2434 11.2696C34.0443 10.3414 31.6526 9.80671 29.1294 9.7454V12.1636C29.6902 12.3561 30.1018 12.8642 30.1721 13.4688H35.2619C35.3232 12.8818 35.7177 12.3913 36.2434 12.1808V11.2696Z"
        fill="#FFDE59"
      />
      <path
        d="M20.9116 12.1808C21.4377 12.3913 21.8317 12.8818 21.8931 13.4688H26.9828C27.0532 12.8556 27.4738 12.3561 28.0342 12.1636V9.7454C25.5113 9.80671 23.1107 10.3414 20.9116 11.2696V12.1808Z"
        fill="#FFDE59"
      />
      <path
        d="M19.8518 11.7516C18.8532 12.2425 17.8979 12.8119 17.0044 13.4688H18.7044C18.7743 12.8205 19.2387 12.2948 19.8518 12.1284V11.7516Z"
        fill="#FFDE59"
      />
      <path
        d="M12.4052 18.1117C11.6871 19.1107 11.0739 20.1882 10.5654 21.3184H11.2755C11.3801 20.7138 11.8183 20.2233 12.4052 20.0655V18.1117Z"
        fill="#FFDE59"
      />
      <path
        d="M19.8517 15.2296C19.3612 15.0984 18.9757 14.739 18.8004 14.2747H15.953C15.0423 15.028 14.1921 15.8693 13.4302 16.7804V20.1007C13.9558 20.2932 14.3499 20.7485 14.4463 21.3184H18.7129C18.818 20.7048 19.2647 20.2233 19.8517 20.0565V15.2296Z"
        fill="#FFDE59"
      />
      <path
        d="M27.0793 14.2747H21.7966C21.6478 14.6863 21.3147 15.0105 20.9116 15.1858V20.1093C21.4111 20.3108 21.7966 20.7661 21.884 21.3184H26.9919C27.0883 20.74 27.4909 20.2756 28.0342 20.0917V15.2034C27.596 15.0542 27.2457 14.7125 27.0793 14.2747Z"
        fill="#FFDE59"
      />
      <path
        d="M36.2434 15.1858C35.8403 15.0105 35.5072 14.6863 35.3584 14.2747H30.0757C29.9179 14.7039 29.5676 15.0456 29.1294 15.2034V20.0917C29.6641 20.2756 30.0671 20.7485 30.1631 21.3184H35.2705C35.3584 20.7661 35.7439 20.3108 36.2434 20.1093V15.1858Z"
        fill="#FFDE59"
      />
      <path
        d="M43.7247 16.7714C42.9624 15.8693 42.1216 15.028 41.2105 14.2747H38.3631C38.1792 14.7476 37.7937 15.0984 37.3032 15.2296V20.0565C37.8902 20.2143 38.337 20.7048 38.442 21.3184H42.7172C42.8136 20.7575 43.1991 20.2932 43.7247 20.1007V16.7714Z"
        fill="#FFDE59"
      />
      <path
        d="M45.8888 21.3184H46.5984C46.0903 20.1791 45.4682 19.1017 44.75 18.0941V20.0565C45.3365 20.2233 45.7837 20.7048 45.8888 21.3184Z"
        fill="#FFDE59"
      />
      <path
        d="M46.9663 22.1598H45.8189C45.6521 22.6417 45.2491 23.0186 44.75 23.1588V29.0547H48.3769C48.3241 26.6193 47.8246 24.2976 46.9663 22.1598Z"
        fill="#FFDE59"
      />
      <path
        d="M43.7247 23.1147C43.2866 22.9573 42.9363 22.6066 42.7785 22.1598H38.3807C38.2053 22.6503 37.8027 23.0272 37.3032 23.1588V27.9768C37.8289 28.117 38.2495 28.529 38.3983 29.0547H43.7247V23.1147Z"
        fill="#FFDE59"
      />
      <path
        d="M36.2434 23.1061C35.8227 22.9397 35.4896 22.589 35.3408 22.1598H30.0932C29.9355 22.6066 29.5762 22.9659 29.1294 23.1237V28.0119C29.6023 28.1783 29.9792 28.5642 30.1194 29.0547H35.3147C35.4549 28.5903 35.7966 28.2135 36.2434 28.0295V23.1061Z"
        fill="#FFDE59"
      />
      <path
        d="M27.0617 22.1598H21.8142C21.6654 22.589 21.3322 22.9397 20.9116 23.1061V28.0295C21.3588 28.2135 21.7001 28.5903 21.8403 29.0547H27.0356C27.1758 28.5642 27.5522 28.1697 28.0342 28.0119V23.1237C27.5788 22.9745 27.2195 22.6156 27.0617 22.1598Z"
        fill="#FFDE59"
      />
      <path
        d="M19.8517 23.1588C19.3522 23.0186 18.9492 22.6417 18.7828 22.1598H14.3764C14.2187 22.6066 13.8684 22.9573 13.4302 23.1147V29.0547H18.7566C18.9054 28.529 19.326 28.126 19.8517 27.9768V23.1588Z"
        fill="#FFDE59"
      />
      <path
        d="M12.4054 23.1498C11.9063 23.0186 11.5119 22.6417 11.3455 22.1598H10.1977C9.33935 24.2976 8.83985 26.6193 8.78711 29.0547H12.4054V23.1498Z"
        fill="#FFDE59"
      />
      <path
        d="M12.4051 31.4902V30.0796H8.79582C8.78681 30.0972 8.78681 30.1062 8.79582 30.1148C9.85571 30.4651 11.0914 30.9033 12.4051 31.4902Z"
        fill="#FFDE59"
      />
      <path
        d="M44.75 31.4902C46.0637 30.9033 47.308 30.4565 48.3679 30.0972C48.3679 30.0972 48.3769 30.0886 48.3679 30.0796H44.75V31.4902Z"
        fill="#FFDE59"
      />
      <path
        d="M37.3032 31.0782V35.8966C37.5133 35.9579 37.7063 36.0543 37.8726 36.1855C39.6335 34.3106 41.7186 32.9617 43.7247 31.9717V30.0792H38.3807C38.2053 30.5697 37.8027 30.9465 37.3032 31.0782Z"
        fill="#FFDE59"
      />
      <path
        d="M13.4302 30.0792V31.9717C15.4363 32.9617 17.5214 34.3106 19.2823 36.1855C19.4487 36.0543 19.6416 35.9579 19.8517 35.8966V31.0782C19.3522 30.938 18.9492 30.5611 18.7828 30.0792H13.4302Z"
        fill="#FFDE59"
      />
      <path
        d="M27.0617 30.0797H21.8142C21.6654 30.5094 21.3322 30.8596 20.9116 31.026V35.9585C21.3412 36.1334 21.6825 36.4927 21.8227 36.9395H27.0532C27.2019 36.4665 27.5698 36.0982 28.0342 35.9404V31.0436C27.5788 30.8948 27.2195 30.5355 27.0617 30.0797Z"
        fill="#FFDE59"
      />
      <path
        d="M36.2434 31.026C35.8227 30.8596 35.4896 30.5094 35.3408 30.0797H30.0932C29.9355 30.5269 29.5762 30.8858 29.1294 31.0436V35.9404C29.5937 36.0982 29.953 36.4751 30.1018 36.9395H35.3318C35.472 36.4927 35.8142 36.1334 36.2434 35.9585V31.026Z"
        fill="#FFDE59"
      />
      <path
        d="M30.1284 37.8852C29.9968 38.3933 29.6199 38.7963 29.1294 38.9712V43.86C29.5148 43.9912 29.8304 44.2716 30.0053 44.6309H30.0144H33.7553C34.1317 42.3442 34.8941 40.3994 35.9016 38.7611C35.6127 38.5511 35.3936 38.2441 35.2971 37.8852H30.1284Z"
        fill="#FFDE59"
      />
      <path
        d="M27.0267 37.8852H21.858C21.7615 38.2441 21.5424 38.5511 21.2534 38.7526C22.27 40.3909 23.0319 42.3355 23.3998 44.6309H27.1407H27.1493C27.3246 44.2716 27.6488 43.9912 28.0342 43.86V38.9712C27.5437 38.8049 27.1583 38.3933 27.0267 37.8852Z"
        fill="#FFDE59"
      />
      <path
        d="M27.0006 45.735H23.5576C23.6541 46.6726 23.6978 47.6713 23.6627 48.7226C25.0643 49.0817 26.5276 49.292 28.0343 49.3184V46.8915C27.5176 46.7163 27.1232 46.2783 27.0006 45.735Z"
        fill="#FFDE59"
      />
      <path
        d="M33.6061 45.735H30.1546C30.0319 46.2783 29.6465 46.7163 29.1294 46.8915V49.3184C30.6451 49.2832 32.108 49.073 33.5096 48.7137C33.4659 47.6624 33.5096 46.6726 33.6061 45.735Z"
        fill="#FFDE59"
      />
      <path
        d="M56.918 25.7959C54.9907 28.389 51.7142 29.9218 48.2802 31.5164C41.8763 34.4864 34.9291 37.6489 35.1919 48.2056C35.2181 49.1868 35.3056 50.2381 35.4633 51.3594C35.4633 51.3594 44.6006 50.352 50.6809 42.3272C50.6809 42.3272 47.4918 52.9978 30.8027 55.7048L31.1179 58C45.7133 56.7209 57.1637 44.4561 57.1637 29.5278C57.1637 28.2664 57.0758 27.0135 56.918 25.7959Z"
        fill="#FFDE59"
      />
      <path
        d="M6.47456 42.3272C12.5629 50.352 21.7002 51.3594 21.7002 51.3594C21.858 50.2381 21.9369 49.1781 21.9631 48.1968C22.2259 37.6579 15.2877 34.495 8.88333 31.5164C5.4494 29.9308 2.16425 28.389 0.236631 25.7959C0.0790624 27.0135 0 28.2664 0 29.5278C0 44.4561 11.4413 56.7209 26.0367 58L26.3523 55.7048C9.67182 53.0065 6.47456 42.3272 6.47456 42.3272Z"
        fill="#FFDE59"
      />
      <path
        d="M29.455 6.51662V0.876728C29.455 0.393088 29.0631 0.000771046 28.5791 0.000771046C28.0946 0.000771046 27.7031 0.393088 27.7031 0.876728V6.51662C27.7031 7.00026 28.0946 7.39258 28.5791 7.39258C29.0631 7.39258 29.455 7.00026 29.455 6.51662Z"
        fill="#FFDE59"
      />
      <path
        d="M24.1535 7.82031C24.2092 7.82031 24.2654 7.81474 24.3229 7.80402C24.7975 7.71055 25.1075 7.25049 25.014 6.77543L23.9301 1.241C23.8371 0.766366 23.3817 0.454658 22.9019 0.549842C22.4269 0.642883 22.1173 1.10337 22.2104 1.57801L23.2947 7.11286C23.3766 7.53047 23.7427 7.82031 24.1535 7.82031Z"
        fill="#FFDE59"
      />
      <path
        d="M19.0843 8.54794C19.2232 8.88752 19.55 9.09375 19.8955 9.09375C20.0057 9.09375 20.1181 9.07274 20.2257 9.02858C20.6742 8.84593 20.8898 8.33485 20.7063 7.88637L18.5788 2.66365C18.3966 2.2156 17.8834 2.00036 17.4375 2.18301C16.9894 2.36566 16.7737 2.87675 16.9568 3.32523L19.0843 8.54794Z"
        fill="#FFDE59"
      />
      <path
        d="M15.228 10.7644C15.3956 11.0208 15.6756 11.1602 15.9612 11.1602C16.1262 11.1602 16.2922 11.1139 16.4405 11.0165C16.8448 10.7511 16.958 10.2083 16.6926 9.80356L13.6008 5.08678C13.3367 4.68203 12.7922 4.56884 12.3887 4.83424C11.984 5.09965 11.8708 5.64289 12.1362 6.04763L15.228 10.7644Z"
        fill="#FFDE59"
      />
      <path
        d="M11.8696 13.6797C12.0406 13.8555 12.2683 13.9434 12.496 13.9434C12.7168 13.9434 12.9376 13.8606 13.1087 13.6943C13.4543 13.356 13.4611 12.8016 13.122 12.4556L9.18081 8.42137C8.84381 8.07579 8.28985 8.06936 7.9417 8.40679C7.59612 8.74551 7.58926 9.2999 7.92841 9.64591L11.8696 13.6797Z"
        fill="#FFDE59"
      />
      <path
        d="M9.13342 17.189C9.28477 17.2936 9.45841 17.3438 9.62949 17.3438C9.90775 17.3438 10.1817 17.2117 10.3515 16.9652C10.6264 16.5664 10.526 16.021 10.1277 15.7466L5.48468 12.5451C5.08593 12.2702 4.54098 12.3706 4.26614 12.7689C3.99174 13.1676 4.09164 13.713 4.49038 13.9874L9.13342 17.189Z"
        fill="#FFDE59"
      />
      <path
        d="M1.95222 18.9068L7.12391 21.1552C7.23754 21.2049 7.35587 21.2285 7.47207 21.2285C7.81079 21.2285 8.13278 21.0313 8.27642 20.7016C8.46979 20.2574 8.26612 19.7416 7.82193 19.5486L2.65024 17.2998C2.20433 17.106 1.69119 17.3105 1.49791 17.7539C1.30449 18.1976 1.50815 18.7138 1.95222 18.9068Z"
        fill="#FFDE59"
      />
      <path
        d="M3.32307 23.0718C2.85144 22.9659 2.3828 23.2661 2.27905 23.739C2.17486 24.2115 2.47327 24.6793 2.94662 24.783L5.91406 25.4365C5.97752 25.4506 6.04098 25.457 6.10315 25.457C6.50532 25.457 6.86806 25.1788 6.9581 24.7693C7.06228 24.2972 6.76386 23.8295 6.29094 23.7253L3.32307 23.0718Z"
        fill="#FFDE59"
      />
      <path
        d="M32.8347 7.80402C32.8922 7.81474 32.9484 7.82031 33.0041 7.82031C33.4149 7.82031 33.781 7.53047 33.8633 7.11286L34.9472 1.57801C35.0403 1.10337 34.7307 0.642883 34.2557 0.549842C33.7724 0.454658 33.3205 0.766366 33.2275 1.241L32.1436 6.77543C32.0501 7.25049 32.3601 7.71055 32.8347 7.80402Z"
        fill="#FFDE59"
      />
      <path
        d="M36.9317 9.02858C37.0393 9.07274 37.1516 9.09375 37.2618 9.09375C37.6074 9.09375 37.9345 8.88752 38.073 8.54794L40.2005 3.32523C40.3836 2.87675 40.1679 2.36567 39.7199 2.18301C39.274 1.99993 38.7616 2.2156 38.5785 2.66365L36.451 7.88637C36.2679 8.33485 36.4832 8.84593 36.9317 9.02858Z"
        fill="#FFDE59"
      />
      <path
        d="M40.7174 11.0165C40.8658 11.1139 41.0317 11.1602 41.1968 11.1602C41.4823 11.1602 41.7623 11.0208 41.93 10.7644L45.0218 6.04763C45.2872 5.64288 45.174 5.09964 44.7696 4.83424C44.3658 4.56927 43.8225 4.68203 43.5571 5.08678L40.4653 9.80356C40.1999 10.2083 40.3131 10.7511 40.7174 11.0165Z"
        fill="#FFDE59"
      />
      <path
        d="M44.05 13.6943C44.2206 13.8606 44.4414 13.9434 44.6623 13.9434C44.8899 13.9434 45.1176 13.8555 45.2887 13.6797L49.229 9.64591C49.5677 9.2999 49.5608 8.74508 49.2157 8.40679C48.8688 8.06936 48.3144 8.07579 47.9766 8.42137L44.0363 12.4556C43.6971 12.8016 43.704 13.3564 44.05 13.6943Z"
        fill="#FFDE59"
      />
      <path
        d="M46.8055 16.9652C46.9758 17.2117 47.2493 17.3438 47.5276 17.3438C47.6986 17.3438 47.8723 17.2936 48.0236 17.189L52.6667 13.9874C53.0654 13.713 53.1653 13.1676 52.8905 12.7689C52.6152 12.3706 52.0694 12.2702 51.6724 12.5451L47.0293 15.7466C46.6306 16.021 46.5307 16.5664 46.8055 16.9652Z"
        fill="#FFDE59"
      />
      <path
        d="M48.8807 20.7016C49.0248 21.0313 49.3463 21.2285 49.685 21.2285C49.8012 21.2285 49.9196 21.2049 50.0332 21.1552L55.2057 18.9068C55.6499 18.7138 55.8536 18.1976 55.6602 17.7538C55.4669 17.3105 54.9519 17.1064 54.5077 17.2998L49.3352 19.5486C48.891 19.7416 48.6873 20.2574 48.8807 20.7016Z"
        fill="#FFDE59"
      />
      <path
        d="M53.8359 23.0718L50.868 23.7253C50.3947 23.8295 50.0962 24.2972 50.2004 24.7693C50.2905 25.1788 50.6532 25.457 51.0549 25.457C51.1175 25.457 51.181 25.4506 51.244 25.4365L54.2119 24.783C54.6853 24.6793 54.9837 24.2115 54.8795 23.739C54.7753 23.2661 54.3054 22.9655 53.8359 23.0718Z"
        fill="#FFDE59"
      />
    </svg>
  );
}

export default LogoMonogram;
