import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { createLocation, CreateLocationData } from "../../../../api/locations-api";
import { MutationConfig } from "../../../../utils/queryClient";


export const useCreateLocation = (
  config?: MutationConfig<typeof createLocation>
): UseMutationResult<void, AxiosError, CreateLocationData> => {
  return useMutation<void, AxiosError, CreateLocationData>({
    mutationFn: createLocation,
    ...config,
  });
};






