
import React, { useState, useEffect } from "react";
import { Card, CustomTable } from "../../../../ui";
import CreateEmission from "./create-emission";
import {
  InputAdornment,
  MenuItem,
  TextField,
  Box,
  useTheme,
} from "@mui/material";
import { ChevronDown } from "../../../../icons";
import {
  createEmission,
  deleteEmissionSource,
  emissionSourceStatus,
  getEmissionSource,
} from "../../../../api/emissions-api";
import DeleteModal from "../../manage/location/delete-location";
import ChangeStatusModal from "./Status-modal";
import { showToast } from "../../../../utils/showToast.util";
import { useFetcher } from "react-router-dom";

const protocols = [
  { value: "CSRD", label: "CSRD" },
  { value: "SECR", label: "SECR" },
];

const EmissionSources: React.FC = () => {
  const theme = useTheme();
  const [rows, setRows] = useState<any[]>([]);
  const [selectedProtocol, setSelectedProtocol] = useState<string>("");
  const [selectedEmission, setSelectedEmission] = useState<any>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const itemsPerPage = 8;

  const columns = [
    { key: "id", label: "Sr." },
    { key: "sourceName", label: "Source Name" },
    { key: "description", label: "Description" },
    { key: "asset", label: "Asset" },
    { key: "category", label: "Category" },
    { key: "subCategory", label: "Sub-category" },
    { key: "status", label: "Status" },
  ];

  const onAddEmission = async (newEmission: any) => {
    try {
      const response = await createEmission(newEmission);
      if (response.success) {
        await fetchEmissionSources(currentPage);
        showToast(response.message);
      }
    } catch (error: any) {
      console.error("Error adding emission:", error);
    }
  };

  const handleDeleteConfirm = async () => {
    if (selectedEmission) {
      try {
        await deleteEmissionSource(selectedEmission.emissionSourceId);
        setRows((prevRows) =>
          prevRows.filter(
            (row) => row.emissionSourceId !== selectedEmission.emissionSourceId
          )
        );
        setIsDeleteModalOpen(false);
      } catch (error: any) {
        console.error("Error deleting emission source:", error);
      }
    }
  };
  const handleDelete = (emission: any) => {
    setSelectedEmission(emission);
    setIsDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  const fetchEmissionSources = async (page: number) => {
    try {
      const response = await getEmissionSource({
        search: searchQuery,
        controlStatuses: ["Owned", "Leased", "Subcontracted", "Jointly Owned", "Other"],
        categoryIds: [],
        assetIds: [],
        statuses: ["ACTIVE", "INACTIVE"],
        page: 0,
        size: 10,
        sort: "createdDate,desc",
      });
      setRows(response.data || []);
      setTotalPages(response?.totalPages || 1);
    } catch (error: any) {
      console.error("Error fetching emission sources:", error);
    }
  };

  useEffect(() => {
    fetchEmissionSources(currentPage);
  }, [searchQuery, currentPage]);

    const rowsDisplay = rows.map((item, i) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
  }));

  const handleOpenChangeStatusModal = (emission: any) => {
    setSelectedEmission(emission);
    setIsChangeStatusModalOpen(true);
  };

  const handleChangeStatusConfirm = async () => {
    if (selectedEmission) {
      try {
        const newStatus =
          selectedEmission.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";

        const payload = {
          emissionSourceId: selectedEmission.emissionSourceId,
          status: newStatus,
        };
        await emissionSourceStatus(payload);
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.emissionSourceId === selectedEmission.emissionSourceId
              ? { ...row, status: newStatus }
              : row
          )
        );

        setIsChangeStatusModalOpen(false);
      } catch (error: any) {
        console.error("Error changing emission source status:", error);
      }
    }
  };
  const handleCloseChangeStatusModal = () => {
    setIsChangeStatusModalOpen(false);
  };

  const handleProtocolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedProtocol(event.target.value);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Card
        headerText="Emissions"
        headerBtnText="Create"
        tableDataLength={rows?.length}
        searchQuery= {searchQuery}
        setSearchQuery={setSearchQuery}
      >
        <TextField
          name="protocol"
          value={selectedProtocol}
          onChange={handleProtocolChange}
          placeholder=""
          label="Protocol"
          select
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ChevronDown />
              </InputAdornment>
            ),
          }}
          SelectProps={{
            displayEmpty: true,
            renderValue: (value: unknown) => {
              if (typeof value === "string" && value.length === 0) {
                return (
                  <span style={{ color: theme.palette.grey[500] }}>
                    SECR/CSRD
                  </span>
                );
              }
              const selectedOption = protocols.find(
                (option) => option.value === value
              );
              return selectedOption ? selectedOption.label : "SECR/CSRD";
            },
          }}
        >
          {protocols.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 3,
          }}
        ></Box>

        <CreateEmission onAddEmission={onAddEmission} />

        <CustomTable
          columns={columns}
          data={rowsDisplay}
          rowActions={[
            {
              text: "Delete Emission",
              onClick: (emission: any) => handleDelete(emission),
            },
            {
              text: "Change Status",
              onClick: (emission: any) => handleOpenChangeStatusModal(emission),
            },
          ]}

          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}

        />
      </Card>
      <DeleteModal
        isEmission={true}
        isOpen={isDeleteModalOpen}
        onCancel={handleCloseModal}
        onDelete={handleDeleteConfirm}
      />

      <ChangeStatusModal
        isOpen={isChangeStatusModalOpen}
        onCancel={handleCloseChangeStatusModal}
        onConfirm={handleChangeStatusConfirm}
        currentStatus={selectedEmission?.status}
      />
    </>
  );
};

export default EmissionSources;
