import React from "react";
import { Typography, TypographyProps, PaletteColor } from "@mui/material";
import theme from "../../styles/theme";
import { Link } from "react-router-dom";

type ThemeColor = keyof typeof theme.palette;
type ColorShade = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

type PaletteColorWithShades = PaletteColor & {
  [key in ColorShade]?: string;
};

interface CustomTextProps extends TypographyProps {
  text: string;
  color?: ThemeColor;
  shade?: ColorShade | string | undefined;
  linkText?: string;
  linkHref?: string;
  fontSize?: string;
}

/**
 * CustomText component.
 * Renders a customizable heading text with specified color and shade from the theme palette.
 *
 * @param {CustomTextProps} props - Props for the CustomText component.
 * @param {string} props.text - Text to be displayed in the heading.
 * @param {string} [props.color="primary"] - Theme color key for the heading text.
 * @param {ColorShade} [props.shade="contrastText"] - Shade of the theme color for the heading text.
 * @param {object} [props.sx] - Additional styling to be applied to the Typography component.
 * @returns {JSX.Element} JSX code for rendering the CustomText component.
 */

const CustomText: React.FC<CustomTextProps> = ({
  text = "",
  linkText = "",
  linkHref = "",
  variant = "body2",
  color = "grey",
  shade = "200",
  fontSize = "14px",
  sx,
  ...props
}) => {
  const getColor = (colorKey: ThemeColor, shadeKey: ColorShade): string => {
    const paletteColor = theme.palette[colorKey] as
      | PaletteColorWithShades
      | string;

    if (typeof paletteColor === "object" && paletteColor !== null) {
      if (shadeKey in paletteColor) {
        return paletteColor[shadeKey] as string;
      }
      // Fallback to main if the specific shade doesn't exist
      return paletteColor.main || theme.palette.text.primary;
    }

    // If paletteColor is a string, it's likely a simple color value
    if (typeof paletteColor === "string") {
      return paletteColor;
    }

    // Final fallback
    return theme.palette.text.primary;
  };

  return (
    <Typography
      variant={variant}
      sx={{
        color: getColor(color as ThemeColor, shade as ColorShade),
        fontWeight: "400",
        fontSize: fontSize,
        ...sx,
      }}
      {...props}
    >
      {text}{" "}
      {linkText && (
        <span style={{ color: theme.palette.primary.main, fontSize: fontSize }}>
          <Link to={linkHref}>{linkText}</Link>
        </span>
      )}
    </Typography>
  );
};

export default CustomText;

// usage example

// import CustomText from "path-to-custom-heading"

/* 
<CustomText
  gutterBottom
  text="Transform Your Sustainability Management"
  color="primary"
/>;
 */
