import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link as RouterLink} from "react-router-dom";
import { z } from "zod";
import { AppleIcon, EmailIcon, GoogleIcon, WindowsIcon } from "../../../icons";
import AuthLayout from "../../../layouts/auth-layout/auth-layout";
import {
  CustomButton,
  CustomCheckbox,
  CustomText,
  CustomTextField,
} from "../../../ui";
import PasswordValidator from "../password-validator";
import { showToast } from "../../../utils/showToast.util";
import { useSignup } from "./useSignup";

const schema = z.object({
  email: z
    .string()
    .min(1, "Email is required!")
    .email("Please enter a valid email address!"),
  password: z
    .string()
    .min(8, "Password must be at least 8 characters")
    .refine(
      (value) => /[a-z]/.test(value) && /[A-Z]/.test(value),
      "Password must contain both upper and lower case letters"
    )
    .refine(
      (value) => /\d/.test(value),
      "Password must contain at least one digit"
    )
    .refine(
      (value) => /[!@#$%^&*(),.?":{}|<>]/.test(value),
      "Password must contain at least one special character"
    ),
  acceptTerms: z.boolean().refine((value) => value === true, {
    message: "You must accept the terms and conditions",
  }),
});

type FormData = z.infer<typeof schema>; 
const Signup: React.FC = () => {
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      acceptTerms: false,
    },
  });

  const {
    formState: { isSubmitting, isValid },
    watch,
  } = methods;

  const password = watch("password");
  const acceptTerms = watch("acceptTerms");

  const { mutate: signup, isPending } = useSignup({
    onSuccess: (data) => {
      const {message} = data; 
      showToast(message);
    },
    onError: (error: any) => {
      showToast(error, "error");  
    }
  }); 

  const onSubmit = (data: FormData) => {
    signup(data);
  };

  return (
    <AuthLayout title="Sign up" subTitle="Sign up to your account!">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CustomTextField
            name="email"
            margin="normal"
            label="Email Address"
            type="email"
            placeholder="Enter Email Address"
            icon={<EmailIcon />}
            fullWidth
          />
          <CustomTextField
            name="password"
            margin="normal"
            label="Password"
            type="password"
            placeholder="Enter Password"
          />

          {password?.length > 0 && <PasswordValidator password={password} />}

          <Box display="flex" alignItems="center">
            <CustomCheckbox
              name="acceptTerms"
              label="I agree to Planet Pulse"
            />
            <RouterLink to="/terms-and-conditions">
              <Link
                component="span"
                color="#59A5FF"
                variant="body2"
                ml="2px"
                underline="always"
              >
                Terms & Conditions
              </Link>
            </RouterLink>
          </Box>

          {methods.formState.errors.acceptTerms && (
            <Typography color="error" variant="body2">
              {methods.formState.errors.acceptTerms.message}
            </Typography>
          )}

          <CustomButton
            sx={{ mt: 2 }}
            rounded="12px"
            text="Get Started"
            fullWidth
            type="submit"
            loading={isSubmitting || isPending}
            disabled={!acceptTerms || isSubmitting || isPending || !isValid}
          />
          <Box
            sx={{ display: "flex", alignItems: "center", width: "100%", my: 4 }}
          >
            <Divider sx={{ flexGrow: 1, borderColor: "#E7E7E7" }} />
            <Typography sx={{ mx: 2, color: "grey.200" }}>
              Or Continue With?
            </Typography>
            <Divider sx={{ flexGrow: 1, borderColor: "#E7E7E7" }} />
          </Box>
          <Stack spacing={2}>
            <CustomButton
              text="Signup with Google"
              bgColor="trasnparent"
              icon={GoogleIcon}
              rounded="12px"
            />
            <CustomButton
              text="Signup with Apple"
              bgColor="trasnparent"
              icon={AppleIcon}
              rounded="12px"
            />
            <CustomButton
              text="Signup with Microsoft"
              bgColor="trasnparent"
              icon={WindowsIcon}
              rounded="12px"
            />
          </Stack>
          <CustomText
            text="Already have an account?"
            fontSize="16px"
            linkText="Login"
            linkHref="/login"
            mt={4}
            sx={{ textAlign: "center" }}
          />
        </form>
      </FormProvider>
    </AuthLayout>
  );
};

export default Signup;

