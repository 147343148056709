import { Box } from "@mui/material";
import { FC, ReactNode } from "react";

interface VerticalTabsCardProps {
  children: ReactNode;  // Header and TabsHeader as children
  content: ReactNode;   // Tab content as a separate prop
}

const VerticalTabsCard: FC<VerticalTabsCardProps> = ({ children, content }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#fff",
        borderRadius: "24px",
      }}
    >
      <Box
        sx={{
          maxWidth: "226px",
          borderRight: "1px solid #D0D5DD",
          padding: "15px 0",
          flexShrink: 0
        }}
      >
        {children}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        {content}
      </Box>
    </Box>
  );
};

export default VerticalTabsCard;
