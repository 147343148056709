import Axios from "axios";
import useStorage from "./useStorage.util";

const defaultOptions = {
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export const axiosInstanceNoAuth = Axios.create(defaultOptions);

export const axiosInstanceWithAuth = Axios.create(defaultOptions);

axiosInstanceWithAuth.interceptors.request.use(
  async (config) => {
    const token = useStorage.getItem("planetPulseToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstanceWithAuth.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Refresh token logic
        // const newToken = await refreshTokenAPI();
        // Cookies.set("planetPulseToken", newToken);
        // originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
        return axiosInstanceWithAuth(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);
