import React, { FC, useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styles } from "./custom-accordion-table.style";
import { useLocationContext } from "../../context/location-context";
import { showToast } from "../../utils/showToast.util";
import CustomTextField from "../custom-textfield/custom-textfield";
import { calculateScope2LocationEmission } from "../../api/scope2-location-api";

interface Column {
  key: string;
  label: string;
  flexBasis: string;
}

interface Row {
  facilityName: string;
  amount: string;
  emissions: string;
  actions: string;
  detailsType?: string;
}

interface CustomLocationAccordionProps {
  columns: Column[];
  rows: Row[];
  expandedRowIndex: number | null;
  onExpandRow: (index: number | null) => void;
  onEmissionTypeChange: (emissionType: string) => void;
  locationId: number;
  onEmissionsUpdate: (data: any) => void;
  
}

const CustomLocationAccordion: FC<CustomLocationAccordionProps> = ({
  columns,
  rows,
  expandedRowIndex,
  onExpandRow,
  onEmissionTypeChange,
  locationId,
  onEmissionsUpdate
}) => {
  const { locationPreferences } = useLocationContext();

  const [values, setValues] = useState<{
    [key: string]: number | null;
  }>({
    gridElectricity: null,
    energySupplyContracts: null,
    directLineMicroGridFossilFuel: null,
    onSiteRenewables: null,
    onsiteFossilFuel: null,
  });


  const handleChange = (index: number) => {
    onExpandRow(expandedRowIndex === index ? null : index);
    const selectedRow = rows[index];
    if (selectedRow.detailsType) {
      onEmissionTypeChange(selectedRow.detailsType);
    }
  };

  const handleInputBlur = async (value: string, emissionType: string) => {
    const measuringValue = parseFloat(value);
    const emissionTypeMapping: { [key: string]: string } = {
      gridElectricity: "GRID ELECTRICITY",
      energySupplyContracts: "ENERGY SUPPLY CONTRACTS",
      onSiteRenewables: "ON SITE RENEWABLES",
      directLineMicroGridFossilFuel: "DIRECT LINE MICRO GRID FOSSIL FUEL",
      onsiteFossilFuel: "ON SITE FOSSIL FUEL",
    };

    const mappedEmissionType = emissionTypeMapping[emissionType];

    if (!isNaN(measuringValue) && value.trim() !== "" && mappedEmissionType) {
      try {
        const requestBody = {
         
          scopeTwoLocationEmissionType: mappedEmissionType,
          locationId: locationId,
          measuringValue: measuringValue,
          protocol: "CSRD",
        };

        onEmissionsUpdate((prevEmissions: any) => Array.isArray(prevEmissions) ? [...prevEmissions, requestBody] : [requestBody]);

        const response = await calculateScope2LocationEmission(requestBody);
        const calculatedEmission = response.data?.calculatedEmission;

        if (calculatedEmission !== undefined) {
          setValues((prev) => ({
            ...prev,
            [emissionType]: calculatedEmission,
          }));
        }
      } catch (error) {
        console.error("Error calculating emission:", error);
      }
    }
  };

  return (
    <>
      <Accordion disableGutters>
        <AccordionSummary
          sx={{
            ...styles.tableTextHead,
            background: "#F3F4F6",
            borderRadius: "10px",
            padding: "8px 16px",
          }}
        >
          <Box
            sx={styles.container}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            width="100%"
          >
            <Box sx={{ flexBasis: "5%" }}>
              <ExpandMoreIcon />
            </Box>
            {columns.map((col) => (
              <Typography
                key={col.key}
                sx={{
                  ...styles.text,
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#2A2A2A",
                  flexBasis: col.flexBasis,
                  textAlign: "left",
                }}
              >
                {col.label}
              </Typography>
            ))}
          </Box>
        </AccordionSummary>
      </Accordion>

      {rows.map((row, index) => (
        <Accordion
          key={index}
          expanded={expandedRowIndex === index}
          onChange={() => handleChange(index)}
          disableGutters
        >
          <AccordionSummary
            sx={{
              ...styles.tableTextHead,
              background: "white",
              borderRadius: "10px",
              padding: "8px 16px",
            }}
          >
            <Box
              sx={styles.container}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
            >
              <Box sx={{ flexBasis: "5%" }}>
                <ExpandMoreIcon />
              </Box>
              {columns.map((col) => (
                <Typography
                  key={col.key}
                  sx={{
                    ...styles.text,
                    fontSize: 14,
                    flexBasis: col.flexBasis,
                    textAlign: "left",
                  }}
                >
                  {row[col.key as keyof Row]}
                </Typography>
              ))}
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              ...styles.tableBody,
              padding: "16px",
              margin: "0px",
            }}
          >
            <Box sx={{ padding: "8px 16px" }}>
              {/* GRID ELECTRICITY */}
              {locationPreferences?.electricityPreference
                ?.scopeTwoLocationWithoutContractualInstruments && (
                <Box display="flex" alignItems="center" marginBottom="12px">
                  <Box flex="0 1 60%">
                    <Typography sx={{ marginLeft: "120px" }}>
                      Grid Electricity
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" marginBottom="12px">
                    <CustomTextField
                      name="gridElectricity"
                      placeholder="0.123"
                      onBlur={(e) =>
                        handleInputBlur(e.target.value, "gridElectricity")
                      }
                      sx={{ width: "150px" }}
                    />
                  </Box>
                  <Box flex="0 1 20%" textAlign="center" marginLeft="8px">
                    {values.gridElectricity !== null
                      ? values.gridElectricity.toFixed(2)
                      : "0000"}
                  </Box>
                  <Box flex="0 1 20%" textAlign="center" marginLeft="8px">
                    <Typography sx={{ marginRight: "200px" }}>Kwh</Typography>
                  </Box>
                </Box>
              )}

              {/* ENERGY SUPPLY CONTRACTS */}
              {locationPreferences?.electricityPreference
                ?.scopeTwoLocationWithContractualInstrumentsDto
                ?.energySupplyContracts && (
                <Box display="flex" alignItems="center" marginBottom="12px">
                  <Box flex="0 1 60%">
                    <Typography sx={{ marginLeft: "120px" }}>
                      Energy Supply Contracts
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" marginBottom="12px">
                    <CustomTextField
                      name="energySupplyContracts"
                      placeholder="0.123"
                      onBlur={(e) =>
                        handleInputBlur(e.target.value, "energySupplyContracts")
                      }
                      sx={{ width: "150px" }}
                    />
                  </Box>

                  <Box flex="0 1 20%" textAlign="center" marginLeft="8px">
                    {values.energySupplyContracts !== null
                      ? values.energySupplyContracts.toFixed(2)
                      : "0000"}
                  </Box>
                  <Box flex="0 1 20%" textAlign="center" marginLeft="8px">
                    <Typography sx={{ marginRight: "200px" }}>Kwh</Typography>
                  </Box>
                </Box>
              )}

              {/* DIRECT LINE MICRO GRID FOSSIL FUEL */}
              {locationPreferences?.electricityPreference
                ?.scopeTwoLocationDirectLineMicrogridFossilFuel && (
                <Box display="flex" alignItems="center" marginBottom="12px">
                  <Box flex="0 1 60%">
                    <Typography sx={{ marginLeft: "120px" }}>
                      Direct Line Micro Grid Fossil Fuel
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" marginBottom="12px">
                    <CustomTextField
                      name="directLineMicroGridFossilFuel"
                      placeholder="0.123"
                      onBlur={(e) =>
                        handleInputBlur(
                          e.target.value,
                          "directLineMicroGridFossilFuel"
                        )
                      }
                      sx={{ width: "150px" }}
                    />
                  </Box>

                  <Box flex="0 1 20%" textAlign="center" marginLeft="8px">
                    {values.directLineMicroGridFossilFuel !== null
                      ? values.directLineMicroGridFossilFuel
                      : "0000"}
                  </Box>
                  <Box flex="0 1 20%" textAlign="center" marginLeft="8px">
                    <Typography sx={{ marginRight: "200px" }}>Kwh</Typography>
                  </Box>
                </Box>
              )}

              {/* ON SITE RENEWABLES */}
              {locationPreferences?.electricityPreference?.onsiteRenewables && (
                <Box display="flex" alignItems="center" marginBottom="12px">
                  <Box flex="0 1 60%">
                    <Typography sx={{ marginLeft: "120px" }}>
                      On Site Renewables
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" marginBottom="12px">
                    <CustomTextField
                      name="onSiteRenewables"
                      placeholder="0.1234"
                      onBlur={(e) =>
                        handleInputBlur(e.target.value, "onSiteRenewables")
                      }
                      sx={{ width: "150px" }}
                    />
                  </Box>

                  <Box flex="0 1 20%" textAlign="center" marginLeft="8px">
                    {values.onSiteRenewables !== null
                      ? values.onSiteRenewables
                      : "0000"}
                  </Box>
                  <Box flex="0 1 20%" textAlign="center" marginLeft="8px">
                    <Typography sx={{ marginRight: "200px" }}>Kwh</Typography>
                  </Box>
                </Box>
              )}

              {/* ON SITE FOSSIL FUEL */}
              {locationPreferences?.electricityPreference?.onsiteFossilFuel && (
                <Box display="flex" alignItems="center" marginBottom="12px">
                  <Box flex="0 1 60%">
                    <Typography sx={{ marginLeft: "120px" }}>
                      On Site Fossil Fuel
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" marginBottom="12px">
                    <CustomTextField
                      name="onsiteFossilFuel"
                      placeholder="0.123"
                      onBlur={(e) =>
                        handleInputBlur(e.target.value, "onsiteFossilFuel")
                      }
                      sx={{ width: "150px" }}
                    />
                  </Box>
                  <Box flex="0 1 20%" textAlign="center" marginLeft="8px">
                    {values.onsiteFossilFuel !== null
                      ? values.onsiteFossilFuel.toFixed(2)
                      : "0000"}
                  </Box>
                  <Box flex="0 1 20%" textAlign="center" marginLeft="8px">
                    <Typography sx={{ marginRight: "200px" }}>Kwh</Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default CustomLocationAccordion;
