import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ChevronDown, LayoutGridIcon, ListIcon } from "../../../../../icons";
import theme from "../../../../../styles/theme";
import {
  Card,
  CustomTable,
  CustomTextField,
} from "../../../../../ui";
import AddEditProcessEmission from "./add-edit-process-emission";
import { CustomGridCard } from "../../../../../ui/custom-grid-card/custom-grid-card";
import {
  getProcessEmissionByFileId,
  getProcessEmissionByProtocol,
} from "../../../../../api/process-api";
import ViewReasonProcessEmission from "./view-reason-process-emission";
import { getUploadedFiles } from "../../../../../api/fuel-api";
import { Close as CloseIcon } from "@mui/icons-material";

const protocols = [
  { value: "csrd", label: "CSRD" },
  { value: "secr", label: "SECR" },
];

const ProcessEmissions: FC = () => {
  const navigate = useNavigate();
  const methods = useForm<FormData>({});
  const [isGridView, setIsGridView] = useState(false);
  const [processEmissionData, setProcessEmissionData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [protocol, setProtocol] = useState<string>("CSRD");
  const [processEmissionId, setProcessEmissionId] = useState<any | null>(null);
  const [fileTableData, setFileTableData] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [fileData, setFileData] = useState<any[]>([]);

  const itemsPerPage = 8;
  const fetchProcessEmissionData = async (
    page: number,
    itemsPerPage: number,
    protocol?: string,
    sort?: string
  ) => {
    try {
      const response = await getProcessEmissionByProtocol({
        page: page - 1,
        size: itemsPerPage,
        protocol: protocol ? protocol.toUpperCase() : "CSRD",
        sort: "createdDate,desc",
      });
      setProcessEmissionData(response.data);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error("Failed to fetch fuel combustion data:", error);
    }
  };

  useEffect(() => {
    if (protocol !== "") {
      fetchProcessEmissionData(currentPage, itemsPerPage, protocol);
    }
  }, [protocol, currentPage]);

  const processData = processEmissionData.map((item, i) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
  }));

  const handleRowUpdate = (updatedRow: any) => {
    setProcessEmissionData((prevData) =>
      prevData.map((row) =>
        row.processEmissionId === updatedRow.processEmissionId
          ? { ...row, ...updatedRow }
          : row
      )
    );
  };

  const handleProtocolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedProtocol = event.target.value;
    if (protocol !== selectedProtocol) {
      setProtocol(selectedProtocol);
    }
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const handleEditClick = (row: any) => {
    setProcessEmissionId(row.processEmissionId);
    navigate(`?action=edit`);
  };

  const handleViewReasonClick = (row: any) => {
    setProcessEmissionId(row.processEmissionId);
    navigate(`?action=viewReason`);
  };

  const fetchUploadedFiles = async (page: number) => {
    try {
      const response = await getUploadedFiles({
        search: "",
        typeOfEmission: "PROCESS",
        page: page - 1,
        size: 10,
        sort: "createdDate,desc",
      });
      setFileData(response.data);
      fetchProcessEmissionData(currentPage, itemsPerPage, protocol);
    } catch (error) {
      console.error("Error fetching uploaded files process:", error);
    }
  };
  useEffect(() => {
    fetchUploadedFiles(currentPage);
  }, [currentPage]); 

  const formattedDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const file = fileData.map((item, i) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
    uploadDate: formattedDate(item.uploadDate),
  }));

  const fileTable = fileTableData.map((item, i) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
  }));

  const handleViewDetails = async (fileId: number) => {
    try {
      const response = await getProcessEmissionByFileId({
        fileId,
        page: currentPage - 1,
        size: 10,
        sort: "createdDate,desc",
      });
      setFileTableData(response.data);
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching process emission details:", error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false); // close modal
  };

  return (
    <Card
      headerText="Process Emission List"
      headerBtnText="Add New"
      tableDataLength={processEmissionData?.length}
      fetchUploadedFiles={fetchUploadedFiles}
      currentPage={currentPage}
      uploadUrl="/process-emission/upload"
      fileType="PROCESS"
    >
      <AddEditProcessEmission
        processEmissionId={processEmissionId}
        onProcessEmissionData={setProcessEmissionData}
        handleRowUpdate={handleRowUpdate}
        fetchProcessEmissionData={fetchProcessEmissionData}
        currentPage={currentPage}
        fetchUploadedFile ={fetchUploadedFiles}
      />
      <ViewReasonProcessEmission processEmissionId={processEmissionId} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 3,
        }}
      >
        {/* select protocol */}
        <TextField
          name="protocol"
          placeholder="Select Protocol"
          label="Protocol"
          select
          fullWidth
          value={protocol}
          onChange={handleProtocolChange}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ChevronDown />
              </InputAdornment>
            ),
          }}
          SelectProps={{
            displayEmpty: true,
            renderValue: (value: unknown) => {
              const selectedValue = value as string | undefined;
              if (!selectedValue) {
                return (
                  <span style={{ color: theme.palette.grey[200] }}>
                    Select Protocol
                  </span>
                );
              }
              const selectedOption = protocols.find(
                (option) => option.value === selectedValue
              );
              return selectedOption ? selectedOption.label : selectedValue;
            },
          }}
        >
          {protocols.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {/* toggle list/grid view */}
        <Stack direction="row" gap={1} ml={3}>
          <IconButton
            sx={{
              backgroundColor: isGridView ? "none" : "#fff5cc",
              borderRadius: "8px",
              padding: "10px",
              "&:hover": {
                backgroundColor: "#fff5cc",
              },
            }}
            onClick={() => setIsGridView(false)}
          >
            <ListIcon />
          </IconButton>
          <IconButton
            sx={{
              backgroundColor: !isGridView ? "none" : "#fff5cc",
              borderRadius: "8px",
              padding: "10px",
              "&:hover": {
                backgroundColor: "#fff5cc",
              },
            }}
            onClick={() => setIsGridView(true)}
          >
            <LayoutGridIcon />
          </IconButton>
        </Stack>
      </Box>
      {isGridView ? (
        <CustomGridCard
          columns={columns}
          row={processData}
          rowActions={[
            {
              text: "Edit Details",
              onClick: (row) => {
                handleEditClick(row);
              },
            },
            {
              text: "View Reason",
              onClick: (row) => {
                handleViewReasonClick(row);
              },
            },
          ]}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      ) : (
        <CustomTable
          columns={columns}
          data={processData}
          rowActions={[
            {
              text: "Edit Details",
              onClick: (row) => {
                handleEditClick(row);
              },
            },
            {
              text: "View Reason",
              onClick: (row) => {
                handleViewReasonClick(row);
              },
            },
          ]}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
      <Box mt={4}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "1.875rem",
              lineHeight: "1",
              fontFamily: "PoppinsSemibold",
              color: "#000",
            }}
          >
            Process Emission Files
          </Typography>

          <FormProvider {...methods}>
            <form>
              <CustomTextField
                name="search"
                margin="normal"
                type="text"
                placeholder="Search"
                icon="search"
                sx={{ margin: 0 }}
              />
            </form>
          </FormProvider>
        </Box>
        <CustomTable
          columns={columns2}
          data={file}
          rowActions={[
            {
              text: "View Details",
              onClick: (row: any) => {
                handleViewDetails(row.fileId);
              },
            },
          ]}
        />
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Process Emission Details</Typography>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <CustomTable columns={columns} data={fileTable} />
          </DialogContent>
        </Dialog>
      </Box>
    </Card>
  );
};
export default ProcessEmissions;

const columns = [
  { key: "id", label: "Sr." },
  { key: "processType", label: "Process Type" },
  { key: "processDescription", label: "Process Description" },
  { key: "quantityOfRawMaterial", label: "Value & Unit" },
  { key: "dataCollectionMethod", label: "Data Collection" },
  { key: "processEmissionFactor", label: "Emission Factor" },
  { key: "status", label: "Status" },
  { key: "calculatedEmission", label: "Calculated Emission" },
];

const columns2 = [
  { key: "fileId", label: "File ID" },
  { key: "fileName", label: "File Name" },
  { key: "uploadDate", label: "Upload Date" },
  { key: "totalEmissions", label: "Total Emissions" },
];
