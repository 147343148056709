import React, { useEffect, useState } from "react";
import { Card, CustomTable } from "../../../../ui";
import { useNavigate, useLocation } from "react-router-dom";
import { getLocations, deleteLocation } from "../../../../api/locations-api";
import AddLocation from "./add-location";
import DeleteModal from "./delete-location";
import { showToast } from "../../../../utils/showToast.util";
export const Locations = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [locationToDelete, setLocationToDelete] = useState<any>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [searchQuery, setSearchQuery] = useState("");

  const isModalOpen =
    searchParams.get("action") === "add" ||
    searchParams.get("action") === "edit";

  const fetchData = async (page: number) => {
    try {
      const response = await getLocations({
        search: searchQuery,
        countries: [],
        page: page - 1,
        size: itemsPerPage,
        sort: "createdDate,desc",
      });
      setRows(response?.data || []);
      setTotalPages(response?.totalPages || 1);
    } catch (error: any) {
      console.error(error?.message || "Failed to fetch locations.");
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, searchQuery]);

  const handleAddNew = () => {
    navigate(`?action=add`, { replace: true });
  };

  const handleEdit = (location: any) => {
    navigate(`?action=edit&id=${location.locationId}`);
    setSelectedLocation(location);
  };

  const handleUpdateRow = (updatedLocation: any) => {
    setRows((prevLocations: any[]) =>
      prevLocations.map((location: any) =>
        location.locationId === updatedLocation.locationId
          ? { ...location, ...updatedLocation }
          : location
      )
    );
  };

  const handleDelete = (location: any) => {
    setLocationToDelete(location);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (locationToDelete) {
      try {
        const response = await deleteLocation(locationToDelete.locationId);
        setRows((prevRows) =>
          prevRows.filter(
            (row: any) => row.locationId !== locationToDelete.locationId
          )
        );
        showToast(response.message);
        setIsDeleteModalOpen(false);
      } catch (error) {
        console.error("Failed to delete location:", error);
      }
    }
  };

  const handleCloseModal = () => {
    navigate(location.pathname);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  const rowsDisplay = rows.map((item, i) => ({
    ...item,
    country: item.country.countryName,
    city: item.city.cityName,
    state: item.state.stateName,
    id: (currentPage - 1) * itemsPerPage + i + 1,
  }));
  return (
    <React.Fragment>
      <Card
        headerText="Location List"
        headerBtnText="Add New"
        tableDataLength={rows?.length}
        btnActions={[{ text: "Add New", onClick: () => handleAddNew }]}
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
      >
        <AddLocation
          isModalOpen={isModalOpen}
          handleClose={handleCloseModal}
          selectedLocation={selectedLocation}
          handleUpdateRow={handleUpdateRow}
          fetchData={fetchData}
          currentPage={currentPage}
        />

        <CustomTable
          columns={columns}
          data={rowsDisplay}
          rowActions={[
            {
              text: "Edit Details",
              onClick: (row: any) => handleEdit(row),
            },
            {
              text: "Delete Location",
              onClick: (row: any) => handleDelete(row),
            },
          ]}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </Card>

      <DeleteModal
        isOpen={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onDelete={handleDeleteConfirm}
      />
    </React.Fragment>
  );
};

const columns = [
  { key: "id", label: "Sr." },
  { key: "locationName", label: "Location Name" },
  { key: "address", label: "Address" },
  { key: "country", label: "Country" },
  { key: "city", label: "City" },
  { key: "zipCode", label: "ZIP Code" },
  { key: "state", label: "State" },
];
