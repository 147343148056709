import React, { useState } from "react";
import {
  Card,
  Typography,
  Grid,
  IconButton,
  Box,
  useTheme,
  Pagination,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CircleIcon from "@mui/icons-material/Circle";
import { getStatusDotColor, getStatusStyle } from "../../utils/getStatusStyle";

interface Column {
  key: string;
  label: string;
}

interface Row {
  id: number;
  [key: string]: string | number;
}

interface RowAction {
  text: string;
  onClick: (row: Row) => void;
}

interface CustomGridCardProps {
  columns: Column[];
  row: Row[];
  rowActions?: RowAction[];
  currentPage?: number;
  totalPages?: number;
  onPageChange?: (event: React.ChangeEvent<unknown>, page: number) => void; 
}

export const CustomGridCard: React.FC<CustomGridCardProps> = ({
  columns,
  row,
  rowActions,
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<Row | null>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, row: Row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleActionClick = (action: RowAction) => {
    if (selectedRow) {
      action.onClick(selectedRow);
      handleMenuClose();
    }
  };

  const filteredColumns = columns.filter(
    (item) =>
      item.label?.toLowerCase() !== "sr." &&
      item.label?.toLowerCase() !== "status"
  );

  const groupedColumns = [
    filteredColumns.slice(0, 2),
    filteredColumns.slice(2, 5),
    filteredColumns.slice(5),
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        {row.map((row) => {
          const status = row.status as string;
          const dotColor = getStatusDotColor(status); 
          const statusStyle = getStatusStyle(status); 

          return (
            <Grid item xs={6} key={row.id}>
              <Card
                variant="outlined"
                sx={{
                  borderRadius: "10px",
                  padding: "24px 14px",
                  border: `1px solid ${theme.palette.divider}`,
                  marginBottom: "16px",
                }}
              >
                <Grid container spacing={2}>
                  {groupedColumns.map((group, groupIndex) => (
                    <Grid item xs={12} key={groupIndex}>
                      <Grid container spacing={2}>
                        {group.map((column) => (
                          <Grid item xs={4} key={column.key}>
                            <Typography
                              variant="subtitle2"
                              sx={{ color: "#6E7793" }}
                            >
                              {column.label}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                color: "#090909",
                                fontWeight:
                                  column.label === "Calculated Emission"
                                    ? "bold"
                                    : "normal",
                              }}
                            >
                              {row[column.key]}
                            </Typography>
                          </Grid>
                        ))}
                        {groupIndex === 0 && (
                          <Grid item xs={4}>
                            <Box display="flex" alignItems= "center" >
                              <CircleIcon
                                sx={{
                                  color: dotColor,
                                  fontSize: "10px",
                                  marginRight: "8px",
                                }}
                              />
                              <Typography
                                variant="body1"
                                sx={{
                                  fontSize: "12px",
                                  ...statusStyle,
                                }}
                              >
                                {status}
                              </Typography>
                              {rowActions && rowActions.length > 0 && (
                                <Grid item xs={12} textAlign="right">
                                  <Box display="flex" justifyContent="flex-end">
                                    <IconButton
                                      aria-label="more options"
                                      onClick={(event) =>
                                        handleMenuOpen(event, row)
                                      }
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </Box>
                                </Grid>
                              )}
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      
      <Pagination
            sx={{
              mt: 3,
              mx: "auto",
              display: "flex",
              justifyContent: "center",
            }}
            color="secondary"
            shape="rounded"
            count={totalPages} 
            page={currentPage} 
            onChange={onPageChange} 
            showFirstButton
            showLastButton
          />

      {rowActions && rowActions.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {rowActions.map((action, index) => (
            <MenuItem key={index} onClick={() => handleActionClick(action)}>
              {action.text}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  );
};
