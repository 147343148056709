import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { ChevronDown, InfoCircleICon } from "../../../../../icons";
import {
  CustomButton,
  CustomHeading,
  CustomModal,
  CustomSubHeading,
  CustomTextField,
} from "../../../../../ui";
import { useEffect, useState } from "react";
import {
  calculateFugitiveEmission,
  CalculateFugitiveEmissionData,
  createFugitive,
  getEmissionPoint,
  getFugitiveEmisionById,
  getFugitiveEmissionFactor,
  getFugitiveSource,
  updateFugitiveEmission,
} from "../../../../../api/fugitive-emission-api";
import { showToast } from "../../../../../utils/showToast.util";
import { useFugitiveEmission } from "./useFugitiveEmission";

const schema = z.object({
  source: z.string().min(1, "Source is required"),
  emissionFrequency: z
  .preprocess(
    (val) => (val === "" ? null : Number(val)),
    z.number().nullable() 
  )
  .refine((val) => val !== null && val > 0, {
    message: "Emission Frequency must be a positive number",
  })
  .refine((val) => val !== null && val <= 15, {
    message: "Frequency cannot exceed 15 times per month",
  }),
  duration: z
    .preprocess(
      (val) => (val === "" ? null : Number(val)),
      z.number().nullable()
    )
    .refine((val) => val !== null && val > 0, {
      message: "Emission Frequency must be a positive number",
    })
    .refine((val) => val !== null && val <= 15, {
      message: "Duration cannot exceed 15 times per month",
    }),
  potentialEmissionPoint: z
    .string()
    .min(1, "Potential Emission Point is required"),
  emissionFactor: z
    .preprocess(
      (val) => (val === "" ? null : Number(val)),
      z.number().nullable()
    )
    .refine((val) => val !== null && val > 0, {
      message: "Emission Factor must be a positive number",
    })
    .refine(
      (val) => val === null || val.toString().length >= 3,
      "Minimum 3 digits are required"
    )
    .refine(
      (val) => val === null || val.toString().length <= 15,
      "Maximum 15 digits are allowed"
    )
    .refine(
      (val) => val === null || /^\d+(\.\d{1,3})?$/.test(val.toString()),
      "Decimal places up to 3 are allowed"
    ),
  dataCollectionMethod: z.string().min(1, "Data Collection Method is required"),
  protocol: z.string().min(1, "Protocol is required"),
  reason: z
    .string()
    .optional()
    .refine((val) => !val || (val.length >= 3 && val.length <= 50), {
      message: "Reason must be between 3 and 50 characters if provided",
    }),
});

type FormData = z.infer<typeof schema>;

interface AddEditFugitiveEmissionProps {
  fugitiveEmissionId: any;
  handleRowUpdate: any;
  onFugitiveEmissionData: (data: any[]) => void;
  fetchFugitiveEmissionData: any;
  currentPage: number;
  fetchUploadedFiles: any;
}

const AddEditFugitiveEmission: React.FC<AddEditFugitiveEmissionProps> = ({
  fugitiveEmissionId,
  handleRowUpdate,
  onFugitiveEmissionData,
  fetchFugitiveEmissionData,
  currentPage,
  fetchUploadedFiles,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const isEditing = searchParams.get("action") === "edit";
  const modalTitle = isEditing ? "Edit Emission" : "Add Data Manually";
  const isModalOpen = isEditing || searchParams.get("action") === "add";

  const [fugitiveSource, setFugitiveSource] = useState<any[]>([]);
  const [emissionPoints, setEmissionPoints] = useState<any[]>([]);
  const [calculatedEmission, setCalculatedEmission] = useState<any | number>(
    null
  );
  const [unitName, setUnitName] = useState<string>("");
  const [emissionFactorId, setEmissionFactorId] = useState<any | number>(null);

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      source: "",
      emissionFrequency: null,
      duration: null,
      emissionFactor: null,
      potentialEmissionPoint: "",
      dataCollectionMethod: "",
      protocol: "",
      reason: "",
    },
  });

  useEffect(() => {
    if (!isEditing) {
      reset({
        source: "",
        emissionFrequency: null,
        duration: null,
        emissionFactor: null,
        potentialEmissionPoint: "",
        dataCollectionMethod: "",
        protocol: "",
        reason: "",
      });
      setCalculatedEmission(null);
    }
  }, [isEditing, isModalOpen]);

  const { watch, setValue, reset } = methods;
  const selectedFugitiveSourceId = watch("source");
  const selectedEmissionPointId = watch("potentialEmissionPoint");

  useEffect(() => {
    const fetchFugitiveSource = async () => {
      try {
        const data = await getFugitiveSource();
        setFugitiveSource(data);
      } catch (error) {
        console.error("Fetch Fugitive Source Emission Failed!", error);
      }
    };
    if (!fugitiveSource.length) {
      fetchFugitiveSource();
    }
  }, [fugitiveSource]);

  useEffect(() => {
    const fetchEmissionPoints = async () => {
      if (selectedFugitiveSourceId) {
        try {
          const emissionPointsData = await getEmissionPoint(
            Number(selectedFugitiveSourceId)
          );
          setEmissionPoints(emissionPointsData);
        } catch (error) {
          console.error("Error fetching emission points:", error);
        }
      }
    };

    if (selectedFugitiveSourceId.length) {
      fetchEmissionPoints();
    }
  }, [selectedFugitiveSourceId]);

  useEffect(() => {
    const fetchEmissionFactor = async () => {
      if (selectedFugitiveSourceId && selectedEmissionPointId) {
        try {
          const response = await getFugitiveEmissionFactor({
            fugitiveEmissionSourceId: Number(selectedFugitiveSourceId),
            emissionPointId: Number(selectedEmissionPointId),
          });
          setEmissionFactorId(response.fugitiveEmissionFactorId);
          setValue("emissionFactor", response.factor);
        } catch (error) {
          console.error("Failed to fetch emission factor:", error);
        }
      }
    };
    fetchEmissionFactor();
  }, [selectedFugitiveSourceId, selectedEmissionPointId, setValue]);

  const calculateEmission = async (data: FormData) => {
    try {
      const payload: CalculateFugitiveEmissionData = {
        frequency: Number(data.emissionFrequency),
        duration: Number(data.duration),
        fugitiveEmissionFactorId: Number(emissionFactorId),
        measuringValue: Number(data.emissionFactor),
      };
      const response = await calculateFugitiveEmission(payload);
      setCalculatedEmission(response.data.calculatedEmission);
      setUnitName(response.data.unitName);
      
    } catch (error: any) {
      console.error("Calculate Emission failed", error);
      showToast(error, "error");
    }
  };

  const fetchFugitiveEmissionById = async () => {
    try {
      const data = await getFugitiveEmisionById(fugitiveEmissionId);
      reset({
        source:
          data?.data.fugitiveEmissionSource?.fugitiveEmissionSourceId.toString() ||
          "",
        potentialEmissionPoint:
          data?.data.emissionPointDto.emissionPointId.toString() || "",
        emissionFactor:
          data?.data.fugitiveEmissionFactor?.fugitiveEmissionFactorId.toString() ||
          "",
        emissionFrequency: data.data?.frequencyOfEvent,
        duration: data.data?.durationOfEvent,
        protocol: data?.data.protocol || "",
        dataCollectionMethod: data?.data.fugitiveDataCollectionMethod || "",
        reason: data?.data.reason || "",
      });

      setCalculatedEmission(data?.data.calculatedEmission || null);
    } catch (error) {
      console.error("Fetch Fugitive Emission Failed!", error);
    }
  };

  useEffect(() => {
    if (fugitiveEmissionId > 0 && isEditing ) {
      fetchFugitiveEmissionById();
    }
  }, [fugitiveEmissionId, isEditing]);

  const { mutate: createFugitive } = useFugitiveEmission({
    onSuccess: (data) => {
      const { message } = data;
      showToast(message);
      fetchFugitiveEmissionData(currentPage, 8);
      fetchUploadedFiles(currentPage, 8);
      handleClose();
    },
    onError: (error: any) => {
      showToast(error, "error");

      
    },
  });

  const onSubmit = async (data: FormData) => {
    try {
      const payload = {
        fugitiveEmissionSourceId: Number(data.source),
        emissionPointId: Number(data.potentialEmissionPoint),
        fugitiveEmissionFactorId: emissionFactorId,
        frequency: data.emissionFrequency || 0,
        duration: data.duration || 0,
        protocol: data.protocol.toUpperCase() || "",
        fugitiveDataCollectionMethod:
          data.dataCollectionMethod.toUpperCase() || "",
        reason: data.reason ? data.reason : "",
      };
      if (isEditing) {
        const response = await updateFugitiveEmission(
          fugitiveEmissionId,
          payload
        );
        if (handleRowUpdate) {
          const updatedRow = {
            fugitiveEmissionId,
            fugitiveEmissionSourceId: Number(data.source),
            emissionPointId: Number(data.potentialEmissionPoint),
            fugitiveEmissionFactorId: Number(data.emissionFactor),
            frequency: data.emissionFrequency,
            duration: data.duration,
            protocol: data.protocol.toUpperCase() || "",
            fugitiveDataCollectionMethod: data.dataCollectionMethod || "",
            reason: data.reason ? data.reason : "",
          };
          showToast(response.message);
          fetchFugitiveEmissionData(currentPage, 8);
          handleRowUpdate(updatedRow);
        }
      } else {
        await createFugitive(payload);
        
      }
      handleClose();
    } catch (error: any) {
      console.error("Error:", error);
      showToast(error, "error");
    }
  };

  const handleClose = () => {
    methods.reset();
    navigate(location.pathname, { replace: true });
  };

  const fields = [
    {
      name: "source",
      label: "Source",
      placeholder: "Select Fugitive Emissions",
      select: true,
      icon: <ChevronDown />,
      options: fugitiveSource.map((source) => ({
        value: source.fugitiveEmissionSourceId.toString(),
        label: source.name,
      })),
      col: 12,
      onChange: (e: any) => {
        setValue("potentialEmissionPoint", "");
      },
    },
    {
      name: "emissionFrequency",
      label: "Emission Frequency",
      placeholder: "Enter Emission Frequency",
      type: "number",
      col: 6,
    },
    {
      name: "duration",
      label: "Duration",
      placeholder: "Enter Duration",
      icon: "Min",
      type: "number",
      col: 6,
    },

    {
      name: "potentialEmissionPoint",
      label: "Potential Emission Point",
      placeholder:
        emissionPoints.length > 0
          ? emissionPoints[0].emissionPointName.toLowerCase()
          : "",
      select: true,
      icon: <ChevronDown />,
      options: emissionPoints.map((point) => ({
        value: point.emissionPointId.toString(),
        label: point.emissionPointName,
      })),
      col: 6,
    },
    {
      name: "emissionFactor",
      label: "Emission Factor",
      placeholder: "Enter Emission Factor",
      icon: "Kwh",
      type: "number",
      disabled: true,
      col: 6,
    },
    {
      name: "dataCollectionMethod",
      label: "Data Collection",
      placeholder: "Select Collection Method",
      icon: <ChevronDown />,
      select: true,
      options: [
        {
          value: "Regular Inspection for leaks",
          label: "Regular Inspection for leaks",
        },
        {
          value: "Monitoring Equipment for venting events",
          label: "Monitoring Equipment for venting events",
        },
        { value: "Recording Observations", label: "Recording Observations" },
      ],
      col: 6,
    },
    {
      name: "protocol",
      label: "Protocol",
      placeholder: "Select Protocol",
      select: true,
      icon: <ChevronDown />,
      options: [
        { value: "csrd", label: "CSRD" },
        { value: "secr", label: "SECR" },
      ],
      col: 6,
    },
  ];

  const extraFields = isEditing
    ? [
        {
          name: "reason",
          label: "Reason To edit",
          placeholder: "Enter The Reason why you want to edit Emission",
          type: "textarea",
          col: 12,
        },
      ]
    : [];

  const finalFields = fields.concat(extraFields);

  return (
    <CustomModal
      title={modalTitle}
      open={isModalOpen}
      onClose={handleClose}
      maxWidth="sm"
      actions={[
        { text: "Discard", onClick: handleClose, bgColor: "transparent" },
        {
          text: isEditing ? "Update Emission" : "Save Emission",
          type: "submit",
          onClick: () => {
            methods.handleSubmit(onSubmit)();
          },
        },
      ]}
    >
      <Box mt={3}>
        <CustomHeading text="Fugitive Emissions" variant="h5" />
        <CustomSubHeading text="Please Provide the Following details to Calculate the Fugitive Emissions" />
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={3} mt={0}>
            {finalFields.map((field, index) => (
              <Grid item xs={12} sm={field.col} key={index}>
                <CustomTextField {...field} />
              </Grid>
            ))}
          </Grid>
        </form>
      </FormProvider>
      <Box mt={3}>
        <CustomButton
          fullWidth
          text="Calculate Emission"
          onClick={methods.handleSubmit(calculateEmission)}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
            mb: 4,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "#090909",
              fontFamily: "PoppinsMedium",
              display: "flex",
              alignItems: "center",
              gap: 1,
              lineHeight: 1,
            }}
          >
            Emission Calculated
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: "#090909",
              fontSize: 18,
              fontWeight: 600,
              lineHeight: 1,
            }}
          >
            {calculatedEmission !== null ? `${calculatedEmission} ${unitName}` : ""}
          </Typography>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default AddEditFugitiveEmission;
