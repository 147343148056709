import * as React from "react";

function WindIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2639 6.5C17.8132 5.88625 18.6115 5.5 19.5 5.5C21.1569 5.5 22.5 6.84315 22.5 8.5C22.5 10.1569 21.1569 11.5 19.5 11.5H13.5M7.2639 4C7.81322 3.38625 8.6115 3 9.5 3C11.1569 3 12.5 4.34315 12.5 6C12.5 7.65685 11.1569 9 9.5 9H2.5M11.2639 20C11.8132 20.6137 12.6115 21 13.5 21C15.1569 21 16.5 19.6569 16.5 18C16.5 16.3431 15.1569 15 13.5 15H2.5"
        stroke="#8E98A8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default WindIcon;
