import React, { FC, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { CustomButton } from "../../../../../ui";
import PlusIcon from "../../../../../icons/PlusIcon";
import { useLocationContext } from "../../../../../context/location-context";
import EmissionsAddedTable from "../../scope2-emission/emission-table/emissions-added-table";
import Scope1LocationAccordion from "../../../../../ui/scop1-location-accordion/scope1-location-accordion";
import { deleteScope1LocationEmission, getScope1LocationEmission, getScope1LocationPreferences, saveScope1LocationEmission } from "../../../../../api/scope1-location-api";
import { showToast } from "../../../../../utils/showToast.util";


interface LocationEmissionProps {
  setIsConfigurationTabsView?: any;
  locationId?: any;
}

const LocationEmissions: FC<LocationEmissionProps> = ({
  setIsConfigurationTabsView,
  locationId,
}) => {
  const { scope1locationPreferences, setScope1LocationPreferences } = useLocationContext();
  const [locationEmissionType, setLocationEmissionType] = useState<any>("");
  const [emissionData, setEmissionData] = useState<any[]>([]); 
  const [scope1LocationEmissionData, setScope1LocationEmissionData] = useState<any[]>([]);

  const methods = useForm({
    defaultValues: {
      gridElectricityAmount: "",
      onSiteRenewablesAmount: "",
    },
  });
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await getScope1LocationPreferences(locationId);
        setScope1LocationPreferences(response.data);
        
      } catch (error: any) {
        console.error("Error fetching initial data:", error);
        showToast(error, "error");
      }
    };
    fetchInitialData();
    fetchLocationData();
  }, [locationId, locationEmissionType]);

  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);

  const columns = [
    { key: "facilityName", label: "Facility Name", flexBasis: "40%" },
    { key: "measuringValue", label: "Amount", flexBasis: "15%" },
    { key: "emissions", label: "Emissions", flexBasis: "15%" },
    { key: "actions", label: "Actions", flexBasis: "15%" },
  ];

  const columns1 = [
    { key: "locationEmissionType", label: "Facility Name"},
    { key: "measuringValue", label: "Amount" },
    {key: "createdDate", label: "Created At"},
    { key: "calculatedEmission", label: "Emissions" },
  ];

  const rows = [
    {
      facilityName: "Stationary Combustion",
      amount: "0.00",
      emissions: "0000",
      actions: "",
      detailsType: "stationaryCombustion",
    }
  ];

  const handleConfiguration = () => {
    setIsConfigurationTabsView({ show: true, active: undefined });
  };

    const fetchLocationData = async () => {
      try {
        const response = await getScope1LocationEmission(locationId);
        setScope1LocationEmissionData(response.data);
      } catch (error: any) {
        console.error("Error fetching initial data:", error);
        showToast(error, "error");  
      }
    };

  const onSubmit = async (emission: any) => {
    try {
      const emissionLocationData = emissionData.map((value: any) => ({
        locationEmissionType: value.scopeOneLocationEmissionType,
        measuringValue: Number(value.measuringValue),
        protocol: "CSRD",
      }));

      const response = await saveScope1LocationEmission({
        locationId: Number(locationId),
        data: emissionLocationData,
      });
      setEmissionData(response.data); 
      showToast(response.message);
      await fetchLocationData();
  
    } catch (error: any) {
      console.error("Error saving building emission:", error);
      showToast(error, "error");
    }
  };

  const handleAddNew = () => {
    setExpandedRowIndex((prevIndex) => (prevIndex === 0 ? null : 0));
  };

  const handleLocationEmissionTypeChange = (type: string) => {
    setLocationEmissionType(type);
  };

  const handleEmissionsUpdate = (updatedEmissions: any) => {
    setEmissionData(updatedEmissions);
  }; 
  const handleDeleteEmission = async (emissionId: number) => {
    try {
      const response = await deleteScope1LocationEmission(emissionId); 
      setScope1LocationEmissionData((prevData) =>
        prevData.filter((item) => item.locationEmissionId !== emissionId)
      );
      showToast(response.message);
    } catch (error) {
      showToast("Error deleting emission", "error");
    }
  };
  

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box display="flex" justifyContent="flex-end" gap={2} padding={2}>
          <CustomButton
            text="Configuration"
            bgColor="transparent"
            textColor="#344054"
            rounded="8px"
            variant="outlined"
            onClick={handleConfiguration}
            sx={{
              borderColor: "#D0D5DD",
              outlineColor: "#EAECF0",
              color: "#344054",
              "&:hover": {
                borderColor: "transparent",
                backgroundColor: "#FFF",
              },
            }}
          />
          <CustomButton
            text="Add New"
            bgColor="transparent"
            textColor="#000"
            icon={PlusIcon}
            iconPosition="end"
            rounded="8px"
            onClick={() => handleAddNew()}
            sx={{
              backgroundColor: "#FFDE59",
              color: "#000",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          />
        </Box>

        {/* {scope1locationPreferences && scope1locationPreferences !== "" ? ( */}
          <>
            <Box sx={{ margin: "14px 18px" }}>
              <Scope1LocationAccordion
                columns={columns}
                rows={rows}
                expandedRowIndex={expandedRowIndex}
                onExpandRow={setExpandedRowIndex}
                onEmissionsUpdate={handleEmissionsUpdate}
                onEmissionTypeChange={handleLocationEmissionTypeChange}
                locationId={locationId}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <CustomButton
                text="Cancel"
                bgColor="transparent"
                rounded="8px"
                variant="outlined"
                onClick={() => {}}
                sx={{
                  borderColor: "#D0D5DD",
                  outlineColor: "#EAECF0",
                  margin: "8px 16px",
                  marginRight: "2px",
                  padding: "10px",
                  color: "#344054",
                  "&:hover": {
                    borderColor: "transparent",
                    backgroundColor: "#FFF",
                  },
                }}
              >
                Cancel
              </CustomButton>

              {/* Save Button */}
              <CustomButton
                type="submit"
                text="Save"
                textColor="#000"
                iconPosition="end"
                rounded="8px"
                sx={{
                  margin: "8px 16px",
                  padding: "10px",
                  backgroundColor: "#FFDE59",
                  color: "#000",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                Save
              </CustomButton>
            </Box>
          </>
        

        {/* Emissions Table */}
        <Box sx={{ marginBottom: "2px" }}>
          <EmissionsAddedTable
            columns={columns1}
            rows={scope1LocationEmissionData}
            emissionType={locationEmissionType}
            tableType= {"Location"}
            rowActions={[
              {
                text: "Delete Emission",
                onClick: (row:any) => handleDeleteEmission(row.locationEmissionId),
              },
            ]}
          />
          
        </Box>
      </form>
    </FormProvider>
  );
};

export default LocationEmissions;
