import {
  QueryClient,
  QueryCache,
  MutationCache,
  DefaultOptions,
  UseQueryOptions,
  UseMutationOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

const queryConfig: DefaultOptions = {
  queries: {
    retry: 3,
    retryDelay: 1000,
    staleTime: 60 * 1000 * 5,
  },
};

const queryCache = new QueryCache({
  onError: (error) => {
    console.log("Query error:", error);
  },
});

const mutationCache = new MutationCache({
  onError: (error) => {
    console.log("Mutation error:", error);
  },
});

export const queryClient = new QueryClient({
  defaultOptions: queryConfig,
  queryCache,
  mutationCache,
});

export type QueryConfig<FetcherFnType extends (...args: any) => any> =
  UseQueryOptions<Awaited<ReturnType<FetcherFnType>>>;

export type MutationConfig<FetcherFnType extends (...args: any) => any> =
  UseMutationOptions<
    Awaited<ReturnType<FetcherFnType>>,
    AxiosError,
    Parameters<FetcherFnType>[0]
  >;
