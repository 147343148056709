import React from "react";
import { Button, ButtonProps, styled, CircularProgress } from "@mui/material";

interface CustomButtonProps extends ButtonProps {
  loading?: boolean;
  icon?: React.ElementType;
  iconPosition?: "start" | "end";
  text?: string;
  bgColor?: string;
  textColor?: string;
  rounded?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  loading = false,
  icon: Icon,
  iconPosition = "start",
  text,
  bgColor,
  rounded,
  textColor,
  children,
  onClick = () => {},
  type = "button",
  ...rest
}) => {
  const renderButtonContent = () => (
    <>
      {loading && (
        <CircularProgress size={20} color="inherit" sx={{ marginRight: 1 }} />
      )}
      {Icon && iconPosition === "start" && (
        <span className="button-icon">
          <Icon />
        </span>
      )}
      {text || children}
      {Icon && iconPosition === "end" && (
        <span className="button-icon">
          <Icon />
        </span>
      )}
    </>
  );

  return (
    <StyledButton
      {...rest}
      disabled={loading || rest.disabled}
      $bgColor={bgColor}
      $textColor={textColor}
      $rounded={rounded}
      onClick={onClick}
      type={type}
    >
      {renderButtonContent()}
      
    </StyledButton>
  );
};

export default CustomButton;

interface StyledButtonProps {
  $bgColor?: string;
  $textColor?: string;
  $rounded?: string;
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    !["$bgColor", "$textColor", "$rounded"].includes(prop as string),
})<StyledButtonProps>(({ theme, $bgColor, $textColor, $rounded }) => ({
  borderRadius: $rounded || "8px",
  backgroundColor: $bgColor || theme.palette.primary.main,
  color: $textColor || theme.palette.primary.contrastText,
  fontSize: "14px",
  fontFamily: "PoppinsMedium",
  padding: "13px",
  outline:
    $bgColor === "trasnparent"
      ? "1px solid #B8BABE"
      : `1px solid ${theme.palette.primary.main}`,
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  "&:hover": {
    outline: "1px solid #B8BABE",
    backgroundColor: "#FFF",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  },
  "&:disabled": {
    backgroundColor: "#EAECF0",
    outline: "0px",
    color: "#0C0C0C",
    cursor: "not-allowed",
    pointerEvents: "all !important",
  },
  "& .button-icon": {
    display: "inline-flex",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));
