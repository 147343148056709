import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  emissionCategory,
  emissionSubCategory,
} from "../../../../api/emissions-api";
import {
  CustomHeading,
  CustomModal,
  CustomSubHeading,
  CustomTextField,
} from "../../../../ui";
import { ChevronDown } from "../../../../icons";
import { getAsset } from "../../../../api/assets-api";

interface CreateEmissionProps {
  onAddEmission: (newEmission: any) => void;
}

const schema = z.object({
  sourceName: z.string().min(1, "Source Name is required").max(255),
  controlStatus: z.enum([
    "Owned",
    "Leased",
    "Jointly Owned",
    "Subcontracted",
    "Other",
  ]),
  category: z.string().min(1, "Category is required"),
  subCategory: z.string().min(1, "Sub-category is required"),
  asset: z.string().min(1, "Asset is required"),
  scope: z.enum(["Scope 1", "Scope 2", "Scope 3"]),
  protocol: z.enum(["CSRD", "SECR"]),
  description: z.string().max(500).optional(),
});

type FormData = z.infer<typeof schema>;

const CreateEmission: FC<CreateEmissionProps> = ({ onAddEmission }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [categories, setCategories] = useState<any[]>([]);
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [assets, setAssets] = useState<any[]>([]);
  const [hasFetched, setHasFetched] = useState<boolean>(false);

  const itemsPerPage = 10;
  const isModalOpen =
    searchParams.get("action") === "add" ||  
    searchParams.get("action") === "create" ||
    searchParams.get("action") === "edit";

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      sourceName: "",
      controlStatus: "Owned",
      category: "",
      subCategory: "",
      asset: "",
      scope: "Scope 1",
      description: "",
      protocol: undefined,
    },
  });

  const selectedProtocol = methods.watch("protocol");

  const onSubmit = (data: FormData) => {
    const processedData = {
      sourceName: data.sourceName,
      description: data.description,
      controlStatus: data.controlStatus,
      categoryId: data.category,
      subCategoryId: data.subCategory,
      assetId: data.asset,
      scope: data.scope,
      protocol: data.protocol,
    };
    onAddEmission(processedData);
    handleClose();
  };

  const handleClose = () => {
    methods.reset();
    navigate(location.pathname, { replace: true });
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoryData = await emissionCategory();
        setCategories(categoryData?.data.data || []);
        setHasFetched(true);
      } catch (error: any) {
        console.error("Failed to fetch categories");
      }
    };

    if (!hasFetched) {
      fetchCategories();
    }
  }, [hasFetched]);

  const selectedCategory = methods.watch("category");
  useEffect(() => {
    if (selectedCategory) {
      methods.setValue("subCategory", "");
      const fetchSubCategories = async () => {
        try {
          const subCategoryData = await emissionSubCategory(
            Number(selectedCategory)
          );
          setSubCategories(subCategoryData?.data || []);
        } catch (error: any) {
          console.error("Failed to fetch subcategories");
        }
      };
      fetchSubCategories();
    }
  }, [selectedCategory]);

  useEffect(() => {
    const fetchAsset = async (page: number = 1) => {
      try {
        const assetData = await getAsset({
          countries: [],
          page: page - 1,
          size: itemsPerPage,
          sort: "createdDate,desc",
        });
        setAssets(assetData.data);
      } catch (error: any) {
        console.error("Failed to fetch asset", error);
      }
    };

    fetchAsset();
  }, []);

  useEffect(() => {
    if (selectedProtocol === "CSRD") {
      methods.setValue("category", "");
      methods.setValue("subCategory", "");
    }
  }, [selectedProtocol, methods]);

  const fields = [
    {
      name: "sourceName",
      label: "Source Name",
      placeholder: "Enter the source name",
      col: 6,
    },
    {
      name: "controlStatus",
      label: "Ownership/Control Status",
      placeholder: "Select ownership/control status",
      col: 6,
      select: true,
      icon: <ChevronDown />,
      options: [
        { value: "Owned", label: "Owned" },
        { value: "Leased", label: "Leased" },
        { value: "Jointly Owned", label: "Jointly Owned" },
        { value: "Subcontracted", label: "Subcontracted" },
        { value: "Other", label: "Other" },
      ],
    },
    {
      name: "protocol",
      label: "Protocol",
      placeholder: "Select Protocol",
      col: 6,
      select: true,
      icon: <ChevronDown />,
      options: [
        { value: "CSRD", label: "CSRD" },
        { value: "SECR", label: "SECR" },
      ],
    },
    ...(selectedProtocol === "CSRD"
      ? [
          {
            name: "category",
            label: "Category",
            placeholder: "Select Category",
            col: 6,
            select: true,
            icon: <ChevronDown />,
            options: categories.map((category: any) => ({
              value: category.id.toString(),
              label: category.name,
            })),
          },
          {
            name: "subCategory",
            label: "Sub-category",
            placeholder: "Select Sub-category",
            col: 6,
            select: true,
            disabled: !selectedCategory,
            icon: <ChevronDown />,
            options: subCategories.map((subCategory: any) => ({
              value: subCategory.id.toString(),
              label: subCategory.name,
            })),
          },
        ]
      : []),
    ...(selectedProtocol === "SECR"
      ? [
          {
            name: "scope",
            label: "Scope",
            placeholder: "Select Scope",
            col: 6,
            select: true,
            icon: <ChevronDown />,
            options: [
              { value: "Scope 1", label: "Scope 1" },
              { value: "Scope 2", label: "Scope 2" },
              { value: "Scope 3", label: "Scope 3" },
            ],
          },
        ]
      : []),
    {
      name: "asset",
      label: "Asset",
      placeholder: "Select Asset",
      col: 6,
      select: true,
      icon: <ChevronDown />,
      options: assets.map((asset: any) => ({
        value: asset.assetId.toString(), 
        label: asset.assetName,
      })),
    },

    {
      name: "description",
      label: "Description",
      placeholder: "Please Enter Description",
      type: "textarea",
      col: 12,
    },
  ];

  return (
    <CustomModal
      title="Create Emission Source"
      open={isModalOpen}
      onClose={handleClose}
      actions={[
        { text: "Discard", onClick: handleClose, bgColor: "transparent" },
        {
          text: "Save",
          type: "submit",
          onClick: () => {
            methods.handleSubmit(onSubmit)();
          },
        },
      ]}
    >
      <Box mt={3}>
        <CustomHeading text="Create Emission Source" variant="h5" />
        <CustomSubHeading text="Please Provide the Following Details to Create New Emission Source!" />
      </Box>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={3} mt={0}>
            {fields
              .filter((field) => field !== null && field !== undefined) // Filter out null and undefined values
              .map((field, index) => (
                <Grid item xs={12} sm={field.col} key={index}>
                  <CustomTextField {...field} />
                </Grid>
              ))}
          </Grid>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default CreateEmission;
