import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import useStorage from "../utils/useStorage.util";


interface User {
  id?: string;
  name?: string;
  email?: string;
  firstName?: string;
  lastName?: string;  
  imageUrl?: string;
  [key: string]: unknown;
  token?: string;
}

interface UserContextType {
  user: User | null;
  updateUser: (newUser: User | null) => void;
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUserContext = (): UserContextType => {

  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(
    useStorage.getItem("planetPulseUser") || null
  );



  const [token, setToken] = useState<string | null>(() => 
    useStorage.getItem("planetPulseToken") || null
  );

  const updateUser = (newUser: User | null) => {
    setUser(newUser);
  };


  useEffect(() => {

    if (user) {
      useStorage.setItem("planetPulseUser", user);
    }
    if (token) {
      useStorage.setItem("planetPulseToken", token);
    }
  }, [user, token]);


  // useEffect(() => {
  //   useStorage.setItem("planetPulseUser", user);
  // }, [user, token]);



  const value: UserContextType = {
    user,
    updateUser,
    token,
    setToken,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
