import { Analytics } from "@mui/icons-material";
import React, { createContext, useContext, useState, ReactNode } from "react";


interface BuildingContextType {
  buildingPreferences: any;
  setBuildingPreferences: (value: any) => void;
  scope1BuildingPreferences: any;
  setScope1BuildingPreferences:(value:any)=>void;

}

const BuildingContext = createContext<BuildingContextType | undefined>(undefined);

export const BuildingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const[buildingPreferences, setBuildingPreferences] = useState<any[]>([]);
  const[scope1BuildingPreferences, setScope1BuildingPreferences] = useState<any[]>([]);

  return (
    <BuildingContext.Provider
      value={{
        buildingPreferences,
        setBuildingPreferences,
        scope1BuildingPreferences,
        setScope1BuildingPreferences,
      }}
    >
      {children}
    </BuildingContext.Provider>
  );
};

export const useBuildingContext = (): BuildingContextType => {
  const context = useContext(BuildingContext);
  if (!context) {
    throw new Error("useBuildingContext must be used within a BuildingProvider");
  }
  return context;
};
