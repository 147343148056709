import { axiosInstanceWithAuth } from "../utils/axios";

export interface CreateAssetData {
  newLocation: boolean;
  assetName: string;
  assetTypeId: number;
  locationId?: number;
  locationDetails?: {
    locationName: string;
    countryId: number;
    cityId: number;
    stateId: number;
    address: string;
    zipCode: string;
  };
}

export interface CreateAssetTypeData {
  name: string;
}

export interface GetAssetData {
  countries?: string[]; 
  search?: string;  
  page: number;           
  size: number;
  sort: string; 
  
}


export interface updateAssetData {
  newLocation: boolean;
  assetName: string;
  assetId: number;
  assetTypeId: number;
  locationId?: number;
  locationDetails?: {
    locationName: string;
    countryId: number;
    cityId: number;
    stateId: number;
    address: string;
    zipCode: string;
  };
    
}

export interface ErrorResponse {
  response?: {
    message?: string;
    status?: number;
    statusText?: string;
  };
  message?: string;
}


export const createAssetType = async (data: CreateAssetTypeData) => {
  try {
    const response = await axiosInstanceWithAuth.post("/asset-type");
    console.log("Create Asset Type Response:", response.data);
    return response.data;
  } catch (error: any) {
    console.error("Create Asset Type failed", error.response?.data || error.message);
    throw error.response?.data?.message || "Create Asset Type failed.";
  }
};


export const getAssetType = async () => {
  try {
    const response = await axiosInstanceWithAuth.get("/asset-type");
    return response.data;
  } catch (error: any) {
    console.error("Get Asset Type failed", error.response?.data || error.message);
    throw error.response?.data?.message || "Get Asset Type failed.";
  }
};

export const createAsset = async (data: CreateAssetData) => {
  try {
    const response = await axiosInstanceWithAuth.post(`/asset`, data);
    console.log("response of create asset", response.data)
    return response.data;
  } catch (error: any) {
    console.error("Create Asset failed", error.response?.data || error.message);
    throw error.response?.data?.message || "Create Asset failed.";
  }
};

export const getAsset = async (data: GetAssetData) => {
  try {
    const response = await axiosInstanceWithAuth.get('/asset', {
      params: {
        countries: data.countries,
        search: data.search,
        page: data.page,
        size: data.size,
        sort: data.sort,
      },
    });
    return response.data;
  } catch (error: any) {
    // Log detailed error information
    console.error("Get Asset data failed:", {
      message: error.message,
      status: error.response?.status,
      response: error.response?.data,
    });
    throw error.response?.data?.message || "Get Asset Data failed.";
  }
};


export const updateAsset = async (data: updateAssetData) => {
  try {
    const response = await axiosInstanceWithAuth.put(`/asset`, data); 
    console.log("response of update asset", response.data)
    return response.data;
  } catch (error: any) {
    console.error("Update Location failed", error.response?.data || error.message);
    throw error.response?.data?.message || "Update Location failed.";
  }
}; 

export const deleteAsset = async (assetId: number) => {
  try {
    console.log("Delete Asset ID:", assetId);
    const response = await axiosInstanceWithAuth.delete(`/asset/${assetId}`);
    console.log("Delete Asset Response:", response.data);
    return response.data;
  } catch (error: any) {
    console.error("Delete Asset failed", error.response?.data || error.message);
    throw error.response?.data?.message || "Delete Asset failed.";
  }
};








