import React from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import LocationIcon from "@mui/icons-material/LocationOn";

interface DeleteModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onDelete: () => void;
  isAsset?: true;
  isEmission?: true;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  isOpen,
  onCancel,
  onDelete,
  isAsset,
  isEmission,
}) => {
  const confirmationMessage = `Are you sure you want to delete this ${
    isEmission ? "emission" : isAsset ? "asset" : "location"
  }? Once deleted, it can’t be retrieved!`;
  

  return (
    <Modal open={isOpen} onClose={onCancel}>
      <Box
        sx={{
          width: 400,
          p: 3,
          mx: "auto",
          mt: "20%",
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: 24,
          position: "relative",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <LocationIcon sx={{ mr: "5px" }} />
          <Typography variant="h6" fontWeight="normal">
            {isEmission ? "Delete Emission" : isAsset ? "Delete Asset" : "Delete Location"}
          </Typography>
          <IconButton
            onClick={onCancel}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="body1" sx={{ mb: 2, color: "grey.100" }}>
          {confirmationMessage}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
          <Button
            onClick={onCancel}
            variant="outlined"
            sx={{ width: "45%", color: "#000", borderColor: "#EAECF0" }}
          >
            Cancel
          </Button>
          <Button
            onClick={onDelete}
            variant="contained"
            sx={{ width: "45%", backgroundColor: "#DA3E33", color: "#fff" }}
          >
            Yes, Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
