import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ForgotPasswordData, forgotPassword } from "../../../api/auth-api";
import { MutationConfig } from "../../../utils/queryClient";

export const useForgotPassword = (
  config?: MutationConfig<typeof forgotPassword>
): UseMutationResult<
  ReturnType<typeof forgotPassword>,
  AxiosError,
  ForgotPasswordData
> => {
  return useMutation({
    mutationFn: forgotPassword,
    ...config,
  });
};
