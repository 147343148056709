import * as React from "react";

function VerticalDotsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 11.334C10.9602 11.334 11.3333 10.9609 11.3333 10.5007C11.3333 10.0404 10.9602 9.66732 10.5 9.66732C10.0397 9.66732 9.66663 10.0404 9.66663 10.5007C9.66663 10.9609 10.0397 11.334 10.5 11.334Z"
        stroke="#0A0A0A"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 5.50065C10.9602 5.50065 11.3333 5.12755 11.3333 4.66732C11.3333 4.20708 10.9602 3.83398 10.5 3.83398C10.0397 3.83398 9.66663 4.20708 9.66663 4.66732C9.66663 5.12755 10.0397 5.50065 10.5 5.50065Z"
        stroke="#0A0A0A"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 17.1673C10.9602 17.1673 11.3333 16.7942 11.3333 16.334C11.3333 15.8737 10.9602 15.5007 10.5 15.5007C10.0397 15.5007 9.66663 15.8737 9.66663 16.334C9.66663 16.7942 10.0397 17.1673 10.5 17.1673Z"
        stroke="#0A0A0A"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default VerticalDotsIcon;
