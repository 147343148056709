import React, { createContext, useContext, useState, ReactNode } from "react";


interface ProfileContextType {
  isViewProfile: boolean;
  setIsViewProfile: (value: boolean) => void;
  profileData: any;
  setProfileData: (data: any) => void;
}

// Create the context
const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

// ProfileProvider component
export const ProfileProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isViewProfile, setIsViewProfile] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<any>(null);

  return (
    <ProfileContext.Provider
      value={{
        isViewProfile,
        setIsViewProfile,
        profileData,
        setProfileData,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

// Custom hook to use the ProfileContext
export const useProfileContext = (): ProfileContextType => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error("useProfileContext must be used within a ProfileProvider");
  }
  return context;
};
