import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { useLocation, useNavigate } from "react-router-dom";
import { ChevronDown } from "../../../../icons";
import {
  CustomHeading,
  CustomModal,
  CustomSubHeading,
  CustomTextField,
} from "../../../../ui";

const roles = [
  { label: "Admin", value: "admin" },
  { label: "User", value: "user" },
  { label: "Guest", value: "guest" },
  // Additional roles can be added here
];

const schema = z.object({
  firstName: z
    .string()
    .min(1, "First Name is required")
    .max(50, "First Name must be less than 50 characters"),
  lastName: z
    .string()
    .min(1, "Last Name is required")
    .max(50, "Last Name must be less than 50 characters"),
  email: z.string().min(1, "Email is required").email("Invalid email address"),
  role: z.string().min(1, "Role is required"),
});

type FormData = z.infer<typeof schema>;

const InviteUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const isModalOpen = searchParams.get("action") === "invite";

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      role: "",
    },
  });

  // const InviteUserMutation = useMutation({
  //   onSuccess: () => {
  //     showToast("User invited successfully!");
  //     methods.reset();
  //   },
  //   onError: (error) => {
  //     console.error("Error inviting user:", error);
  //     showToast(error?.message, "error");
  //   },
  // });

  const onSubmit = (data: FormData) => {};

  const handleClose = () => {
    methods.reset();
    navigate(location.pathname, { replace: true });
  };

  return (
    <CustomModal
      title="Invite User"
      open={isModalOpen}
      onClose={handleClose}
      actions={[
        { text: "Discard", onClick: handleClose, bgColor: "transparent" },
        {
          text: "Send Invite",
          type: "submit",
          onClick: () => {
            methods.handleSubmit(onSubmit)();
          },
        },
      ]}
    >
      <Box mt={3}>
        <CustomHeading text="Invite User" variant="h5" />
        <CustomSubHeading text="Please Provide the Following Details to Invite User to your system!" />
      </Box>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={3} mt={0}>
            {fields.map((field, index) => (
              <Grid item xs={12} sm={field.col} key={index}>
                <CustomTextField {...field} />
              </Grid>
            ))}
          </Grid>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

const fields = [
  {
    name: "firstName",
    label: "First Name",
    placeholder: "Enter User First Name",
    col: 6,
  },
  {
    name: "lastName",
    label: "Last Name",
    placeholder: "Enter User Last Name",
    col: 6,
  },
  {
    name: "email",
    label: "Email",
    placeholder: "Enter User Email address",
    col: 6,
  },
  {
    name: "role",
    label: "Role",
    placeholder: "Select user Role",
    icon: <ChevronDown />,
    select: true,
    options: roles,
    col: 6,
  },
];

export default InviteUser;
