import React, { createContext, useContext, useState, ReactNode } from "react";


interface LocationContextType {
  locationPreferences: any;
  setLocationPreferences: (value: any) => void;
  scope1locationPreferences: any;
  setScope1LocationPreferences: (value: any) => void;

}

// Create the context
const LocationContext = createContext<LocationContextType | undefined>(undefined);

// BuildingProvider component
export const LocationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const[locationPreferences, setLocationPreferences] = useState<any>("");
  const[scope1locationPreferences, setScope1LocationPreferences] = useState<any>("");

  return (
    <LocationContext.Provider
      value={{
        locationPreferences,
        setLocationPreferences,
        scope1locationPreferences,
        setScope1LocationPreferences
      
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

// Custom hook to use the BuildingContext
export const useLocationContext = (): LocationContextType => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error("useLocationContext must be used within a LocationProvider");
  }
  return context;
};
