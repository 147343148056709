import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid, Typography } from "@mui/material";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { ChevronDown } from "../../../../../icons";
import {
  CustomButton,
  CustomHeading,
  CustomModal,
  CustomSubHeading,
  CustomTextField,
} from "../../../../../ui";
import {
  calculateMobileEmission,
  CalculateMobileEmissionData,
  getMobileEmissionFactor,
  getMobileSourceEmissionById,
  getMobileSourceType,
  getMobileVehicleType,
  updateMobileSourceEmission,
} from "../../../../../api/mobile-source-api";
import { useEffect, useState } from "react";
import { useMobileSourceEmission } from "./useMobileSourceEmission";
import { showToast } from "../../../../../utils/showToast.util";

// Define your validation schema
const schema = z.object({
  typeOfVehicle: z.string().min(1, "Type of Vehicle is required"),
  vehicleQuantity: z
    .preprocess(
      (val) => (val === "" ? null : Number(val)),
      z.number().nullable()
    )
    .refine((val) => val !== null && val > 0, {
      message: "Vehicle Quantity must be a positive number",
    })
    .refine((val) => val !== null && val <= 15, {
      message: "Vehicle Quantity exceed 15 times per month",
    }),
  fuelConsumption: z
    .preprocess(
      (val) => (val === "" ? null : Number(val)),
      z.number().nullable()
    )
    .refine(
      (val) => val !== null && val > 0,
      "Fuel Consumption must be a positive number"
    ),
  fuelType: z.string().min(1, "Fuel Type is required"),
  distanceTravelled: z
    .preprocess(
      (val) => (val === "" ? null : Number(val)),
      z.number().nullable()
    )
    .refine(
      (val) => val !== null && val > 0,
      "Distance Travel must be a positive number"
    ),
  emissionFactor: z.preprocess(
    (val) => (val === "" ? null : Number(val)),
    z
      .number()
      .nullable()
      .refine((val) => val === null || val > 0, "Must be a positive number")
  ),
  mobileSourceDataCollectionMethod: z
    .string()
    .min(1, "Data Collection Method is required"),
  protocol: z.string().min(1, "Protocol is required"),
  unit: z.string().min(1, "Unit of Fuel Type is required"),
  reason: z
    .string()
    .optional()
    .refine((val) => !val || (val.length >= 3 && val.length <= 50), {
      message: "Reason must be between 3 and 50 characters if provided",
    }),
});

type FormData = z.infer<typeof schema>;
interface AddEditMobileEmissionPrps {
  onMobileSourceData: (data: any[]) => void;
  mobileSourceEmissionId: any;
  handleRowUpdate: any;
  fetchMobileEmissionData: any;
  currentPage: number;
  fetchUploadedFiles: any;
}

const AddEditMobileEmission: React.FC<AddEditMobileEmissionPrps> = ({
  mobileSourceEmissionId,
  onMobileSourceData,
  handleRowUpdate,
  fetchMobileEmissionData,
  currentPage,
  fetchUploadedFiles,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const isEditing = searchParams.get("action") === "edit";
  const modalTitle = isEditing ? "Edit Emission" : "Add Data Manually";
  const isModalOpen = isEditing || searchParams.get("action") === "add";

  const [vehicleTypes, setVehicleType] = useState<any[]>([]);
  const [sourceTypes, setSourceType] = useState<any[]>([]);
  const [units, setUnits] = useState<any[]>([]);
  const [calculatedEmissions, setCalculatedEmission] = useState<number | null>(
    null
  );
  const [unitName, setUnitName] = useState<string>("");
  const [emissionFactor, setEmissionFactor] = useState<number | null>(null);
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      typeOfVehicle: "",
      vehicleQuantity: null,
      fuelConsumption: null,
      fuelType: "",
      distanceTravelled: null,
      emissionFactor: null,
      mobileSourceDataCollectionMethod: "",
      protocol: "",
      unit: "",
      reason: "",
    },
  });

  const { setValue, watch, reset } = methods;
  const selectedFuelTypeId = watch("fuelType");
  const selectedVehicleTypeId = watch("typeOfVehicle");

  useEffect(() => {
    const fetchVehicleTypes = async () => {
      try {
        const data = await getMobileVehicleType();
        setVehicleType(data);
      } catch (error: any) {
        console.error(error, "error");
      }
    };
    fetchVehicleTypes();
  }, []);

  useEffect(() => {
    const fetchSourceTypes = async () => {
      try {
        const data = await getMobileSourceType();
        setSourceType(data.data);
      } catch (error: any) {
        console.error(error, "error");
      }
    };
    fetchSourceTypes();
  }, []);

  useEffect(() => {
    if (selectedFuelTypeId) {
      const selectedFuelType = sourceTypes.find(
        (source) =>
          source.mobileSourceFuelTypeId.toString() === selectedFuelTypeId
      );
      if (selectedFuelType) {
        setUnits(selectedFuelType.units);
      }
    }
  }, [selectedFuelTypeId, sourceTypes]);

  useEffect(() => {
    const fetchEmissionFactor = async () => {
      if (selectedVehicleTypeId && selectedFuelTypeId) {
        try {
          const response = await getMobileEmissionFactor({
            vehicleTypeId: parseInt(selectedVehicleTypeId),
            fuelTypeId: parseInt(selectedFuelTypeId),
          });
          setEmissionFactor(response.data.mobileSourceEmissionFactorId);
          setValue("emissionFactor", response.data.factor);
        } catch (error: any) {
          console.error(error, "error");
        }
      }
    };
    fetchEmissionFactor();
  }, [selectedFuelTypeId, selectedVehicleTypeId, setValue]);

  const { mutate: createMobileSource } = useMobileSourceEmission({
    onSuccess: (data) => {
      const { message } = data;
      showToast(message);
      fetchMobileEmissionData(currentPage, 8);
      handleClose();
    },
    onError: (error: any) => {
      console.error(error, "error");
      showToast(error, "error");
    },
  });

  useEffect(() => {
    if (isModalOpen && !isEditing) {
      // Reset the form when switching to create mode (not editing)
      reset({
        typeOfVehicle: "",
        vehicleQuantity: null,
        fuelConsumption: null,
        fuelType: "",
        distanceTravelled: null,
        emissionFactor: null,
        mobileSourceDataCollectionMethod: "",
        protocol: "",
        unit: "",
        reason: "",
      });
      setCalculatedEmission(null); // Reset calculated emission
    }
  }, [isModalOpen, isEditing, reset]);

  const fetchMobileEmission = async () => {
    try {
      const data = await getMobileSourceEmissionById(mobileSourceEmissionId);
      reset({
        fuelType: data?.data.fuelType?.mobileSourceFuelTypeId.toString(),
        typeOfVehicle: data?.data.typeOfVehicle.typeOfVehicleId.toString(),
        vehicleQuantity: data?.data.vehicleQuantity || null,
        unit: data?.data.fuelType.unit?.id.toString() || "",
        emissionFactor: data?.data.emissionFactor?.factor || null,
        fuelConsumption: data?.data.fuelConsumption,
        distanceTravelled: data.data.distanceTravelled,
        protocol: data?.data.prtocol || "",
        mobileSourceDataCollectionMethod:
          data?.data.mobileSourceDataCollectionMethod || "",
        reason: data?.data.reason || "",
      });
      setCalculatedEmission(data?.data.calculatedEmission || null);
    } catch (error: any) {
      console.error(error, "error");
    }
  };

  useEffect(() => {
    if (mobileSourceEmissionId > 0 && isEditing) {
      fetchMobileEmission();
    }
  }, [mobileSourceEmissionId, isEditing]);

  const calculateEmissions = async (data: FormData) => {
    try {
      const payload: CalculateMobileEmissionData = {
        fuelConsumption: Number(data.fuelConsumption),
        mobileSourceEmissionFactorId: Number(emissionFactor),
        distanceTraveled: Number(data.distanceTravelled),
      };
      const response = await calculateMobileEmission(payload);
      setCalculatedEmission(response.data.calculatedEmission);
      setUnitName(response.data.unit);
    } catch (error: any) {
      console.error(error, "error");
      showToast(error, "error");
    }
  };

  const onSubmit = async (data: FormData) => {
    try {
      const payload = {
        typeOfVehicleId: Number(data.typeOfVehicle),
        mobileSourceFuelTypeId: Number(data.fuelType),
        mobileSourceFuelUnitId: Number(data.unit),
        mobileSourceEmissionFactorId: emissionFactor || 0,
        vehicleQuantity: data.vehicleQuantity || 0,
        fuelConsumption: data.fuelConsumption || 0,
        distanceTraveled: Number(data.distanceTravelled),
        distanceTravelledUnit: "KILOMETERS",
        protocol: data.protocol.toUpperCase() || "",
        mobileSourceDataCollectionMethod: data.mobileSourceDataCollectionMethod,
        reason: data.reason ? data.reason : "",
      };

      if (isEditing) {
        const response = await updateMobileSourceEmission(
          mobileSourceEmissionId,
          payload
        );
        if (handleRowUpdate) {
          const updatedRow = {
            mobileSourceEmissionId,
            typeOfVehicleId: Number(data.typeOfVehicle),
            mobileSourceFuelTypeId: Number(data.fuelType),
            mobileSourceFuelUnitId: Number(data.unit),
            mobileSourceEmissionFactorId: emissionFactor || 0,
            vehicleQuantity: data.vehicleQuantity || 0,
            fuelConsumption: data.fuelConsumption || 0,
            distanceTraveled: Number(data.distanceTravelled),
            distanceTravelledUnit: "KILOMETERS",
            protocol: data.protocol.toUpperCase() || "",
            mobileSourceDataCollectionMethod:
              data.mobileSourceDataCollectionMethod,
            reason: data.reason ? data.reason : "",
          };
          showToast(response.message);
          handleClose();
          fetchMobileEmissionData(currentPage, 8);
          fetchUploadedFiles(currentPage, 8);
          handleRowUpdate(updatedRow);
        }
      } else {
        await createMobileSource(payload);
      }
      handleClose();
    } catch (error: any) {
      console.error("Error:", error);
      showToast(error, "error");
    }
  };

  const handleClose = () => {
    methods.reset();
    navigate(location.pathname, { replace: true });
  };

  const fields = [
    {
      name: "typeOfVehicle",
      label: "Type of Vehicle",
      placeholder: "Select Type of Vehicle",
      select: true,
      icon: <ChevronDown />,
      options: vehicleTypes.map((vehicle) => ({
        value: vehicle.typeOfVehicleId.toString(),
        label: vehicle.name,
      })),
      col: 12,
    },
    {
      name: "vehicleQuantity",
      label: "Vehicle Quantity",
      placeholder: "Enter No. of Vehicles",
      type: "number",
      col: 6,
    },
    {
      name: "fuelConsumption",
      label: "Fuel Consumption",
      placeholder: "Enter Consumption",
      icon: "Liter",
      type: "number",
      col: 6,
    },
    {
      name: "fuelType",
      label: "Fuel Type",
      placeholder: "Select Fuel Type",
      icon: <ChevronDown />,
      select: true,
      options: sourceTypes.map((source) => ({
        value: source.mobileSourceFuelTypeId.toString(),
        label: source.name,
      })),
      col: 6,
      onChange: (e: any) => {
        setValue("unit", "");
      },
    },
    {
      name: "unit",
      label: "Unit of Fuel Type",
      placeholder: "Select Unit",
      icon: <ChevronDown />,
      select: true,
      options: units.map((unit) => ({
        value: unit.id.toString(),
        label: unit.unitName,
      })),
      col: 6,
    },
    {
      name: "distanceTravelled",
      label: "Travel Distance",
      placeholder: "Enter Distance",
      type: "number",
      icon: "Km",
      col: 6,
    },
    {
      name: "emissionFactor",
      label: "Emission Factor",
      placeholder: "0.1234",
      disabled: true,
      type: "number",
      col: 6,
    },
    {
      name: "protocol",
      label: "Protocol",
      placeholder: "Select Protocol",
      select: true,
      icon: <ChevronDown />,
      options: [
        { value: "csrd", label: "CSRD" },
        { value: "secr", label: "SECR" },
      ],
      col: 6,
    },
    {
      name: "mobileSourceDataCollectionMethod",
      label: "Data Collection",
      placeholder: "Select Data Collection",
      select: true,
      icon: <ChevronDown />,
      options: [
        { value: "Vehicle Logs", label: "Vehicle Logs" },
        { value: "Tracking systems", label: "Tracking systems" },
      ],
      col: 6,
    },
  ];

  const extraFields = isEditing
    ? [
        {
          name: "reason",
          label: "Reason To edit",
          placeholder: "Enter The Reason why you want to edit Emission",
          type: "textarea",
          col: 12,
        },
      ]
    : [];

  const finalFields = fields.concat(extraFields);

  return (
    <CustomModal
      title={modalTitle}
      open={isModalOpen}
      onClose={handleClose}
      maxWidth="sm"
      actions={[
        { text: "Discard", onClick: handleClose, bgColor: "transparent" },
        {
          text: isEditing ? "Update Emission" : "Save Emission",
          type: "submit",
          onClick: () => {
            methods.handleSubmit(onSubmit)();
          },
        },
      ]}
    >
      <Box mt={3}>
        <CustomHeading text="Mobile Sources Emissions" variant="h5" />
        <CustomSubHeading text="Please Provide the Following details to Calculate the Mobile Source Emissions" />
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={3} mt={0}>
            {finalFields.map((field, index) => (
              <Grid item xs={12} sm={field.col} key={index}>
                <CustomTextField {...field} />
              </Grid>
            ))}
          </Grid>
        </form>
      </FormProvider>
      <Box mt={3}>
        <CustomButton
          fullWidth
          text="Calculate Emission"
          onClick={methods.handleSubmit(calculateEmissions)}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 2,
          mb: 4,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: "#090909",
            fontFamily: "PoppinsMedium",
            display: "flex",
            alignItems: "center",
            gap: 1,
            lineHeight: 1,
          }}
        >
          Emission Calculated
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: "#090909",
            fontSize: 18,
            fontWeight: 600,
            lineHeight: 1,
          }}
        >
          {calculatedEmissions !== null
            ? `${calculatedEmissions} ${unitName}`
            : ""}
        </Typography>
      </Box>
    </CustomModal>
  );
};

export default AddEditMobileEmission;
