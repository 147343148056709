import { toast } from "react-toastify";

/**
 * Utility functions for displaying toasts using react-toastify.
 *
 * @typedef {Object} ToastOptions - Object containing functions to show toasts for different types.
 * @property {Function} success - Function to show a success toast.
 * @property {Function} error - Function to show an error toast.
 * @property {Function} warning - Function to show a warning toast.
 * @property {Function} info - Function to show an info toast.
 *
 * @typedef {Function} ShowToastFunction - Function to display a toast with a specified message and type.
 * @param {string} message - Message to be displayed in the toast.
 * @param {string} [type="success"] - Type of the toast (success, error, warning, info).
 *
 * @param {ToastOptions} toastOptions - Object containing functions to show toasts for different types.
 *
 * @example
 *  Import showToast and toastOptions from "path/to/toastUtility";
 * Call showToast with a message and type (optional, defaults to 'success').
 * showToast("Operation Successful", "success");
 * showToast("Error Occurred", "error");
 * showToast("Warning Message", "warning");
 * showToast("Information", "info");
 */

type ToastType = "success" | "error" | "warning" | "info";

interface ToastOptions {
  success: (message: string | JSX.Element) => void;
  error: (message: string | JSX.Element) => void;
  warning: (message: string | JSX.Element) => void;
  info: (message: string | JSX.Element) => void;
}

const toastOptions: ToastOptions = {
  success: (message: string | JSX.Element) => toast.success(message),
  error: (message: string | JSX.Element) => toast.error(message),
  warning: (message: string | JSX.Element) => toast.warning(message),
  info: (message: string | JSX.Element) => toast.info(message),
};

/**
 * Displays a toast with the specified message and type.
 * @param {string} message - Message to be displayed in the toast.
 * @param {string} [type="success"] - Type of the toast (success, error, warning, info).
 * @param {() => void} [undoAction] - Optional undo action to be executed.
 */

export const showToast = (
  message: string | JSX.Element,
  type: ToastType = "success",
  undoAction?: () => void
): void => {
  const toastContent = message;

  const showToastFn = toastOptions[type] || toast;
  showToastFn(toastContent);
};
