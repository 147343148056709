import { styles } from "../ui/custom-table/custom-table.style";

export const getStatusStyle = (status: string): React.CSSProperties => {
    const normalizedStatus = status?.trim().toLowerCase();
    
    switch (normalizedStatus) {
      case "inactive":
        return styles.statusInactive;
      case "active":
        return styles.statusActive;
      case "completed":
        return styles.statusCompleted;
      case "processing":
        return styles.statusProcessing;
      case "error":
        return styles.statusError;
      default:
        return styles.statusInactive;
    }
  };
  
  export const getStatusDotColor = (status: string): string => {
    const normalizedStatus = status?.trim().toLowerCase();
  
    switch (normalizedStatus) {
      case "inactive":
        return "#DA3E33"; // Red
      case "active":
      case "completed":
        return "#12B76A"; // Green
      case "processing":
        return "#04A2FB"; // Blue
      case "error":
        return "#DA3E33"; // Red
      default:
        return "gray"; // Gray if none match
    }
  };


  export const capitalizeFirstLetter = (status: string) => {
    if (!status) return "";
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  };

  
  export const formatDateToDDMMYYYY = (dateInput: string | Date): string => {
    const dateObj = new Date(dateInput);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
  
    return `${day}-${month}-${year}`;
  };
  
  
  
  