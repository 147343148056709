import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { MutationConfig } from "../../../../../utils/queryClient";
import { createMobileSource, CreateMobileSourceData } from "../../../../../api/mobile-source-api";


export const useMobileSourceEmission = (
  config?: MutationConfig<typeof createMobileSource>
): UseMutationResult<void, AxiosError, CreateMobileSourceData> => {
  return useMutation<void, AxiosError, CreateMobileSourceData>({
    mutationFn: createMobileSource,
    ...config,
  });
};



