import React from "react";
import { Typography, TypographyProps, PaletteColor } from "@mui/material";
import theme from "../../styles/theme";

type ThemeColor = keyof typeof theme.palette;
type ColorShade =
  | 50
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900
  | "main"
  | "light"
  | "dark"
  | "contrastText";

type PaletteColorWithShades = PaletteColor & {
  [key in ColorShade]?: string;
};

interface CustomHeadingProps extends TypographyProps {
  text: string;
  color?: ThemeColor;
  shade?: ColorShade;
  fontSize?: string;
}

/**
 * CustomHeading component.
 * Renders a customizable heading text with specified color and shade from the theme palette.
 *
 * @param {CustomHeadingProps} props - Props for the CustomHeading component.
 * @param {string} props.text - Text to be displayed in the heading.
 * @param {string} [props.color="primary"] - Theme color key for the heading text.
 * @param {ColorShade} [props.shade="contrastText"] - Shade of the theme color for the heading text.
 * @param {object} [props.sx] - Additional styling to be applied to the Typography component.
 * @returns {JSX.Element} JSX code for rendering the CustomHeading component.
 */

const CustomHeading: React.FC<CustomHeadingProps> = ({
  text,
  variant = "h5",
  color = "primary",
  shade = "contrastText",
  fontSize = "1.5rem",
  sx,
  ...props
}: CustomHeadingProps): JSX.Element => {
  const getColor = (colorKey: ThemeColor, shadeKey: ColorShade): string => {
    const paletteColor = theme.palette[colorKey] as
      | PaletteColorWithShades
      | string;

    if (typeof paletteColor === "object" && paletteColor !== null) {
      if (shadeKey in paletteColor) {
        return paletteColor[shadeKey] as string;
      }
      // Fallback to main if the specific shade doesn't exist
      return paletteColor.main || theme.palette.text.primary;
    }

    // If paletteColor is a string, it's likely a simple color value
    if (typeof paletteColor === "string") {
      return paletteColor;
    }

    // Final fallback
    return theme.palette.text.primary;
  };

  return (
    <Typography
      variant={variant}
      sx={{
        color: getColor(color, shade),
        fontFamily: "PoppinsSemibold",
        fontSize: fontSize,
        ...sx,
      }}
      {...props}
    >
      {text}
    </Typography>
  );
};

export default CustomHeading;

// usage example

// import CustomHeading from "path-to-custom-heading"

/* 
<CustomHeading
  gutterBottom
  text="Transform Your Sustainability Management"
  color="primary"
/>;
 */
