import React, { FC, useEffect, useState } from "react";
import { Card, CustomTable, CustomTextField } from "../../../../../ui";
import AddEditFugitiveEmission from "./add-edit-fugitive-emission";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import theme from "../../../../../styles/theme";
import { ChevronDown, LayoutGridIcon, ListIcon } from "../../../../../icons";
import { FormProvider, useForm } from "react-hook-form";
import { CustomGridCard } from "../../../../../ui/custom-grid-card/custom-grid-card";
import {
  getFugitiveEmission,
  getFugitiveEmissionByFileId,
} from "../../../../../api/fugitive-emission-api";
import ViewReasonFugitiveEmission from "./view-reason-fugitive-emission";
import { getUploadedFiles } from "../../../../../api/fuel-api";
import { Close as CloseIcon } from "@mui/icons-material";

const protocols = [
  { value: "csrd", label: "CSRD" },
  { value: "secr", label: "SECR" },
];

const FugitiveEmissions: FC = () => {
  const navigate = useNavigate();
  const methods = useForm<FormData>({});
  const [isGridView, setIsGridView] = useState(false);
  const [fugitiveEmissionData, setFugitiveEmissionData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [protocol, setProtocol] = useState<string>("CSRD");
  const [fugitiveEmissionId, setFugitiveEmissionId] = useState<any | null>(
    null
  );
  const [fileData, setFileData] = useState<any[]>([]);
  const [fileTableData, setFileTableData] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);

  const itemsPerPage = 8;
  const fetchFugitiveEmissionData = async (
    page: number,
    itemsPerPage: number,
    protocol?: string,
    sort?: string
  ) => {
    try {
      const response = await getFugitiveEmission({
        page: page - 1,
        size: itemsPerPage,
        protocol: protocol ? protocol.toUpperCase() : "CSRD",
        sort: "createdDate,desc",
      });
      setFugitiveEmissionData(response.data);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error("Failed to fetch fuel combustion data:", error);
    }
  };

  useEffect(() => {
    if (protocol !== "") {
      fetchFugitiveEmissionData(currentPage, itemsPerPage, protocol);
    }
  }, [protocol, currentPage]);

  const fugitiveData = fugitiveEmissionData.map((item, i) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
  }));

  const handleRowUpdate = (updatedRow: any) => {
    setFugitiveEmissionData((prevData) =>
      prevData.map((row) =>
        row.fugitiveEmissionId === updatedRow.fugitiveEmissionId
          ? { ...row, ...updatedRow }
          : row
      )
    );
  };

  const handleProtocolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedProtocol = event.target.value;
    if (protocol !== selectedProtocol) {
      setProtocol(selectedProtocol);
    }
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const handleEditClick = (row: any) => {
    setFugitiveEmissionId(row.fugitiveEmissionId);
    navigate(`?action=edit`);
  };

  const handleViewReasonClick = (row: any) => {
    setFugitiveEmissionId(row.fugitiveEmissionId);
    navigate(`?action=viewReason`);
  };

  const fetchUploadedFiles = async (page: number) => {
    try {
      const response = await getUploadedFiles({
        search: "",
        typeOfEmission: "FUGITIVE",
        page: page - 1,
        size: 10,
        sort: "createdDate,desc",
      });
      setFileData(response.data);
      fetchFugitiveEmissionData(page, itemsPerPage, protocol);
    } catch (error) {
      console.error("Error fetching uploaded files:", error);
    }
  };

  useEffect(() => {
    fetchUploadedFiles(currentPage); // Fetch files on component mount
  }, [currentPage]);

  const formattedDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const file = fileData.map((item, i) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
    uploadDate: formattedDate(item.uploadDate),
  }));

  const fileTable = fileTableData.map((item, i) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
  }));

  const handleViewDetails = async (fileId: number) => {
    try {
      const response = await getFugitiveEmissionByFileId({
        fileId,
        page: currentPage - 1,
        size: 10,
        sort: "createdDate,desc",
      });
      setFileTableData(response.data);
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching process emission details:", error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Card
      headerText="Fugitive Emissions"
      headerBtnText="Add New"
      searchPlaceholder="Hinted search text"
      btnActions={[{ text: "Edit", onClick: () => {} }]}
      tableDataLength={fugitiveEmissionData?.length}
      fetchUploadedFiles={fetchUploadedFiles}
      currentPage={currentPage}
      uploadUrl="/fugitive-emission/upload"
      fileType="FUGITIVE"
    >
      <AddEditFugitiveEmission
        fugitiveEmissionId={fugitiveEmissionId}
        onFugitiveEmissionData={setFugitiveEmissionData}
        handleRowUpdate={handleRowUpdate}
        fetchFugitiveEmissionData={fetchFugitiveEmissionData}
        currentPage={currentPage}
        fetchUploadedFiles={fileData}
      />
      <ViewReasonFugitiveEmission fugitiveEmissionId={fugitiveEmissionId} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 3,
        }}
      >
        {/* select protocol */}
        <TextField
          name="protocol"
          placeholder="Select Protocol"
          label="Protocol"
          select
          fullWidth
          value={protocol}
          onChange={handleProtocolChange}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ChevronDown />
              </InputAdornment>
            ),
          }}
          SelectProps={{
            displayEmpty: true,
            renderValue: (value: unknown) => {
              if (typeof value !== "string") {
                return (
                  <span style={{ color: theme.palette.grey[200] }}>
                    Select Protocol
                  </span>
                );
              }
              const selectedOption = protocols.find(
                (option) => option.value === value
              );
              return selectedOption ? selectedOption.label : value;
            },
          }}
        >
          {protocols.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {/* toggle list/grid view */}
        <Stack direction="row" gap={1} ml={3}>
          <IconButton
            sx={{
              backgroundColor: isGridView ? "none" : "#fff5cc",
              borderRadius: "8px",
              padding: "10px",
              "&:hover": {
                backgroundColor: "#fff5cc",
              },
            }}
            onClick={() => setIsGridView(false)}
          >
            <ListIcon />
          </IconButton>
          <IconButton
            sx={{
              backgroundColor: !isGridView ? "none" : "#fff5cc",
              borderRadius: "8px",
              padding: "10px",
              "&:hover": {
                backgroundColor: "#fff5cc",
              },
            }}
            onClick={() => setIsGridView(true)}
          >
            <LayoutGridIcon />
          </IconButton>
        </Stack>
      </Box>
      {isGridView ? (
        <CustomGridCard
          columns={columns}
          row={fugitiveData}
          rowActions={[
            {
              text: "Edit Details",
              onClick: (row) => {
                handleEditClick(row);
              },
            },
            {
              text: "View Reason",
              onClick: (row) => {
                handleViewReasonClick(row);
              },
            },
          ]}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      ) : (
        <CustomTable
          columns={columns}
          data={fugitiveData}
          rowActions={[
            {
              text: "Edit Details",
              onClick: (row) => {
                handleEditClick(row);
              },
            },
            {
              text: "View Reason",
              onClick: (row) => {
                handleViewReasonClick(row);
              },
            },
          ]}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}

      <Box mt={4}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "1.875rem",
              lineHeight: "1",
              fontFamily: "PoppinsSemibold",
              color: "#000",
            }}
          >
            Fugitive Emission
          </Typography>

          <FormProvider {...methods}>
            <form>
              <CustomTextField
                name="search"
                margin="normal"
                type="text"
                placeholder="Hinted search text"
                icon="search"
                sx={{ margin: 0 }}
              />
            </form>
          </FormProvider>
        </Box>
        <CustomTable
          columns={columns2}
          data={file}
          rowActions={[
            {
              text: "View Details",
              onClick: (row: any) => {
                handleViewDetails(row.fileId);
              },
            },
          ]}
        />

        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Fugitive Emission Details</Typography>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <CustomTable columns={columns} data={fileTable} />
          </DialogContent>
        </Dialog>
      </Box>
    </Card>
  );
};

export default FugitiveEmissions;

const columns = [
  { key: "id", label: "Sr." },
  { key: "fugitiveEmissionSource", label: "Fuel Type" },
  { key: "emissionEventFrequency", label: "Emission Frequency" },
  { key: "durationOfFugitiveEvent", label: "Duration" },
  { key: "fugitiveDataCollectionMethod", label: "Data Collection" },
  { key: "fugitiveEmissionFactor", label: "Emission Factor" },
  { key: "calculatedEmission", label: "Calculated Emission" },
  { key: "status", label: "Status" },
];

const columns2 = [
  { key: "fileId", label: "File ID" },
  { key: "fileName", label: "File Name" },
  { key: "uploadDate", label: "Upload Date" },
  { key: "totalEmissions", label: "Total Emissions" },
];
