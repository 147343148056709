
import React, { FC, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Checkbox,
  FormControlLabel,
  Switch,
  styled,
  SwitchProps,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { showToast } from "../../utils/showToast.util";
import { styles } from "../custom-accordion-configuration/custom-accordion-configuration.style";
import { scope1CreateOrUpdateLocationPreferences } from "../../api/scope1-location-api";
import { IOSSwitch } from "../custom-switch/custom-switch";


interface Scope1LocationAccordionConfigurationProps {
  locationId?: number | undefined | any;
  savePreferences: boolean;
}

const Scope1LocationAccordionConfiguration: FC<Scope1LocationAccordionConfigurationProps> = ({
  locationId,
  savePreferences,
}) => {
  const [expandedCombustion, setExpandedCombustion] = useState<boolean>(false);

  const [stationaryCombustionChecked, setStationaryCombustionChecked] = useState<boolean>(false);

  const [liquidFuelChecked, setLiquidFuelChecked] = useState<boolean>(false);
  const [onSiteFossilFuelChecked, setOnSiteFossilFuelChecked] = useState<boolean>(false);
  const [naturalGasChecked, setNaturalGasChecked] = useState<boolean>(false);
  const [landfillGasChecked, setLandfillGasChecked] = useState<boolean>(false);
  const [liquefiedPetroleumGasChecked, setLiquefiedPetroleumGasChecked] = useState<boolean>(false);
  const [numberTwoOilOrRedDieselChecked, setNumberTwoOilOrRedDieselChecked] = useState<boolean>(false);
  const [numberSixFuelOilChecked, setNumberSixFuelOilChecked] = useState<boolean>(false);


  const handleSavePreferences = async () => {
    const preferences = {
      stationaryCombustion: {
        liquidFuelOrGas: {
          naturalGas: naturalGasChecked,
          landfillGas: landfillGasChecked,
          propaneOrLiquefiedPetroleumGas: liquefiedPetroleumGasChecked,
          numberTwoOilOrRedDieselGasFuel: numberTwoOilOrRedDieselChecked,
          numberSixFuelOil: numberSixFuelOilChecked,
        },
        onSiteFossilFuel: onSiteFossilFuelChecked,
      },
    };
    try {
      const response = await scope1CreateOrUpdateLocationPreferences(locationId, preferences);
      showToast(response.message);
    } catch (error: any) {
      console.error("Error saving preferences", error);
      showToast(error, "error")
    }
  };


  useEffect(() => {
    if (savePreferences) {
      handleSavePreferences();
    }
  }, [savePreferences]);

  const handleCombustionSwitchChange = () => {
    const newValue = !stationaryCombustionChecked;
    setStationaryCombustionChecked(newValue);
    setExpandedCombustion(newValue);
  };
  return (
    <>
      {/* First Accordion - Stationary Combustion */}
      <Accordion
        expanded={expandedCombustion}
        onChange={() => setExpandedCombustion(!expandedCombustion)}
        disableGutters
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={styles.container} display="flex" alignItems="center" flex="1">
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={stationaryCombustionChecked}
                  onChange={handleCombustionSwitchChange}
                  inputProps={{ "aria-label": "Stationary Combustion switch" }}
                />
              }
              label="Stationary Combustion"
              sx={{ gap: 2 }}
            />
          </Box>
        </AccordionSummary>

        {stationaryCombustionChecked && (
          <AccordionDetails>
            <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
              {/* Liquid Fuel or Gas */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={liquidFuelChecked}
                    onChange={() => setLiquidFuelChecked(!liquidFuelChecked)}
                    sx={{
                      color: "#8E98A8",
                      "&.Mui-checked": {
                        color: "#E8CA51",
                      },
                    }}
                    inputProps={{ "aria-label": "Liquid Fuel or Gas checkbox" }}
                  />
                }
                label="Liquid Fuel or gas"
              />

              {/* Additional Liquid Fuel options */}
              {liquidFuelChecked && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", marginLeft: "40px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={naturalGasChecked}
                        onChange={() => setNaturalGasChecked(!naturalGasChecked)}
                        sx={{ color: "#8E98A8", "&.Mui-checked": { color: "#E8CA51" } }}
                        inputProps={{ "aria-label": "Natural Gas checkbox" }}
                      />
                    }
                    label="Natural Gas"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={landfillGasChecked}
                        onChange={() => setLandfillGasChecked(!landfillGasChecked)}
                        sx={{ color: "#8E98A8", "&.Mui-checked": { color: "#E8CA51" } }}
                        inputProps={{ "aria-label": "Landfill Gas checkbox" }}
                      />
                    }
                    label="Landfill Gas"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={liquefiedPetroleumGasChecked}
                        onChange={() => setLiquefiedPetroleumGasChecked(!liquefiedPetroleumGasChecked)}
                        sx={{ color: "#8E98A8", "&.Mui-checked": { color: "#E8CA51" } }}
                        inputProps={{ "aria-label": "Liquefied Petroleum Gas checkbox" }}
                      />
                    }
                    label="Propane Liquefied Petroleum Gas"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={numberTwoOilOrRedDieselChecked}
                        onChange={() => setNumberTwoOilOrRedDieselChecked(!numberTwoOilOrRedDieselChecked)}
                        sx={{ color: "#8E98A8", "&.Mui-checked": { color: "#E8CA51" } }}
                        inputProps={{ "aria-label": "#2 Fuel Oil/Red Diesel/Gas Fuel checkbox" }}
                      />
                    }
                    label="#2 Fuel Oil/Red Diesel/Gas Fuel"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={numberSixFuelOilChecked}
                        onChange={() => setNumberSixFuelOilChecked(!numberSixFuelOilChecked)}
                        sx={{ color: "#8E98A8", "&.Mui-checked": { color: "#E8CA51" } }}
                        inputProps={{ "aria-label": "#6 Fuel Oil checkbox" }}
                      />
                    }
                    label="#6 Fuel Oil"
                  />
                  
                </Box>
              )}

              {/* Fossil Fuel */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={onSiteFossilFuelChecked}
                    onChange={() => setOnSiteFossilFuelChecked(!onSiteFossilFuelChecked)}
                    sx={{ color: "#8E98A8", "&.Mui-checked": { color: "#E8CA51" } }}
                    inputProps={{ "aria-label": "Solid Fuel (Wood or Coal) checkbox" }}
                  />
                }
                label="Onsite Fossile Fuel"
              />
            </Box>
          </AccordionDetails>
        )}
      </Accordion>
    </>
  );
};

export default Scope1LocationAccordionConfiguration;
