import React from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import StatusIcon from "../../../../icons/StatusIcon"; // Make sure this is your custom status icon
import { CustomButton } from "../../../../ui";

interface ChangeStatusModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  currentStatus?: string;
}

const capitalizeFirstLetter = (status: string) => {
  return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
};

const ChangeStatusModal: React.FC<ChangeStatusModalProps> = ({
  isOpen,
  onCancel,
  onConfirm,
  currentStatus = "Active",
}) => {
  const newStatus = currentStatus.toLowerCase() === "active" ? "inactive" : "active"; // Toggles between active and inactive
  const confirmationMessage = `Are you sure you want to update the status from ${capitalizeFirstLetter(
    currentStatus
  )} to ${capitalizeFirstLetter(newStatus)}?`;

  return (
    <Modal open={isOpen} onClose={onCancel}>
      <Box
        sx={{
          width: 400,
          p: 3,
          mx: "auto",
          mt: "20%",
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: 24,
          position: "relative",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <StatusIcon />
          <Typography
            variant="h6"
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "24px",
              textAlign: "left",
            }}
          >
            Change Status
          </Typography>
          <IconButton
            onClick={onCancel}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography variant="body1" sx={{ mb: 2, color: "grey.600" }}>
          {confirmationMessage}
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
          <Button
            onClick={onCancel}
            variant="outlined"
            sx={{ width: "45%", color: "#000", borderColor: "#EAECF0"}}
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            variant="contained"
            sx={{ width: "45%", backgroundColor: "#DA3E33", color: "#fff"}}
          >
            Yes, {capitalizeFirstLetter(newStatus)}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
export default ChangeStatusModal;
