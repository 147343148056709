import * as React from "react";

function StatusIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.99966C13.2477 2.99966 14.4361 3.25354 15.5163 3.71247L14.3609 4.87881C13.6186 4.63281 12.8249 4.49966 12 4.49966C7.85786 4.49966 4.5 7.85752 4.5 11.9996C4.5 13.4245 4.89727 14.7889 5.63583 15.97C5.93764 16.4527 6.29368 16.8999 6.69639 17.3026C7.09848 17.7048 7.5449 18.0604 8.02668 18.362C9.20844 19.1017 10.5739 19.4996 12 19.4996C16.1421 19.4996 19.5 16.1418 19.5 11.9996C19.5 11.1992 19.3746 10.4281 19.1424 9.70482L20.3069 8.53015C20.7534 9.59788 21 10.77 21 11.9996C21 16.9702 16.9706 20.9996 12 20.9996C10.2904 20.9996 8.64945 20.5214 7.23081 19.6334C6.65294 19.2717 6.1177 18.8453 5.63566 18.3632C5.1529 17.8804 4.72601 17.3442 4.36401 16.7653C3.4774 15.3475 3 13.7078 3 11.9996C3 7.02909 7.02944 2.99966 12 2.99966ZM21.1626 2.57266L21.3013 2.70091C22.2387 3.63826 22.2421 5.15694 21.3089 6.09848L14.554 12.9141C14.4397 13.0294 14.2991 13.1151 14.1443 13.1639L9.79631 14.5342C9.69096 14.5674 9.57864 14.5089 9.54544 14.4036C9.53311 14.3644 9.53311 14.3225 9.54544 14.2833L10.9171 9.93085C10.965 9.77892 11.0485 9.64063 11.1606 9.52749L17.9189 2.70848C18.8045 1.81492 20.2215 1.76815 21.1626 2.57266Z"
        fill="#DA3E33"
      />
    </svg>
  );
}

export default StatusIcon;
