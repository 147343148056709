import React from "react";
import { Card, CustomTable } from "../../../../ui";
import InviteUser from "./invite-user";
import { useNavigate } from "react-router-dom";
import EditUser from "./edit-user";

const Users: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Card headerText="Users List" headerBtnText="Invite" tableDataLength={rows?.length}>
        <InviteUser />
        <EditUser />
        <CustomTable
          columns={columns}
          data={rows}
          rowActions={[
            {
              text: "Edit Details",
              onClick: () => {
                navigate(`?action=edit`);
              },
            },
            {
              text: "Delete User",
              onClick: (user) => {
                console.log("delete user", user);
              },
            },
          ]}
        />
      </Card>
    </>
  );
};

export default Users;

const columns = [
  { key: "id", label: "Sr." },
  { key: "firstName", label: "First Name" },
  { key: "lastName", label: "Last Name" },
  { key: "emailAddress", label: "Email address" },
  { key: "lastLogin", label: "Last Login" },
  { key: "status", label: "Status" },
];

const rows = [
  {
    id: 1,
    firstName: "Manoj shahid",
    lastName: "Irqis",
    emailAddress: "manoiraji201@gmail.com",
    lastLogin: "24-07-2024 - 18:00",
    status: "Inactive",
  },
  {
    id: 2,
    firstName: "Stephen",
    lastName: "jhon",
    emailAddress: "manoiraji201@gmail.com",
    lastLogin: "24-07-2024 - 18:00",
    status: "Active",
  },
  {
    id: 3,
    firstName: "Hifza",
    lastName: "Aslam",
    emailAddress: "manoiraji201@gmail.com",
    lastLogin: "24-07-2024 - 18:00",
    status: "Inactive",
  },
  {
    id: 4,
    firstName: "Hasus",
    lastName: "Ahmad",
    emailAddress: "manoiraji201@gmail.com",
    lastLogin: "24-07-2024 - 18:00",
    status: "Active",
  },
  {
    id: 5,
    firstName: "Mohsin",
    lastName: "Islam",
    emailAddress: "manoiraji201@gmail.com",
    lastLogin: "24-07-2024 - 18:00",
    status: "Inactive",
  },
  {
    id: 6,
    firstName: "Rahanat",
    lastName: "Ali",
    emailAddress: "manoiraji201@gmail.com",
    lastLogin: "24-07-2024 - 18:00",
    status: "Active",
  },
  {
    id: 7,
    firstName: "Ali",
    lastName: "Mehmood",
    emailAddress: "manoiraji201@gmail.com",
    lastLogin: "24-07-2024 - 18:00",
    status: "Inactive",
  },

  {
    id: 8,
    firstName: "Anajib",
    lastName: "Islam",
    emailAddress: "manoiraji201@gmail.com",
    lastLogin: "24-07-2024 - 18:00",
    status: "Active",
  },
  {
    id: 9,
    firstName: "Ali",
    lastName: "Mehmood",
    emailAddress: "manoiraji201@gmail.com",
    lastLogin: "24-07-2024 - 18:00",
    status: "Inactive",
  },
  {
    id: 10,
    firstName: "Anajib",
    lastName: "Islam",
    emailAddress: "manoiraji201@gmail.com",
    lastLogin: "24-07-2024 - 18:00",
    status: "Active",
  },
  {
    id: 11,
    firstName: "Anajib",
    lastName: "Islam",
    emailAddress: "manoiraji201@gmail.com",
    lastLogin: "24-07-2024 - 18:00",
    status: "Active",
  },
  {
    id: 12,
    firstName: "Ali",
    lastName: "Mehmood",
    emailAddress: "manoiraji201@gmail.com",
    lastLogin: "24-07-2024 - 18:00",
    status: "Inactive",
  },
];
