import React, { FC, useState } from "react";
import { Box } from "@mui/material";
import { CustomButton, CustomHeading, CustomSubHeading } from "../../../../../ui";
import Scope1BuildingAccordionConfiguration from "../../../../../ui/scope1-building-accordion-configutration/scope1-building-accordion-configuration";


interface Scope1BuildingConfigurationProps {
  buildingId?: number;
  setIsConfigurationTabsView?: any;
  setIsTabsView?:any;
  setSelectedTab?: any;
  selectedTab?: any;
}

const Scope1BuildingConfiguration: FC<Scope1BuildingConfigurationProps> = ({
  buildingId,
  setIsConfigurationTabsView,
  setIsTabsView,
}) => {

  const [savePreferences, setSavePreferences] = useState<boolean>(false)
  
  const handleSavePreferences = async () => {
    await setSavePreferences(true);
    await setIsTabsView({ show: true, active: undefined });
    await setIsConfigurationTabsView({ show: false, active: undefined });
  };


  const handleCancel = () => {
    setSavePreferences(false);
    setIsTabsView({ show: true, active: undefined });
    setIsConfigurationTabsView({ show: false, active: undefined });
  };


  return (
    <div>
      <Box sx={{ p: "24px 12px 16px" , m: "6px"}}>
      <CustomHeading text= "Configuration" fontSize="20px" />
          <CustomSubHeading
            text="Please select the building you want to apply the configuration on!"
            fontSize="14px"
            sx={{ pt: "8px", display: "flex-start" }}
          />
      </Box>

      <Box sx={{ margin: "14px 18px" }}>
        <Scope1BuildingAccordionConfiguration
        buildingId={buildingId} 
        savePreferences={savePreferences}
        navigateUrl={"/measure/scope1-emissions?emission=building"}
        /> 
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <CustomButton
          text="Cancel"
          bgColor="transparent"
          rounded="8px"
          variant="outlined"
          onClick={handleCancel}
          sx={{
            borderColor: "#D0D5DD",
            outlineColor: "#EAECF0",
            margin: "8px 16px",
            marginRight: "2px",
            padding: "10px",
            color: "#344054",
            "&:hover": {
              borderColor: "transparent",
              backgroundColor: "#FFF",
            },
          }}
        >
          Cancel
        </CustomButton>

        <CustomButton
          text="Save "
          textColor="#000"
          iconPosition="end"
          rounded="8px"
          onClick={handleSavePreferences}
          sx={{
            margin: "8px 16px ",
            padding: "10px",
            backgroundColor: "#FFDE59",
            color: "#000",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          Save
        </CustomButton>
      </Box>
    </div>
  );
};

export default Scope1BuildingConfiguration;
