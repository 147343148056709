import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {  updateLocation, updateLocationData } from "../../../../api/locations-api";
import { MutationConfig } from "../../../../utils/queryClient";


export const useUpdateLocation = (
  config?: MutationConfig<typeof updateLocation>
): UseMutationResult<void, AxiosError, updateLocationData> => {
  return useMutation<void, AxiosError, updateLocationData>({
    mutationFn: updateLocation,
    ...config,
  });
};
